import { FC, useEffect } from 'react';
import { apiErrors } from './SampleError';
import { mls } from '../../../../lib/multilanguagesupport';

const Error500: FC = () => {
  useEffect(() => {
    mls(apiErrors[500].message);
  }, []);

  return (
    <>
      <h1 className='fw-bolder fs-4x text-gray-700 mb-10'>{apiErrors[500].error}</h1>

      <div className='fw-bold fs-3 text-gray-400 mb-15'>{apiErrors[500].message}</div>
    </>
  );
};

export { Error500 };
