/*
This component renders either the projects home page (cards of projects) OR the project edit page
*/

import { useState } from 'react';

import { ProjectsHomePage, EditProjectPage } from './pages';

const ProjectsPage = () => {
  const [openedProject, setOpenedProject] = useState(null);
  const [isProjectEditPageOpen, setIsProjectEditPageOpen] = useState(false);

  
  const openProjectToEdit = (screenData) => {
    setOpenedProject(screenData)
    setIsProjectEditPageOpen(true);
  }
  const closeEditScreen = () => {
    setOpenedProject(null)
    setIsProjectEditPageOpen(false);
  }

  return <>
  {isProjectEditPageOpen
  ?
  <EditProjectPage
    openedProject={openedProject}
    closeEditScreen={closeEditScreen}
  />
  :
  <ProjectsHomePage
    openProjectToEdit={openProjectToEdit}
  />}
  </>
}

export default ProjectsPage;