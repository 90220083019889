import { v4 as uuid } from 'uuid';
import { arrayMoveImmutable } from 'array-move';

import { mls } from 'lib/multilanguagesupport';

import DragAndDrop from '../DragAndDrop';

import * as editPageConstants from '../../constants';

export const ProjectForm = ({
  formik,
  activePageInEditing,
  setActivePageInEditing
}) => {
  const handleAddScreenGroup = () => {
    const currentScreenGroups = global.structuredClone(formik.values.screenGroup);
    currentScreenGroups.push({
      name: '',
      description: '',
      screen: [],
      screenGroupID: uuid()
    });
    formik.setFieldValue('screenGroup', currentScreenGroups);
    setActivePageInEditing({
      activePage: editPageConstants.SCREEN_GROUP,
      screenGroupIndex: currentScreenGroups.length - 1,
      screenIndex: null
    });
  }

  const handleReArrangeScreenGroups = (dragEndDetails) => {
    console.log(dragEndDetails);
    const reArrangedScreenGroups = arrayMoveImmutable(formik.values.screenGroup, dragEndDetails.source.index, dragEndDetails.destination.index);
    formik.setFieldValue('screenGroup', reArrangedScreenGroups);
  }
  return <>
    {/* <ConfirmDeleteModal
      isDeleteModalOpen={isDeleteModalOpen}
      setIsDeleteModalOpen={setIsDeleteModalOpen}
      what={editPageConstants.SCREEN_GROUP}
      handleDelete={handleAddScreenGroup}
    /> */}


    <label className='form-label fs-6 fw-bolder text-dark'>{mls('Name')}</label>
    <input
      {...formik.getFieldProps('name')}
      placeholder='Project name'
      className='form-control form-control-lg form-control-solid'
    />
    {
      /**
       * @todo: Add icon to upload icon image.
       */
    }
    {/* <label className='form-label fs-6 fw-bolder text-dark mt-5'>{mls('Icon')}</label>
    <input
      {...formik.getFieldProps('icon')}
      maxLength={150}
      placeholder='Icon'
      className='form-control form-control-lg form-control-solid'
    /> */}
    <div className='d-flex justify-content-between px-2'>
      <label className='form-label fs-6 fw-bolder text-dark mt-5'>{mls('Description')}</label>
      <label className='form-label text-muted fs-6 fw-bolder text-dark mt-5'>
        {formik.values?.description?.length || 0}/150
      </label>
    </div>
    <textarea
      {...formik.getFieldProps('description')}
      maxLength={150}
      style={{ resize: 'none', height: 80 }}
      placeholder='Description'
      className='form-control form-control-lg form-control-solid'
    />
    <div className='d-flex justify-content-between px-2'>
      <label className='form-label fs-6 fw-bolder text-dark mt-5'>{mls('Screen groups')}</label>
      <button onClick={handleAddScreenGroup} className=' btn btn-sm btn-primary btn-light-primary mt-4'>
        {mls('Add screen group')}
      </button>
    </div>
    <DragAndDrop
      represents={editPageConstants.SCREEN_GROUP}
      listForDragAndDrop={formik.values?.screenGroup}
      onDragEnd={handleReArrangeScreenGroups}
      activePageInEditing={activePageInEditing}
      setActivePageInEditing={setActivePageInEditing}
      formik={formik}
    />
  </>
}

export default ProjectForm;