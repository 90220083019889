import React, { useImperativeHandle, useRef } from 'react';
import styles from './PDFTemplate.module.scss';
import Handlebars from 'handlebars';
import html2pdf from 'html2pdf.js';
const HtmlToPdf = (props) => {
  const printRef = useRef(null);
  const { downloadPdfButtonRef = () => {}, htmlContent, htmlContentData } = props;

  const handleDownloadPdf = async () => {
    if (printRef?.current) {
      const element = printRef.current;
      var opt = {
        margin: 5,
        filename: 'myfile.pdf',
        image: { type: 'jpeg', quality: 0.2 },
        html2canvas: { scale: 2, useCORS: true, imageTimeout: 10000 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'p' },
        // pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
        pagebreak: { mode: 'css', before: '.break-before', avoid: '.no-break' },
        letterRendering: true,
        useCORS: true,
        // image: { type: 'jpeg', quality: 0.98 },
        // html2canvas: { scale: 2 },
        // jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
      };

      const data = await html2pdf().from(element).set(opt).save();
      if (data) {
        return { skip: false };
      } else {
        return { skip: false };
      }
    } else {
      return { skip: false };
    }
  };
  const downloadPdf = async () => {
    return await handleDownloadPdf();
  };
  useImperativeHandle(downloadPdfButtonRef, () => ({
    downloadPdf,
  }));

  const { renderedHtml, isError } = renderTemplate(htmlContent, htmlContentData);
  return (
    <div className={styles.iframeOuter}>
      <div className={`${styles.iframeInner} ${isError ? styles.iframeInnerError : ''}`}>
        {/* <iframe title='preview' className={styles.iframe} ref={printRef} srcDoc={renderedHtml} /> */}
        <div
          dangerouslySetInnerHTML={{ __html: renderedHtml }}
          className={styles.iframe}
          ref={printRef}
        />
      </div>
    </div>
  );
};
export function renderTemplate(template, data) {
  try {
    const compiledTemplate = Handlebars.compile(template);
    return { renderedHtml: compiledTemplate(data), isError: false };
  } catch (error) {
    console.log('Handlebars error:', error);
    return { renderedHtml: error, isError: true }; // Return an empty string or a fallback HTML string
  }
}
export default HtmlToPdf;
