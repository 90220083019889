import React from 'react';
import { Button } from 'react-bootstrap';
import styles from './ComponentSettingWrapper.module.scss';
import { FaTimes } from 'react-icons/fa';
import { mls } from 'lib/multilanguagesupport';

const Header = ({ title, subtitle, handleClose, handleSave, handleDelete, isNew }) => {
  return (
    <div
      className={`${styles.headerContent} d-flex justify-content-between align-items-center MainUpperHeader`}
    >
      <div className={styles.headerTitles}>
        <div class='title'>{title}</div>
        <div class='subTitle'>{subtitle}</div>
      </div>
      <div className={styles.headerButtons}>
        {!isNew ? (
          <Button variant='danger' size='sm' className='me-2' onClick={handleDelete}>
            {mls('Delete')}
          </Button>
        ) : null}
        <Button variant='primary' size='sm' className='me-2' onClick={() => handleSave()}>
          {mls('Save')}
        </Button>
        <div className='closeButton' onClick={() => handleClose(false)}>
          <FaTimes />
        </div>
      </div>
    </div>
  );
};

export default Header;
