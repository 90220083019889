import clsx from 'clsx';
import { useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { KTSVG } from '_metronic/helpers';
import { ConfirmDelete } from '../../modal/add-database/DeleteColumn';
import { ColumnSettings } from '../ColumnSettings';
// import ShortUniqueId from 'short-unique-id';
// import { splitAndAdd } from './util/splitAndAdd';
import { Modal, Box } from '@mui/material';
import { cloneDeep } from 'lodash';
import { mls } from 'lib/multilanguagesupport';
import Accordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { useParams } from 'react-router-dom';
// import { supistaApiPost, supistaApiDelete } from 'lib/data-connectors/dependencies/connections';
import { DeleteTable } from '../../modal/add-database/DeleteTable';
import { useLocation } from 'react-router-dom';
import ConfirmDeleteModal from '../ConfirmDeleteModal';
import useUpdateTableSchema from '../../hooks/useUpdateTableSchema';
import { publishToProduction } from '../util/publishToProduction';
import { _supistaApiDelete, _supistaApiPost } from 'lib/server-connection/connections';
import store from 'lib/reusable-components/reusableUtils/redux/store';
import { create } from 'lib/reusable-components/reusableUtils/redux/stateSlice';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import RelationsComponent from '../RelationsComponent';
import AddNewColumn from './AddNewColumn';
import styles from './EditTableSettingsPopup.module.scss';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './tostifyOverrideScss.scss';
// '__d3__id__ComponentId';
// Actually the modal
export const EditTableSettingsPopup = ({
  dataIndex,
  allDataTableData,
  show,
  columnUpdates,
  handleClose,
  setDataTableData,
  // setColumnUpdates,
  // setRerender,
  // isJoinedTable,
}) => {
  const [colSchIndex, setColSchIndex] = useState(0);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [relatedTableList, setRelatedTableList] = useState({});
  // const [tableNameExist, setTableNameExist] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [dataTableSchema, setDataTableSchema] = useState({ ...allDataTableData[dataIndex] });
  // const [tableNameError] = useState(false);
  const [columnNameError, setColumnNameError] = useState(false);
  // const [originalName, setOriginalName] = useState(dataTableSchema?.name);
  const [deleteColIndex, setDeleteColIndex] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [tableUpdates, setTableUpdates] = useState({
    addColumns: [],
    updateColumns: [],
    deleteColumns: [],
    renameColumns: {},
    dropTable: false,
    createTable: false,
  });
  console.log(tableUpdates, 'tableUpdates');
  // console.log(dataTableSchema, 'dataTableSchema');
  // console.log(relatedTableList, 'relatedTableList');
  // console.log(allDataTableData, 'allDataTableData');

  // const appDatatable = useSelector((state) => state.config_store.appSchema.appDatatable);

  const updateTableSchema = useUpdateTableSchema();

  // const [columnExistCounter, setColumnExistCounter] = useState({});
  const deleteTarget = useRef();
  // const newLastDropdown = useRef();
  // console.log(allDataTableData, 'allDataTableData');
  // console.log(dataTableSchema, 'dataTableSchema');

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vw',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    pb: 8,
    pt: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // justifyContent: 'center',     // Causing scroll issue
    maxHeight: '80vh',
    overflow: 'auto',
    borderRadius: '.5rem',
  };

  // Special characters which are not allowed in table name
  // const notAllowedInTableName = [
  //   '!',
  //   '"',
  //   '#',
  //   '$',
  //   '%',
  //   '&',
  //   "'",
  //   '(',
  //   ')',
  //   '*',
  //   '+',
  //   ',',
  //   '.',
  //   '/',
  //   '@',
  //   '^',
  //   '=',
  //   ';',
  //   ':',
  // ];

  // Checks the validity of table name on each change
  // useEffect(() => {
  //   const containsCharacterWhichIsNotAllowed = notAllowedInTableName.some((char) => {
  //     return dataTableSchema?.name?.includes(char);
  //   });

  //   // Table with this name exists in schema AND it is not the same table which is being ediited
  //   const tableWithThisNameAlreadyExists =
  //     (appDatatable?.[dataTableSchema?.name] ? true : false) &&
  //     dataTableSchema.name !== originalName;
  //   tableWithThisNameAlreadyExists && setTableNameError('Table name must be unique');
  //   containsCharacterWhichIsNotAllowed &&
  //     setTableNameError(
  //       "Table name can only contain these special characters: space (' '), hyphen (-) and underscore (_)"
  //     );
  //   !containsCharacterWhichIsNotAllowed &&
  //     !tableWithThisNameAlreadyExists &&
  //     setTableNameError(false);
  // }, [dataTableSchema.name]);

  // useEffect(() => {
  //   // console.log('show', show);
  //   let cec = {};
  //   dataTableSchema?.columnSchema?.forEach((column) => (cec[column.name] = 1));
  //   setColumnExistCounter(cec);
  //   console.log(cec, 'columnExistCounter');
  // }, [dataTableSchema?.columnSchema]);

  // Resets the fields of the table after modal is closed and open
  // useEffect(() => {
  //   // setIsNameChanged(false);
  //   setOriginalName(dataTableSchema?.name);
  //   setDataTableSchema({ ...dataTableSchema });
  // }, [show]);

  // const uid = new ShortUniqueId({ length: 9 });

  const params = useParams();

  // const uploadedCSVRef = useRef(null);

  // const accordionRef=useRef()
  const accordionRef = useRef([]);
  const drawerRef = useRef();

  // let tableName = dataTableSchema.name;

  const handleTableFieldsChange = (e) => {
    setDataTableSchema((old) => {
      return { ...old, [e.target.name]: e.target.value };
    });

    // setDataTableSchema({ ...dataTableSchema, [e.target.name]: e.target.value });
    // if (e.target.name === 'name') {
    //   setDataTableSchema((old) => {
    //     return { ...old, [e.target.name]: e.target.value, tableID: e.target.value };
    //   });
    // } else
    // {
    // }
    // if (existInObject(dataTableSchema, 'name', e.target.value, dataIndex)) {
    //   setTableNameExist(true);
    // } else {
    //   setTableNameExist(false);
    // }
  };

  // const handleNameChangeBlur = () => {
  //   if (tableNameExist) {
  //     dataTableSchema.name += 1;
  //     setTableNameExist(false);
  //   }
  //   let newArr = { ...allDataTableData };
  //   newArr[dataIndex] = dataTableSchema;

  //   setDataTableData(newArr);
  //   // setUpdateDataTable((prev) => prev + 1);
  // };
  const handleAddColumn = ({ columnName, name, type }) => {
    setDataTableSchema((old) => {
      const newColumn = {
        name: name,
        columnName: columnName,
        type: type,
        validation: [],
      };
      setTableUpdates({
        ...tableUpdates,
        addColumns: [...(tableUpdates?.addColumns ?? []), newColumn],
      });
      if (['multipleSelect', 'select'].includes(type)) {
        newColumn.options = [];
      }

      if (type === 'foreignField') {
        newColumn.fetchForeignData = {};
      }

      if (type === 'formula') {
        newColumn.formula = [];
      }

      return {
        ...old,
        columnSchema: [...old.columnSchema, newColumn],
      };
    });
  };

  const handleDelete = (indx) => {
    // console.log('deleting', indx);

    setDataTableSchema((old) => {
      const newCols = [...old.columnSchema];
      newCols.splice(indx, 1);
      setDataTableSchema((old) => {
        return { ...old, columnSchema: newCols };
      });
    });
  };

  const handleOnDragEnd = (param) => {
    if (!param.destination) return;
    if (
      param.source.droppableId === param.destination.droppableId &&
      param.source.index === param.destination.index
    )
      return;
    const srcIndex = param.source.index;
    const destIndex = param.destination.index;
    let tmp = { ...dataTableSchema };
    let colSch = [...dataTableSchema.columnSchema];
    colSch.splice(destIndex, 0, colSch.splice(srcIndex, 1)[0]);
    tmp.columnSchema = colSch;
    // console.log('sampleData',colSch)
    setDataTableSchema(tmp);
  };

  const handleSave = () => {
    const currentTimestamp = Date.now();

    // Collect and trim all column names
    const columnNames = dataTableSchema.columnSchema.map((col) => col.name.trim());

    // Check if all column names are non-empty and unique
    const hasEmptyNames = columnNames.includes('');
    const uniqueColumnNames = new Set(columnNames);
    const hasUniqueNames = uniqueColumnNames.size === columnNames.length;

    if (hasEmptyNames) {
      setErrorMessage('Column names cannot be empty.');
    } else if (!hasUniqueNames) {
      setErrorMessage('Column names must be unique.');
    }

    if (hasEmptyNames || !hasUniqueNames) {
      setColumnNameError(true);
      return;
    }

    setColumnNameError(false);

    setDataTableData(dataTableSchema);
    const sendToBackend = {
      ...dataTableSchema,
      updates: columnUpdates,
      updatedAt: currentTimestamp,
    };

    _supistaApiPost(`settings/${params.appID}/DataTable`, {
      data: sendToBackend,
    })
      .then(() => {
        store.dispatch(
          create({
            setPath: reduxConstants.config.APP_STATUS.TOAST,
            value: {
              message: 'Saved successfully',
              time: 5,
              isActive: true,
            },
          })
        );
        Object.values(relatedTableList || {}).forEach((relatedTable) => {
          const sendRelatedData = {
            ...relatedTable,
            updates: columnUpdates,
            updatedAt: currentTimestamp,
          };
          _supistaApiPost(`settings/${params.appID}/DataTable`, {
            data: sendRelatedData,
          });
          updateTableSchema(
            false,
            null,
            sendRelatedData,
            sendRelatedData.tableID,
            sendRelatedData.name
          );
        });
        publishToProduction(params.appID);
        updateTableSchema(false, null, sendToBackend, sendToBackend.tableID, dataTableSchema.name);
        handleClose();
      })
      .catch(() => {
        store.dispatch(
          create({
            setPath: reduxConstants.config.APP_STATUS.ERROR_TOAST,
            value: {
              message: 'Unable to save',
              time: 5,
              isActive: true,
            },
          })
        );
      });
  };

  // const overWriteColumnSchema = async () => {
  //   const currentTimestamp = Date.now();
  //   const sendToBackend = {
  //     ...localTableData,
  //     columnSchema: uploadedCSVColumnSchema,
  //     updates: columnUpdates,
  //     updatedAt: currentTimestamp
  //   };
  //   const response = await supistaApiPost(`settings/${params.appID}/${isJoinedTable ? 'JoinTable' : 'DataTable'}`, { data: sendToBackend });
  //   if(response.__d3__success) {
  //     uploadRows();
  //   }
  // }

  // const uploadRows = async (rowsData) => {
  //   const CHUNK_SIZE = 1000;
  //   const uploadCSVRowsPromises = [];
  //   const csvDataKeysSpacesRemoved = rowsData;
  //   for (let i = 0; i < csvDataKeysSpacesRemoved.length; i += CHUNK_SIZE) {
  //     const rowsData = csvDataKeysSpacesRemoved.slice(i, i + CHUNK_SIZE);
  //     uploadCSVRowsPromises.push(
  //       _supistaApiPost(`app/${params.appID}/TABLE_SOURCE/${dataTableSchema.name}/uploadCSV`, {
  //         data: {
  //           __d3__csvData: rowsData,
  //         },
  //       })
  //     );
  //   }

  //   const csvRowsUploadResponse = await Promise.all(uploadCSVRowsPromises);
  //   let success = false;
  //   csvRowsUploadResponse.forEach((response) => {
  //     if (response.bulkCreated) {
  //       success = true;
  //     } else {
  //       success = false;
  //     }
  //   });
  //   if (success) {
  //     store.dispatch(
  //       create({
  //         setPath: reduxConstants.config.APP_STATUS.TOAST,
  //         value: {
  //           message: 'CSV uploaded successfully',
  //           time: 5,
  //           isActive: true,
  //         },
  //       })
  //     );
  //     setIsUploadedCSVSchemaNotMatchingErrorShown(false);
  //     publishToProduction(appID);
  //   } else {
  //     store.dispatch(
  //       create({
  //         setPath: reduxConstants.config.APP_STATUS.ERROR_TOAST,
  //         value: {
  //           message: 'Unable to upload CSV data',
  //           time: 5,
  //           isActive: true,
  //         },
  //       })
  //     );
  //   }
  //   publishToProduction(appID);
  // };

  // const discardCSVSchemaNotMacthing = () => {
  //   setUploadedCSVData(null);
  //   setIsUploadedCSVSchemaNotMatchingErrorShown(false);
  // };

  // const handleCsvUpload = (e) => {
  //   const file = e.target.files[0];
  //   const reader = new FileReader();
  //   reader.readAsText(file);
  //   reader.onload = function (e) {
  //     let csvFileData = e.target.result;
  //     Papa.parse(csvFileData, {
  //       header: true,
  //       skipEmptyLines: true,
  //       dynamicTyping: true,
  //       complete: (results) => {
  //         setUploadedCSVData(results.data);
  //         uploadRows(results.data);
  //       },
  //       // transform: transformValue,
  //     });
  //   };
  // };
  const location = useLocation();
  const appID = location.pathname.split('/')[1];

  const handleDeleteTable = () => {
    _supistaApiDelete(`settings/${appID}/DataTable`, {
      data: {
        tableID: dataTableSchema.tableID,
        name: dataTableSchema.name,
      },
    })
      .then((response) => {
        setDeleteOpen(false);
        handleClose();
        store.dispatch(
          create({
            setPath: reduxConstants.config.APP_STATUS.TOAST,
            value: {
              message: 'Table Deleted Successfully',
              time: 5,
              isActive: true,
            },
          })
        );
        publishToProduction(appID);
        updateTableSchema(true, dataTableSchema.tableID);
      })
      .catch((err) => {
        console.error(err);
        store.dispatch(
          create({
            setPath: reduxConstants.config.APP_STATUS.ERROR_TOAST,
            value: {
              message: 'Failed To Delete Table',
              time: 5,
              isActive: true,
            },
          })
        );
      });
  };

  const closeModal = () => {
    // discardCSVSchemaNotMacthing();
    handleClose();
  };

  return (
    <>
      <ConfirmDeleteModal
        isDeleteOpen={deleteOpen}
        setIsDeleteOpen={setDeleteOpen}
        what='Data Table'
        handleDelete={handleDeleteTable}
      />
      <ToastContainer position={'bottom-left'} theme={'colored'} />

      <Modal
        // PaperProps={{
        //   sx: { width: 600 },
        // }}
        anchor='right'
        open={show}
        onClose={closeModal}
      >
        <Box sx={style}>
          <DeleteTable
            setDeleteOpen={setDeleteOpen}
            deleteOpen={deleteOpen}
            handleDeleteTable={handleDeleteTable}
            tableID={dataTableSchema.tableID}
          />
          <div className={`card shadow-none w-100 ${styles.EditTableSettingsPopup}`}>
            <div
              className='card-header stickyH'
              id='kt_explore_header bg-white d-flex justify-content-between'
              style={{
                position: 'sticky',
                top: 0,
                paddingTop: 10,
                zIndex: 200,
                width: '100%',
                background: 'white',
              }}
            >
              <h5 className='card-title fw-bolder fs-2' style={{ marginLeft: -20 }}>
                {mls('Edit Data Table')}
              </h5>

              <div className='card-toolbar'>
                {/* <input
                  type='file'
                  id='file'
                  onChange={handleCsvUpload}
                  accept='.csv'
                  ref={uploadedCSVRef}
                  style={{ display: 'none' }}
                />
                <button
                  className='btn btn-sm  btn-primary btn-light-primary mx-2'
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => {
                    uploadedCSVRef.current.click();
                  }}
                >
                  {mls('Upload CSV')}
                </button> */}
                <button
                  className='btn btn-sm  btn-primary btn-light-primary mx-2'
                  disabled={columnNameError}
                  onClick={handleSave}
                >
                  {mls('Save')}
                </button>
                <button
                  ref={deleteTarget}
                  onClick={() => setDeleteOpen(true)}
                  className='btn btn-sm  btn-primary btn-light-danger mx-2'
                >
                  <i class='fa fa-trash'></i>
                </button>
                <button
                  type='button'
                  className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
                  // id='kt_explore_close'
                  onClick={closeModal}
                >
                  <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
                </button>
              </div>
            </div>

            <div className='mb-5' style={{ position: 'relative', top: 10 }}>
              <label className='form-label fs-6 fw-bolder text-dark mt-3'>
                {mls('Table name')}
              </label>
              <input
                // onChange={handleTableFieldsChange}
                // onBlur={handleNameChangeBlur}
                value={dataTableSchema?.name}
                placeholder='Table name'
                className={clsx('form-control form-control-lg form-control-solid')}
                type='text'
                name='name'
                autoComplete='off'
                disabled
              />
              {/* {tableNameError && (
                <div className='fv-plugins-message-container'>
                  <span className='text-danger' role='alert'>
                    {tableNameError}
                  </span>
                </div>
              )} */}
              <div className='d-flex justify-content-between'>
                <label className='form-label fs-6 fw-bolder text-dark mt-5'>
                  {mls('Description')}
                </label>
                <label className='form-label text-muted fs-6 fw-bolder text-dark mt-5'>
                  {dataTableSchema?.description?.length || 0}/100
                </label>
              </div>
              <textarea
                maxLength={100}
                style={{ resize: 'none', height: 100 }}
                onChange={handleTableFieldsChange}
                // onBlur={() => setUpdateDataTable((prev) => prev + 1)}
                value={dataTableSchema?.description}
                placeholder='Description'
                className={clsx('form-control form-control-lg form-control-solid')}
                type='text'
                name='description'
                autoComplete='off'
              />
            </div>
            <div>
              <div className=''>
                <label className='form-label fs-6 fw-bolder text-dark mt-3'>{mls('Columns')}</label>
                <DragDropContext
                  onDragEnd={(param) => {
                    handleOnDragEnd(param);
                  }}
                >
                  <Droppable droppableId='droppable-1'>
                    {(provided, snapshot) => (
                      <div
                        className='accordion'
                        id='kt_accordion_1'
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {dataTableSchema?.columnSchema?.length === 0 && (
                          <p className='text-muted mx-8 mt-3'>
                            {mls('No column schema available')}.
                          </p>
                        )}
                        {dataTableSchema?.columnSchema?.map((sch, key, originalArray) => (
                          <>
                            {/* {console.table(dataTableSchema.columnSchema, 'edittable')} */}
                            <Draggable key={key} draggableId={`draggable-${key}`} index={key}>
                              {(providedDrag, _) => (
                                <Accordion
                                  elevation={1}
                                  ref={providedDrag.innerRef}
                                  {...providedDrag.draggableProps}
                                  key={key}
                                  style={{
                                    boxShadow: 'none',
                                    backgroundColor: '#f5f8fa',
                                    color: '#5e6278',
                                  }}
                                >
                                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <div
                                      ref={(el) => (accordionRef.current[key] = el)}
                                      id='kt_accordion_1'
                                      className='fs-5 fw-bold collapsed fw-bold mb-1 d-flex align-items-center'
                                      type='button'
                                      data-bs-toggle='collapse'
                                      aria-expanded='false'
                                      aria-controls='kt_accordion_1_body_1'
                                      data-bs-target={`#edit_card_${key}`}
                                    >
                                      <i
                                        {...providedDrag.dragHandleProps}
                                        style={{ marginRight: 10 }}
                                        className='bi fs-3 bi-grip-horizontal mt-1'
                                      ></i>
                                      {mls(dataTableSchema.columnSchema[key].name)}
                                    </div>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <ColumnSettings
                                      allDataTableData={allDataTableData}
                                      // columnExistCounter={columnExistCounter}
                                      // setColumnExistCounter={setColumnExistCounter}
                                      accordionRef={accordionRef}
                                      drawerRef={drawerRef}
                                      key={key}
                                      // setUpdateDataTable={setUpdateDataTable}
                                      dataTableSchema={cloneDeep(dataTableSchema)}
                                      setDataTableSchema={setDataTableSchema}
                                      sch={sch}
                                      // setLocalTableData={setDataTableSchema}
                                      dataIndex={dataIndex}
                                      index={key}
                                      handleDelete={handleDelete}
                                      // setColumnUpdates={setColumnUpdates}
                                      columnNameError={columnNameError}
                                      setColumnNameError={setColumnNameError}
                                      setTableUpdates={setTableUpdates}
                                      tableUpdates={tableUpdates}
                                    >
                                      <button
                                        data-bs-toggle='modal'
                                        data-bs-target='#kt_modal_confirm_delete'
                                        id='kt_toolbar_primary_button'
                                        className='btn btn-sm mt-5  btn-danger btn-light-primary'
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setColSchIndex(key);
                                          setConfirmDeleteModal(true);
                                          setDeleteColIndex(key);
                                        }}
                                      >
                                        {mls('Delete column')}
                                      </button>
                                    </ColumnSettings>
                                  </AccordionDetails>
                                </Accordion>
                              )}
                            </Draggable>
                          </>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
              {errorMessage && <p className='text-danger mx-4'>{errorMessage}</p>}
              <AddNewColumn
                columnSchema={dataTableSchema?.columnSchema}
                allDataTableData={allDataTableData}
                handleAddColumn={handleAddColumn}
              />
            </div>
            <div>
              <RelationsComponent
                allDataTableData={allDataTableData}
                dataTableSchema={dataTableSchema}
                setDataTableSchema={setDataTableSchema}
                setRelatedTableList={setRelatedTableList}
                setTableUpdates={setTableUpdates}
                tableUpdates={tableUpdates}
              />
            </div>

            {/* <div className=' text-white'>f</div> */}
          </div>
        </Box>
      </Modal>
      <ConfirmDelete
        confirmDeleteModal={confirmDeleteModal}
        setConfirmDeleteModal={setConfirmDeleteModal}
        accordionRef={accordionRef}
        handleDelete={handleDelete}
        deleteColIndex={deleteColIndex}
        dataTableSchema={dataTableSchema}
        dataIndex={dataIndex}
        colSchIndex={colSchIndex}
      />
    </>
  );
};
