import { useState } from 'react';
import { Card } from './components/Card';
// import { useSelector } from 'react-redux';
import ComponentSettingWrapper from './componentSettings/ComponentSettingWrapper';
import { mls } from 'lib/multilanguagesupport';
import Sorting from 'lib/reusable-components/Components/Sorting/Sorting';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
const defaultAppComponent = {};
const ComponentPage = () => {
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [searchKeyWord, setSearchKeyWord] = useState('');

  const appSchema = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.APP_SCHEMA);
  const appComponent = appSchema?.appComponent ?? defaultAppComponent;

  const [componentsData, setComponentsData] = useState(
    appComponent ? Object.values(appComponent) : []
  );

  const [open, setOpen] = useState(window.location.search === '?new=true');
  const [isNew, setIsNew] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  return (
    <div className='row'>
      <div className='d-flex align-items-center justify-content-end mb-5'>
        <Sorting
          arrayOfObjects={componentsData}
          setArrayOfObjects={setComponentsData}
          searchBy={'name'}
          keyProperty={'componentID'}
          dataInRedux={appSchema?.appComponent ?? defaultAppComponent}
          updateSearchKey={setSearchKeyWord}
        />
        <button
          onClick={() => {
            handleOpen();
            setIsNew(true);
            setSelectedComponent({});
          }}
          className='btn btn-sm btn-primary'
        >
          {mls('Create Components')}
        </button>
      </div>
      {open && (
        <ComponentSettingWrapper
          data={{ componentSchema: selectedComponent }}
          show={open}
          isNew={isNew}
          setIsNew={setIsNew}
          handleClose={handleClose}
        />
      )}

      {componentsData?.map((component, key) => {
        return (
          <div
            onClick={() => {
              // setSelectedComponent(key);
              setSelectedComponent(component);
              setIsNew(false);
              handleOpen();
            }}
            className='col-md-4 col-sm-6 mb-6'
            key={key}
          >
            <Card
              index={key}
              title={component?.name}
              description={component?.description}
              handleShow={handleOpen}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ComponentPage;
