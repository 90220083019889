import React, { useEffect, useState } from 'react';
import styles from '../ComponentSettingWrapper.module.scss';
import { mls } from 'lib/multilanguagesupport';
import { CircularProgress } from '@mui/material';
import SampleDataPopup from './SampleDataPopup';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { _supistaApiPost } from 'lib/server-connection/connections';

const AICodeForm = ({
  onGenerateCode,
  isAiQueryLoading,
  handleAddCodeManually,
  componentRowList,
  componentSchema,
  appID,
}) => {
  const [requests, setRequests] = useState({
    create: '',
    read: '',
    delete: '',
    update: '',
    formValidation: '',
    actionButtons: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRequests((prevRequests) => ({
      ...prevRequests,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onGenerateCode(requests);
  };
  const [isSampleDataPopup, setIsSampleDataPopup] = useState(false);
  const [sampleData, setSampleData] = useState([]);
  const [selectedId, setSelectedId] = useState(componentRowList?.[0]?.id ?? '');

  const handleComponentChange = (e) => {
    setSelectedId(e.target.value);
  };
  useEffect(() => {
    (async () => {
      const componentID = componentSchema?.componentID;
      const baseUrl = `app/${appID}/Action_Button/${componentID}`;
      const finalUrl = `${baseUrl}`;
      const data = {
        actionType: 'getRowData',
        filterMainComponent: {
          __d3__filterdata: {
            where: { id: selectedId },
          },
        },
      };
      const componentRes = await _supistaApiPost(finalUrl, { data });
      setSampleData(componentRes?.rows ?? []);
    })();
  }, [selectedId, componentSchema?.componentID, appID]);
  const [errors, setErrors] = useState({});

  return (
    <Form onSubmit={handleSubmit} className={styles.aiCodeForm}>
      <div
        className={styles.editorHeader}
        style={{
          justifyContent: 'flex-start',
          marginBottom: '1rem',
          marginTop: '1rem',
          gap: '1rem',
        }}
      >
        <div style={{ width: 'auto', display: 'flex', textAlign: 'end' }}>
          <Form.Group as={Row} className='' style={{ marginLeft: 0, marginRight: 0 }}>
            <Form.Label column sm={3}>
              {mls('Id')}:
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                as='select'
                name='selectId'
                value={selectedId}
                style={{ textAlign: 'end' }}
                onChange={(e) => handleComponentChange(e)}
                isInvalid={!!errors.componentID}
              >
                <option value='' disabled>
                  {mls('Select Id')}
                </option>
                {React.Children.toArray(
                  (componentRowList ?? []).map((componentRowData) => {
                    return (
                      <option value={componentRowData?.id ?? ''} disabled={false}>
                        {componentRowData?.id ?? ''}
                      </option>
                    );
                  })
                )}
              </Form.Control>
              <Form.Control.Feedback type='invalid'>{errors.componentID}</Form.Control.Feedback>
            </Col>
          </Form.Group>
        </div>
        <div
          style={{
            width: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <div>
            {isSampleDataPopup ? (
              <SampleDataPopup
                isSampleDataPopup={isSampleDataPopup}
                setIsSampleDataPopup={setIsSampleDataPopup}
                sampleData={sampleData}
              />
            ) : null}
            {/* <b>{mls('Sample Data')}:</b> */}
            <button
              className='primaryButton secondaryButtonColor'
              onClick={() => setIsSampleDataPopup(true)}
              type='button'
            >
              {mls('Selected Row Data')}
            </button>
          </div>
        </div>
      </div>
      {['create', 'read', 'delete', 'update', 'formValidation', 'actionButtons'].map(
        (type, index) => (
          <Form.Group key={index} controlId={`form${type.charAt(0).toUpperCase() + type.slice(1)}`}>
            <Form.Label>{`${
              type.charAt(0).toUpperCase() + type.slice(1)
            } API Customization Request`}</Form.Label>
            <Form.Control
              type='text'
              placeholder={`Ask AI how you want to customize your API`}
              name={type}
              value={requests[type]}
              onChange={handleChange}
            />
          </Form.Group>
        )
      )}
      <div className={styles.aiCodeFormButonContainer}>
        <Button variant='primary' type='submit' className={styles.generateButton}>
          {isAiQueryLoading ? (
            <>
              <CircularProgress color='inherit' size={10} /> &nbsp;
            </>
          ) : null}
          {mls('Generate Code using AI')}
        </Button>
        <Button
          variant='link'
          style={{ marginLeft: 'auto' }}
          type='button'
          onClick={() => handleAddCodeManually()}
          className={styles.generateButton}
        >
          {mls('Add Code Manually')}
        </Button>
      </div>
    </Form>
  );
};

export default AICodeForm;
