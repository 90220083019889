import React, { useMemo, useState } from 'react';
import MainConnectedTableSelection from '../MainConnectedTableSelection';
import { Accordion } from 'react-bootstrap';
import styles from './additionalSettings.module.scss';
import ColumnDragableContainer from './ColumnDragableContainer';
import AppComponentFilter from '../../AppComponentFilter/AppComponentFilter';
import { getAppComponentColumnSchema } from '../PDFTemplates/helperFuntion';
import { mls } from 'lib/multilanguagesupport';

interface AdditionalSettingsProps {
  componentSchema: any;
  connectedTablesData: any;
  appDatatable: any;
  appSchema: any;
  setRelatedTableList: Function;
}
const AdditionalSettings = ({
  componentSchema,
  connectedTablesData,
  appDatatable,
  setRelatedTableList,
  appSchema,
}: AdditionalSettingsProps) => {
  const [filterData, setFilterData] = useState({
    popupStatus: false,
    filters: {},
    tableID: '',
    columnName: '',
  });
  const handleFilters = (action: any) => {
    setFilterData({ ...filterData, filters: action });
  };
  const handleFilterPopupStatus = () => {
    const filters = filterData?.filters;
    const tableID = filterData?.tableID;
    const columnName = filterData?.columnName;
    const relatedTableList = componentSchema?.tableList;
    const relatedTable = relatedTableList[tableID] ?? {};

    const newDefaultView = (relatedTable?.defaultView ?? []).map((columnNameElem: any) => {
      if (columnNameElem?.columnName === columnName ?? '') {
        return { ...columnNameElem, filters: filters };
      }
      return columnNameElem;
    });
    let udpatedRelatedTableList = {
      ...relatedTableList,
      [tableID]: {
        ...relatedTable,
        defaultView: newDefaultView,
      },
    };
    setRelatedTableList(udpatedRelatedTableList);

    setFilterData({ ...filterData, popupStatus: !filterData.popupStatus });
  };
  const columnSchema = useMemo(() => {
    return getAppComponentColumnSchema({
      connectedTableList: componentSchema?.connectedTableList ?? [],
      appDatatable: appSchema?.appDatatable ?? {},
    });
  }, [componentSchema?.connectedTableList, appSchema?.appDatatable]);
  return (
    <div className={styles.AdditionalSettings}>
      <div className={styles.mainTableContainer}>
        <div className={styles.mainTableTitle}>{mls('Main Table')}:</div>
        <div className={styles.selectColumnContainer}>
          <Accordion>
            <ColumnDragableContainer
              tableID={componentSchema?.table}
              appDatatable={appDatatable}
              setRelatedTableList={setRelatedTableList}
              filterData={filterData}
              setFilterData={setFilterData}
              relatedTableList={componentSchema?.tableList}
            />
          </Accordion>
        </div>
      </div>
      <div className={styles.connectedTablesContainer}>
        <div className={styles.chooseConnectedTables}>
          <MainConnectedTableSelection
            connectedTablesData={connectedTablesData}
            componentSchema={componentSchema}
            relatedTableList={componentSchema?.tableList ?? {}}
            setRelatedTableList={setRelatedTableList}
          />
          {filterData.popupStatus ? (
            <AppComponentFilter
              setChartFilter={handleFilters}
              chartFilter={filterData?.filters ?? {}}
              allTableData={columnSchema}
              appID={''}
              sheetID={''}
              isJoinedTable={false}
              isPopUp={filterData.popupStatus}
              setIsPopUp={handleFilterPopupStatus}
            />
          ) : null}
        </div>
        <div className={styles.selectColumnContainer}>
          <Accordion>
            {React.Children.toArray(
              Object.keys(componentSchema?.tableList ?? {}).map((tableID: string) => {
                if (componentSchema?.table === tableID) {
                  return <></>;
                }
                return (
                  <ColumnDragableContainer
                    tableID={tableID}
                    appDatatable={appDatatable}
                    setRelatedTableList={setRelatedTableList}
                    filterData={filterData}
                    setFilterData={setFilterData}
                    relatedTableList={componentSchema?.tableList}
                  />
                );
              })
            )}
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default AdditionalSettings;
