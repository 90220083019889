import { useState } from 'react';
import { Chip } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';

const SelectChip = ({
  onClick, 
  label,
  selected,
  removeDisabled = false,
}) => {
  const [color, setColor] = useState('default');
  
  const handleChipAndIconClick = () => {
    // If the chip is not removable
    if(removeDisabled) {
      return;
    }
    // Currently, onCLick and onDelete are identical functions, they both handle the select or deselect of column
    onClick();
  }

  const selectedColor = '#3598dc';
  const notSelectedColor = 'grey'

  return (
    <Chip
      onMouseOver={() => setColor('default')}
      onMouseOut={() => setColor('default')}
      color={color}
      onClick={handleChipAndIconClick}
      label={label}
      deleteIcon={ <CheckCircle />}
      onDelete={handleChipAndIconClick}
      sx={{
        '.MuiChip-deleteIcon': {
          color: removeDisabled ? 'grey' : selected ? selectedColor : notSelectedColor,
        },
        '.MuiChip-deleteIcon: hover': {
          color: removeDisabled ? 'grey' : selected ? selectedColor : notSelectedColor,
        }
      }}
    />
  );
}

export default SelectChip;