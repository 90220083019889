import React, { useEffect, useState } from 'react';
import './dataLakeSearch.scss';
import SearchInputDiv from 'lib/reusable-components/Components/SearchInputDiv/SearchInputDiv';
import { mls } from 'lib/multilanguagesupport';
interface dataLakeSearchProps {
  objectForSearch: { [key: string]: any };
  searchBy?: string;
  secondarySearchBy?: string;
  setSearch: Function;
  fullWidth?: boolean;
  updateSearchKey?: Function;
  placeholder?: string;
}
interface handleFilterProps {
  filterKey: string;
  secondarySearchBy: string;
  objectForSearch: { [key: string]: any };
  searchBy: string;
}
const DataLakeSearch = ({
  objectForSearch,
  searchBy = 'name',
  secondarySearchBy = 'name',
  setSearch,
  fullWidth = false,
  updateSearchKey = () => {},
  placeholder = mls('Search'),
}: dataLakeSearchProps) => {
  const [searchKey, setSearchKey] = useState('');

  const handleFilter = ({
    filterKey,
    objectForSearch,
    searchBy,
    secondarySearchBy,
  }: handleFilterProps) => {
    let filterdObject = {};
    Object.keys(objectForSearch || {}).forEach((objectForSearchKey) => {
      const objectForSearchElem = objectForSearch[objectForSearchKey];
      const searchValue = (objectForSearchElem?.[searchBy] ?? '').toLowerCase();
      const secondarySearchValue = (objectForSearchElem?.[secondarySearchBy] ?? '').toLowerCase();
      if (searchValue.includes(filterKey.toLowerCase())) {
        filterdObject = { ...filterdObject, [objectForSearchKey]: objectForSearchElem };
        return;
      }
      if (secondarySearchValue.includes(filterKey.toLowerCase())) {
        filterdObject = { ...filterdObject, [objectForSearchKey]: objectForSearchElem };
      }
    });
    return filterdObject;
  };

  useEffect(() => {
    const filterdObject = handleFilter({
      filterKey: searchKey,
      objectForSearch,
      searchBy,
      secondarySearchBy,
    });

    setSearch(filterdObject);
    updateSearchKey(searchKey);
  }, [objectForSearch, searchKey, searchBy, secondarySearchBy, setSearch, updateSearchKey]);

  return (
    <SearchInputDiv
      value={searchKey}
      onChange={setSearchKey}
      border
      fullWidth={fullWidth}
      placeholder={placeholder}
    />
  );
};

export default DataLakeSearch;
