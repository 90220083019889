/*
This is the project edit page - Renders project navigation bar at left and edit form at right
*/

import { useState } from 'react';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import ProjectEdit from '../components/ProjectEdit';
import ProjectNavbar from '../components/ProjectNavbar';

import * as editPageConstants from '../constants';
import { supistaApiPost } from 'app/connections/connections';

import store from 'lib/reusable-components/reusableUtils/redux/store';
import { create } from 'lib/reusable-components/reusableUtils/redux/stateSlice';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import ConfirmDeleteModal from 'app/pages/sharable/ConfirmDeleteModal';
import useUpdateProjectsSchema from 'lib/reusable-components/reusableUtils/commons/useUpdateProjectsSchema';

export const EditProjectPage = ({ openedProject, closeEditScreen }) => {
  const app = useSelector((store) => store.config_store.appSchema.app);
  const location = useLocation();
  const appID = location.pathname.split('/')[1];
  const updateProjects = useUpdateProjectsSchema();

  const [activePageInEditing, setActivePageInEditing] = useState({
    activePage: editPageConstants.PROJECT,
    screenGroupIndex: null,
    screenIndex: null,
  });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const formik = useFormik({
    initialValues: openedProject,
    onSubmit: async (values) => {
      const projectsInRedux = global.structuredClone(app.projects);
      const updatedProjects = projectsInRedux.filter((project) => {
        // Old projects do not have the projectID field. Keeping those projects
        if (!project.projectID) return true;
        return project.projectID !== values.projectID;
      });
      const currentProjectDataInFormik = { ...values };
      // Remove the 'isNewProject' field
      if (currentProjectDataInFormik.isNewProject) {
        delete currentProjectDataInFormik.isNewProject;
      }
      updatedProjects.push({ ...currentProjectDataInFormik, updatedAt: Date.now() });
      try {
        await supistaApiPost(`settings/${appID}/App`, {
          data: {
            ...app,
            projects: updatedProjects,
          },
        });
        updateProjects(updatedProjects);
        store.dispatch(
          create({
            setPath: reduxConstants.config.APP_STATUS.TOAST,
            value: {
              message: 'Project saved successfully',
              time: 5,
              isActive: true,
            },
          })
        );
        closeEditScreen();
      } catch (err) {
        store.dispatch(
          create({
            setPath: reduxConstants.config.APP_STATUS.ERROR_TOAST,
            value: {
              message: "Project couldn't be saved",
              time: 5,
              isActive: true,
            },
          })
        );
      }
    },
  });

  const handleDeleteProject = async () => {
    const projectsInRedux = global.structuredClone(app.projects);
    let updatedProjects;
    if (formik.values?.projectID) {
      updatedProjects = projectsInRedux.filter((project) => {
        // Old projects do not have the projectID field. Keeping those projects
        if (!project.projectID) return true;
        return project.projectID !== formik.values.projectID;
      });
    } else {
      updatedProjects = projectsInRedux.filter((project) => {
        return project.name !== formik.values.name;
      });
    }

    try {
      await supistaApiPost(`settings/${appID}/App`, {
        data: {
          ...app,
          projects: updatedProjects,
        },
      });
      updateProjects(updatedProjects);
      store.dispatch(
        create({
          setPath: reduxConstants.config.APP_STATUS.TOAST,
          value: {
            message: 'Project deleted successfully',
            time: 5,
            isActive: true,
          },
        })
      );
    } catch (err) {
      store.dispatch(
        create({
          setPath: reduxConstants.config.APP_STATUS.ERROR_TOAST,
          value: {
            message: "Project couldn't be deleted",
            time: 5,
            isActive: true,
          },
        })
      );
    }
    closeEditScreen();
  };

  return (
    <>
      {/* Navbar and edit page */}
      <ConfirmDeleteModal
        isDeleteModalOpen={isDeleteModalOpen}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        what={editPageConstants.PROJECT}
        handleDelete={handleDeleteProject}
      />

      <div className='row gx-5' style={{ height: '80vh' }}>
        <ProjectNavbar
          project={openedProject}
          closeEdit={closeEditScreen}
          activePageInEditing={activePageInEditing}
          setActivePageInEditing={setActivePageInEditing}
          formik={formik}
        />
        <ProjectEdit
          activePageInEditing={activePageInEditing}
          setActivePageInEditing={setActivePageInEditing}
          formik={formik}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
        />
      </div>

      {/* <h1>Edit page</h1>
  <h4>{openedScreenData.name}</h4>
  <button onClick={closeEditScreen}>Close</button> */}
    </>
  );
};
