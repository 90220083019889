import { useEffect, useState } from 'react';
import { mls } from 'lib/multilanguagesupport';

export const ForeignFieldSection = ({
  dataTableSchema,
  currentColumn,
  currentColumnIndex,
  setDataTableSchema,
  colIndex,
  dataTableData,
}) => {
  const [selectedField, setSelectedField] = useState();

  // console.log(dataTableData);

  useEffect(() => {
    if (dataTableSchema.columnSchema[colIndex]?.fetchForeignData?.hasOwnProperty('tableID')) {
      let columnNames = dataTableData.filter(
        (data) => data._id === dataTableSchema.columnSchema[colIndex]?.fetchForeignData.tableID
      );
      setSelectedField(columnNames[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    setSelectedField(JSON.parse(e.target.value));
  };

  // const handleSave = (e) => {
  //   dataTableSchema?.columnSchema[colIndex]['fetchForeignData'] = {
  //     tableID: selectedField._id,
  //     columnName: e.target.value,
  //   };
  //   // setDataTableSchema(sampleData);
  // };

  return (
    <div className='px-7 mt-7 py-5' style={{ background: '#F5F8FA' }}>
      <p className='mb-5 fs-6 fw-bolder text-dark'>Fetch Foreign Field</p>
      <div style={{ maxHeight: 300, overflowY: 'auto', overflowX: 'hidden' }}>
        <select
          name='tableName'
          className='form-select form-control form-control-lg'
          onChange={handleChange}
        >
          <option value='' hidden>
            {mls('Select...')}
          </option>
          {dataTableData.length === 0 && (
            <option disabled selected value>
              {' '}
              {mls('No table available')}{' '}
            </option>
          )}
          {Array.isArray(dataTableData) &&
            (dataTableData || []).map((data, key) => (
              <option
                key={key}
                value={JSON.stringify(data)}
                // selected={
                //   data._id === dataTableSchema?.columnSchema[colIndex]?.fetchForeignData?.tableID
                // }
                // hidden={data._id === currentColumn ? true : false}
              >
                {mls(data.name)}
              </option>
            ))}
        </select>
        {selectedField && (
          <select
            name='columnName'
            className='form-select form-control form-control-lg mt-4'
            // onChange={handleSave}
          >
            <option value='' hidden>
              {mls('Select...')}
            </option>
            {(selectedField.columnSchema || []).map((data, key) => (
              <option
                key={key}
                // selected={
                //   dataTableSchema?.columnSchema[colIndex]?.fetchForeignData?.columnName ===
                //   data.name
                // }
                // hidden={data.type === 'select' || data.type === 'multiple' ? false : true}
                value={data.name}
              >
                {mls(data.name)}
              </option>
            ))}
          </select>
        )}
      </div>
    </div>
  );
};
