import { useState, useEffect } from 'react';
import { KTSVG } from '_metronic/helpers';
import sortArrayOfObjects from 'utils/sortArrayOfObjects';
import { mls } from 'lib/multilanguagesupport';

const Sorting = ({
  arrayOfObjects,
  setArrayOfObjects,
  dataInRedux
}) => {
  const [isSortAscending, setIsSortAscending] = useState(true); // true = ascending order sort; false = descending order sort
  const [sortBy, setSortBy] = useState('alphabetically');
  
  // Load sorting configuration from localStorage if exists
  useEffect(() => {
    const storedSortConfig = JSON.parse(localStorage.getItem('sortConfig'));
    if(storedSortConfig) {
      setSortBy(storedSortConfig.sortMethod);
      setIsSortAscending(storedSortConfig.sortAscending);
    }
  }, []);

  // Sort the data tables
  useEffect(() => {
    if(dataInRedux) {
      const sortConfig = { sortMethod: sortBy, sortAscending: isSortAscending };
      localStorage.setItem('sortConfig', JSON.stringify(sortConfig));
      const sorted = sortArrayOfObjects(Object.values(dataInRedux), sortBy, isSortAscending);
      setArrayOfObjects(sorted);
    }
  }, [dataInRedux, sortBy, isSortAscending, setArrayOfObjects]);

  return <>
    <label>{mls('Sort by:')}</label>
    <select
      className='form-select form-select-sm mx-1'
      style={{ width: '18%' }}
      value={sortBy}
      onChange={(e) => { console.log('change order'); setSortBy(e.target.value) }}
    >
      <option value="alphabetically">{mls('Alphabetically')}</option>
      <option value="createdAt">{mls('Created At')}</option>
      <option value="updatedAt">{mls('Updated At')}</option>
    </select>
    <button
      type="button"
      className="btn btn-sm btn-light mx-1 px-4"
      onMouseDown={(e) => e.preventDefault()}    // remove hover from button
      onClick={(e) => {e.preventDefault(); setIsSortAscending((old) => !old)}}  // invert sorting order
    >
      <KTSVG className='m-0' path={`/media/icons/duotune/arrows/${isSortAscending ? 'arr066.svg' : 'arr065.svg' }`} />
    </button>
  </>
  
}

export default Sorting;