import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { GripVertical } from 'react-bootstrap-icons';
import styles from './additionalSettings.module.scss';
import { mls } from 'lib/multilanguagesupport';
import SelectedColumnPopup from './SelectedColumnPopup';
import './additionalSettings.scss';
import { Form } from 'react-bootstrap';
import { FiFilter } from 'react-icons/fi';

interface columnDragableContainerProps {
  tableID: string;
  appDatatable: any;
  relatedTableList: { [key: string]: any };
  setRelatedTableList: Function;
  filterData: any;
  setFilterData: Function;
}
const ColumnDragableContainer = ({
  tableID,
  appDatatable,
  relatedTableList,
  setRelatedTableList,
  filterData,
  setFilterData,
}: columnDragableContainerProps) => {
  const tableData = appDatatable[tableID] ?? {};
  const relatedTable = relatedTableList[tableID] ?? {};
  const defaultView = relatedTable?.defaultView ?? [];
  const handleDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const reorderedDefaultView = Array.from(defaultView);
    const [movedScreen] = reorderedDefaultView.splice(result.source.index, 1);
    reorderedDefaultView.splice(result.destination.index, 0, movedScreen);
    let udpatedRelatedTableList = {
      ...relatedTableList,
      [tableID]: {
        ...relatedTable,
        defaultView: reorderedDefaultView,
      },
    };
    setRelatedTableList(udpatedRelatedTableList);
  };
  const [isSelectedColumnPopup, setIsSelectedColumnPopup] = useState(false);
  const handleIsSelectedColumnPopup = () => {
    setIsSelectedColumnPopup(!isSelectedColumnPopup);
  };
  const handleSelectColumnsButton = (e: any) => {
    e.stopPropagation();
    setIsSelectedColumnPopup(!isSelectedColumnPopup);
  };
  const handleScreenName = (e: any) => {
    let udpatedRelatedTableList = {
      ...relatedTableList,
      [tableID]: {
        ...relatedTable,
        screenName: e.target.value,
      },
    };
    setRelatedTableList(udpatedRelatedTableList);
  };
  const specialDataType = ['hasOne', 'hasMany', 'belongsToMany'];
  const isSpecialDataType = specialDataType.some(
    (elem: string) => elem === relatedTable?.relationType
  );
  const specialTypeName = {
    belongsTo: {
      key: 'belongsTo',
      name: 'Belongs To Relation',
    },
    MAIN: {
      key: 'MAIN',
      name: 'Main Table',
    },
    hasOne: {
      key: 'hasOne',
      name: 'Has One Relation',
    },
    belongsToMany: {
      key: 'belongsToMany',
      name: 'Belongs To Many Relation',
    },
  };
  return (
    <>
      <Accordion.Item eventKey={tableID} className='selectedColumnAccordion'>
        <Accordion.Header>
          <div className={styles.draggableItemHeader}>
            {tableData?.name ?? tableID} &nbsp; ({' '}
            {specialTypeName[relatedTable?.relationType as keyof typeof specialTypeName]?.name ??
              relatedTable?.relationType}{' '}
            ) &nbsp;
            <button
              className='primaryButton secondaryButtonColor'
              onClick={(e) => handleSelectColumnsButton(e)}
              style={{ border: 'solid 1px #dfe2ef' }}
            >
              {mls('Select Columns')}
            </button>
          </div>

          {isSelectedColumnPopup ? (
            <SelectedColumnPopup
              isSelectedColumnPopup={isSelectedColumnPopup}
              handleIsSelectedColumnPopup={handleIsSelectedColumnPopup}
              relatedTable={relatedTable}
              relatedTableList={relatedTableList}
              tableID={tableID}
              tableData={tableData}
              setRelatedTableList={setRelatedTableList}
            />
          ) : null}
        </Accordion.Header>
        <Accordion.Body>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId='screens'>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '.5rem',
                    }}
                  >
                    {isSpecialDataType ? (
                      <>
                        <Form.Group
                          controlId='actionButtonLabel'
                          className={styles.screenNameInputContainer}
                        >
                          <Form.Label className={styles.screenNameInputLabel}>
                            {mls('Screen Name')}:
                          </Form.Label>
                          <Form.Control
                            type='text'
                            value={relatedTable?.screenName}
                            onChange={handleScreenName}
                          />
                        </Form.Group>
                        <hr />
                      </>
                    ) : null}

                    {React.Children.toArray(
                      (defaultView ?? []).map((defaultViewElem: any, positonValue: number) => {
                        const columnName = defaultViewElem?.columnName;
                        const columnData = (tableData?.columnSchema ?? []).find(
                          (columnNameElem: { [key: string]: any }) =>
                            columnNameElem?.columnName === columnName
                        );

                        const handleFilter = ({
                          defaultViewElem,
                          columnName,
                          tableID,
                        }: {
                          defaultViewElem: any;
                          columnName: string;
                          tableID: string;
                        }) => {
                          setFilterData({
                            ...filterData,
                            popupStatus: true,
                            filters: defaultViewElem?.filters ?? {},
                            columnName: columnName,
                            tableID: tableID,
                          });
                        };
                        const filterLength = ({ defaultViewElem }: { defaultViewElem: any }) => {
                          const filterLength = Object.keys(defaultViewElem?.filters ?? {})?.length;

                          if (filterLength > 0) {
                            return (
                              <div>
                                (<b>{mls('Applied')}</b>: {filterLength})
                              </div>
                            );
                          }
                          return '';
                        };
                        const isDragDisabled = defaultViewElem?.columnName === 'id';
                        if (isDragDisabled) {
                          return (
                            <div>
                              <div className={styles.draggableItem}>
                                <GripVertical className={styles.dragIcon} />
                                {columnData?.name ?? columnName}
                                <div className={styles.filterIconContainer}>
                                  {filterLength({ defaultViewElem })}
                                  <button
                                    className='primaryButton secondaryButtonColor'
                                    onClick={() => {
                                      handleFilter({ defaultViewElem, columnName, tableID });
                                    }}
                                  >
                                    {mls('Filter')}
                                  </button>
                                  {/* <FiFilter
                                        className={styles.filterIcon}
                                        onClick={() => {
                                          handleFilter({ defaultViewElem, columnName, tableID });
                                        }}
                                      /> */}
                                </div>
                              </div>
                            </div>
                          );
                        }
                        return (
                          <div>
                            <Draggable
                              key={columnName}
                              draggableId={columnData?.columnName}
                              index={positonValue}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div className={styles.draggableItem}>
                                    <GripVertical className={styles.dragIcon} />
                                    {columnData?.name ?? columnName}
                                    <div className={styles.filterIconContainer}>
                                      {filterLength({ defaultViewElem })}
                                      <button
                                        className='primaryButton secondaryButtonColor'
                                        onClick={() => {
                                          handleFilter({ defaultViewElem, columnName, tableID });
                                        }}
                                      >
                                        {mls('Filter')}
                                      </button>
                                      {/* <FiFilter
                                        className={styles.filterIcon}
                                        onClick={() => {
                                          handleFilter({ defaultViewElem, columnName, tableID });
                                        }}
                                      /> */}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          </div>
                        );
                      })
                    )}
                    {provided.placeholder}
                  </div>
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {!defaultView?.[0] ? `${mls('No column selected please select any column')}!` : null}
        </Accordion.Body>
      </Accordion.Item>
    </>
  );
};

export default ColumnDragableContainer;
