// import { supistaApiPost } from 'lib/data-connectors/dependencies/connections';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { columnTypeChecker } from '../helper/columnTypeChecker';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import { create } from 'lib/reusable-components/reusableUtils/redux/stateSlice';
import store from 'lib/reusable-components/reusableUtils/redux/store';

// import useUpdateTableSchema from 'lib/data-connectors/dependencies/useUpdateTableSchema';
import { useSelector } from 'react-redux';

import { mls } from 'lib/multilanguagesupport';
import useUpdateTableSchema from '../../../hooks/useUpdateTableSchema';
import {
  charactersNotAllowedInTableAndColumnName,
  convertToTitleCase,
  renameKeysInArrayOfObjects,
} from '../../../components/util/utilFunctions';
import { _supistaApiPost } from 'lib/server-connection/connections';
import { publishToProduction } from '../../../components/util/publishToProduction';
// import { publishToProduction } from 'lib/reusable-components/reusableFunction/publishToProductions';

// import { charactersNotAllowedInTableAndColumnName } from 'lib/data-connectors/dependencies/constant';
// import {
//   convertColumnDataType,
//   removeSpacesFromKeys,
//   renameKeysInArrayOfObjects,
// } from 'lib/data-connectors/dependencies/utilFunctions';
// import { publishToProduction } from 'lib/data-connectors/dependencies/publishToProductions';

export const CreateNewDataTableSettings = ({
  csvData,
  setCsvData,
  filename = '',
  setTableSchema = () => {},
  setActiveModalPage = () => {},
  isManual = false,
  setFilename,
  setDataTableData,
  setOpen,
}) => {
  // const [originalCsvData, setOriginalCsvData] = useState([...csvData]);
  const [csvDataKey, setCsvDataKey] = useState([]);
  const [tableNameError, setTableNameError] = useState(false);
  const [tableName, setTableName] = useState(filename?.split('.csv')[0] || '');
  const [colTypeChangeError, setColTypeChangeError] = useState({ index: null, targetType: null });
  const [isAnyColumnNameEmpty, setIsAnyColumnNameEmpty] = useState(false);
  const [nonUniqueColumnNames, setNonUniqueColumnNames] = useState([]);
  const [invalidColumnNameIndices] = useState(new Set());
  const [currentlySelectedFieldName, setCurrentlySelectedFieldName] = useState();

  const appDatatable = useSelector((state) => state.config_store.appSchema.appDatatable);

  // Checks the validity of table name on each change
  useEffect(() => {
    const containsCharacterWhichIsNotAllowed = charactersNotAllowedInTableAndColumnName.some(
      (char) => {
        return tableName.includes(char);
      }
    );
    const tableWithThisNameAlreadyExists = appDatatable?.[tableName] ? true : false;
    tableWithThisNameAlreadyExists && setTableNameError('Table name must be unique');
    containsCharacterWhichIsNotAllowed &&
      setTableNameError(
        "Table name can only contain these special characters: space (' '), hyphen (-) and underscore (_)"
      );
    !containsCharacterWhichIsNotAllowed &&
      !tableWithThisNameAlreadyExists &&
      setTableNameError(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableName]);

  // Checks if any column has empty name - it is not allowed
  useEffect(() => {
    const anyColWithEmptyName = csvDataKey.some((tableRow) => tableRow.name === '');
    setIsAnyColumnNameEmpty(anyColWithEmptyName);
  }, [csvDataKey]);

  const param = useParams();
  const updateTableSchema = useUpdateTableSchema();

  useEffect(() => {
    if (!isManual) {
      const res = columnTypeChecker(csvData[0]);
      setCsvDataKey(res);

      // Check presence of invalid characters in all column names if CSV is uploaded
      res.forEach((col, index) => {
        // const containsCharacterWhichIsNotAllowed = charactersNotAllowedInTableAndColumnName.some(
        //   (char) => {
        //     return col.name.includes(char);
        //   }
        // );
        // if (containsCharacterWhichIsNotAllowed) {
        //   setInvalidColumnNameIndices((old) => {
        //     const newSet = new Set(old);
        //     newSet.add(index);
        //     return newSet;
        //   });
        // }
      });
    } else {
      addColumn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Find non-unique column names in every change to columns data
  useEffect(() => {
    setNonUniqueColumnNames(findNonUniqueColumnNames());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csvDataKey]);

  const findNonUniqueColumnNames = () => {
    const columnNames = csvDataKey.map((col) => col.name);
    const uniqueSet = new Set(columnNames);
    // eslint-disable-next-line array-callback-return
    const nonUniqueColumnNames = columnNames.filter((columnName) => {
      // If the column name is present in set, remove it
      if (uniqueSet.has(columnName)) {
        uniqueSet.delete(columnName);
      }

      // If the column is NOT present in the set, it means it has been removed by the above if condition and
      // this current one is the repeating one, so this column name is present more than once and it is non-unique
      else {
        return columnName;
      }
    });

    return nonUniqueColumnNames;
  };

  const addColumn = () => {
    setCsvDataKey([...csvDataKey, { name: '', columnName: '', dataType: 'text' }]);
  };
  const handleRemoveColumn = (index) => {
    setCsvDataKey((old) => {
      const newArray = [...old];
      newArray.splice(index, 1);
      return newArray;
    });
  };

  const handleColumnNameChange = (e, key) => {
    setCsvDataKey((old) => {
      const newArray = [...old];
      newArray[key].name = e.target.value;
      return newArray;
    });

    // const containsCharacterWhichIsNotAllowed = charactersNotAllowedInTableAndColumnName.some(
    //   (char) => {
    //     return e.target.value.includes(char);
    //   }
    // );
    // if (containsCharacterWhichIsNotAllowed) {
    //   setInvalidColumnNameIndices((old) => {
    //     const newSet = new Set(old);
    //     newSet.add(key);
    //     return newSet;
    //   });
    // } else {
    //   setInvalidColumnNameIndices((old) => {
    //     const newSet = new Set(old);
    //     newSet.delete(key);
    //     return newSet;
    //   });
    // }
  };

  const handleColumnDatatypeChange = (e, index, datatype) => {
    if (e.target.value === 'select' || e.target.value === 'multiple') {
      // Getting all values of this column in an array
      const allValuesOfThisColumn = (csvData || []).map((row) => {
        return row[csvDataKey[index].name];
      });
      // Getting all the unique values
      const uniqueValues = [...new Set(allValuesOfThisColumn)];
      // Constructing the 'selectMenu' property with all distinct data of this column
      const selectMenu = uniqueValues.map((value) => {
        return { label: value, value: value.trim() };
      });

      setCsvDataKey((old) => {
        const newArray = [...old];
        newArray[index] = {
          ...newArray[index],
          dataType: e.target.value,
          selectMenu,
        };
        return newArray;
      });

      // No need of converting or checking the data
      return;
    }

    // const conversionResult = convertColumnDataType(
    //   csvData,
    //   datatype?.name,
    //   e.target.value,
    //   originalCsvData
    // );
    // if (!isManual && conversionResult === false) {
    //   // CHANGE LOGIC HERE TO CONVERT THE ROW TYPES TOO
    //   setColTypeChangeError({ index: index, targetType: e.target.value });
    //   setTimeout(() => {
    //     setColTypeChangeError({ index: null, targetType: null });
    //   }, 5000);
    //   return;
    // }
    // setCsvData(conversionResult);
    setColTypeChangeError({ index: null, targetType: null });
    setCsvDataKey((old) => {
      old[index].dataType = e.target.value;
      // console.log(old, 'old data +new');
      return [...old];
    });
  };

  const focusInputField = (e) => {
    setCurrentlySelectedFieldName(e.target.value);
  };

  const renameCSVDataKeysOnBlur = (e) => {
    // Rename keys in CSV data array of objects only if the column name is changed
    if (e.target.value !== currentlySelectedFieldName) {
      const updated = renameKeysInArrayOfObjects(
        csvData,
        currentlySelectedFieldName,
        e.target.value
      );
      setCsvData(updated);
    }
  };

  const save = () => {
    let data = csvDataKey.map((dataKey) => {
      return {
        ...dataKey,
        columnName: dataKey.name,
        name: convertToTitleCase(dataKey.name ?? ''),
      };
    });

    // setTableSchema({
    //   name: tableName,
    //   columnSchema: data,
    //   tableID: tableName,
    //   appID: param.appID,
    // });

    const currentTimestamp = Date.now();

    _supistaApiPost(`settings/${param.appID}/DataTable`, {
      data: {
        name: tableName,
        columnSchema: data,
        tableID: tableName,
        appID: param.appID,
        version: 2.0,
        updates: {
          addColumns: [],
          updateColumns: [],
          deleteColumns: [],
          renameColumns: {},
          dropTable: false,
          createTable: true,
        },
        createdAt: currentTimestamp,
        updatedAt: currentTimestamp,
      },
    })
      .then((response) => {
        // console.log(response);
        // console.log(csvData, 'csvData');
        // If manual table creation, display success message immediately after creating the table
        if (isManual) {
          store.dispatch(
            create({
              setPath: reduxConstants.config.APP_STATUS.TOAST,
              value: {
                message: 'Saved successfully',
                time: 5,
                isActive: true,
              },
            })
          );
        }
        setTimeout(() => {
          // Sending the csv rows data to backend only if CSV upload is done
          if (!isManual) {
            // Size of chunk of rows' data to be sent at once
            const CHUNK_SIZE = 1000;
            const csvDataKeysSpacesRemoved = csvData;
            // var success = true;
            const rowsDataUploadPromises = [];
            for (let i = 0; i < csvDataKeysSpacesRemoved.length; i += CHUNK_SIZE) {
              const rowsData = csvDataKeysSpacesRemoved.slice(i, i + CHUNK_SIZE);
              rowsDataUploadPromises.push(
                _supistaApiPost(`app/${param.appID}/TABLE_SOURCE/${tableName}/uploadCSV`, {
                  data: {
                    __d3__csvData: rowsData,
                  },
                })
              );
            }

            Promise.all(rowsDataUploadPromises)
              .then((csvDataUploadResponses) => {
                let success = false;
                for (let i = 0; i < csvDataUploadResponses.length; i++) {
                  const oneChunkRowUploadResponse = csvDataUploadResponses[i];
                  if (oneChunkRowUploadResponse.bulkCreated) {
                    success = true;
                  } else {
                    success = false;
                  }
                  if (success === false) throw new Error('Rows not uploaded');
                }
                publishToProduction(param.appID);
              })
              .catch((error) => {
                store.dispatch(
                  create({
                    setPath: reduxConstants.config.APP_STATUS.ERROR_TOAST,
                    value: {
                      message:
                        'Table created but rows cannot be uploaded. Check the column data types properly.',
                      time: 5,
                      isActive: true,
                    },
                  })
                );
              });
          }
        }, 100);

        // Update the local redux
        updateTableSchema(
          false,
          null,
          {
            name: tableName,
            columnSchema: data,
            tableID: tableName,
            appID: param.appID,
            updates: {
              addColumns: [],
              updateColumns: [],
              deleteColumns: [],
              renameColumns: {},
              dropTable: false,
              createTable: true,
            },
            createdAt: currentTimestamp,
            updatedAt: currentTimestamp,
          },
          tableName
        );
      })
      .catch((err) => {
        // console.log(err);
      });
    setOpen(false);
    publishToProduction(param.appID);
  };

  return (
    <div className='d-flex flex-column justify-content-center align-items-center p-5'>
      <h1 className='mb-5 mt-10'>{isManual ? 'Create Table' : 'CSV Data'}</h1>
      <div style={{ width: 600 }}>
        <label className='col-md-5 fs-5 fw-bold mb-2'>Table Name</label>
        <input
          type='text'
          className='checkbox form-control form-control-solid'
          placeholder='Table Name'
          value={tableName}
          onChange={(e) => setTableName(e.target.value)}
        />
        {tableNameError && (
          <div className='fv-plugins-message-container'>
            <span className='text-danger' role='alert'>
              {tableNameError}
            </span>
          </div>
        )}
        <h3 className='mb-10 mt-10'>
          {isManual ? 'Add Columns' : 'Verify the Type & Column Name'}
        </h3>
        <div className='row d-flex justify-content-center w-100 mb-5 mr-0'>
          <label className='col-md-4 fs-5 fw-bold'>&nbsp; Column Name</label>
          <label className='col-md-4 fs-5 fw-bold'>&nbsp; Column Type</label>
          <label className='col-md-3 fs-5 fw-bold text-center'>Is Required</label>
          <label className='col-md-1 fs-5 fw-bold text-center'></label>
        </div>
        <div style={{ overflowY: 'auto', overflowX: 'hidden' }}>
          {csvDataKey &&
            Array.isArray(csvDataKey) &&
            (csvDataKey || []).map((datatype, key) => (
              <div className='row d-flex justify-content-center' key={key}>
                <div className='col-md-4'>
                  <input
                    type='text'
                    // If it has any error, add m-0 since the margin before the next column input field will be added by the error container
                    className={`checkbox form-control form-control-solid mb-1 ${
                      nonUniqueColumnNames.includes(datatype.name) ||
                      invalidColumnNameIndices.has(key)
                        ? 'mb-0'
                        : 'mb-5'
                    }`}
                    placeholder='Column Name'
                    value={datatype.name}
                    onChange={(e) => {
                      handleColumnNameChange(e, key);
                    }}
                    onFocus={focusInputField}
                    onBlur={renameCSVDataKeysOnBlur}
                  />
                  {nonUniqueColumnNames.includes(datatype.name) && (
                    // If it is the only error, add the margin else leave it for next error container to add the margin
                    <div
                      className={`fv-plugins-message-container ${
                        invalidColumnNameIndices.has(key) ? 'mb-0' : 'mb-5'
                      }`}
                    >
                      <span className='text-danger fs-8' role='alert'>
                        {'Column name must be unqiue'}
                      </span>
                    </div>
                  )}
                  {invalidColumnNameIndices.has(key) && (
                    <div className='fv-plugins-message-container mb-5'>
                      <span className='text-danger fs-8' role='alert'>
                        {'Invalid character added'}
                      </span>
                    </div>
                  )}
                </div>
                <div className='col-md-4'>
                  <select
                    onChange={(e) => handleColumnDatatypeChange(e, key, datatype)}
                    defaultValue='text'
                    className='form-select form-control form-control-solid mb-1'
                    key={key}
                    value={datatype.dataType}
                  >
                    <option value='text'>Text</option>
                    <option value='number'>Number</option>
                    <option value='decimal'>Decimal</option>
                    <option value='boolean'>Boolean</option>
                    <option value='check'>Checkbox</option>
                    <option value='select'>Select</option>
                    <option value='multiple'>Multiple</option>
                    <option value='datePicker'>Date</option>
                  </select>
                  {/* Display column type change error */}
                  {key === colTypeChangeError.index && (
                    <span className='text-danger'>
                      {mls(`Column type cannot be set as ${colTypeChangeError?.targetType}`)}
                    </span>
                  )}
                </div>
                <div className='col-md-3 form-check d-flex justify-content-center align-items-center'>
                  <input className='form-check-input form-check-solid mb-5' type='checkbox' />
                </div>
                <div className='col-md-1 form-check d-flex justify-content-start p-0 align-items-center'>
                  <i
                    className='fas fa-trash-alt text-danger mb-5 text-danger mr-auto'
                    style={{ cursor: 'pointer' }}
                    onClick={handleRemoveColumn.bind(null, key)}
                  />
                </div>
              </div>
            ))}
        </div>
        {isManual && (
          <button onClick={addColumn} className='btn btn-sm btn-primary mb-10 mt-10'>
            {mls('Add Column')}
          </button>
        )}
      </div>
      <div
        style={{
          position: 'sticky',
          bottom: -70,
        }}
        className='d-flex justify-content-end w-100 py-5 px-5 bg-white'
      >
        <div className='me-4'>
          <button
            href='#'
            className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder'
            data-bs-dismiss='modal'
            onClick={() => setOpen(false)}
          >
            {mls('Cancel')}
          </button>
        </div>
        <button
          href='#'
          className='btn btn-sm btn-primary'
          disabled={
            tableName.trim() === '' ||
            tableNameError ||
            isAnyColumnNameEmpty ||
            nonUniqueColumnNames.length > 0 ||
            invalidColumnNameIndices.size > 0
          }
          onClick={save}
        >
          {mls('Save Table')}
        </button>
      </div>
    </div>
  );
};
