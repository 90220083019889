/*
Shows the app projects in cards
*/

import { useState } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import Card from '../components/Card';

import Sorting from 'app/pages/sharable/Sorting';

import { mls } from 'lib/multilanguagesupport';

export const ProjectsHomePage = ({
  openProjectToEdit
}) => {
  const projects = useSelector((state) => state.config_store.appSchema.app.projects);
  const [sortedProjects, setSortedProjects] = useState([ ...projects ]);

  const handleCreateProject = () => {
    const currentTimestamp = Date.now();
    const newProjectBlankData = {
      isNewProject: true,
      name: '',
      icon: '',
      description: '',
      screenGroup: [],
      connectedScreenGroup: [],
      projectID: uuid(),
      createdAt: currentTimestamp,
      updatedAt: currentTimestamp
    }
    openProjectToEdit(newProjectBlankData);
  }

  return <>
    <div className='d-flex justify-content-end align-items-center mb-5'>
      <Sorting
        dataInRedux={projects}
        arrayOfObjects={sortedProjects}
        setArrayOfObjects={setSortedProjects}
      />
      <button
        onClick={handleCreateProject}
        className='btn btn-sm btn-primary'
      >
        {mls('Create New Project')}
      </button>
    </div>
    <div className='row'>
      {sortedProjects.map((project, index) => {
        return <div
          className='col-md-4 col-sm-6 mb-6'
          key={index}
          onClick={() => openProjectToEdit(project)}
        >
          <Card
            project={project}
          />
        </div>
      })}
    </div>
  </>
}