import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@mui/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Checkbox, ListItemText, Paper, TextField, Typography } from '@mui/material/';
import Table from '@mui/material//Table';
// import Tbody from '@mui/material/Tbody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Thead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import translate from 'translate';
// import Loading from '../../../error/Loading';
import { areEqual } from '../sharable/commons';
import { sendMlsData } from './sendMlsData';
import store from 'lib/reusable-components/reusableUtils/redux/store';
import { create } from 'lib/reusable-components/reusableUtils/redux/stateSlice';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import { supistaApiPost } from 'app/connections/connections';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';

const GoogleAPI = 'AIzaSyAGhlipRn43ICgvGtAJ7nO9yiNAaQFaPuA';

const useStyles = makeStyles({
  root: {
    padding: '8px',
  },
  rootMenu: {
    position: 'fixed',
    background: '#F5F8FA',
    left: 0,
    right: 30,
    top: 90,
    marginTop: -25,
    paddingTop: 20,
  },
  heading: {
    width: '100%',
    marginBottom: '8px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  headingButton: {
    marginRight: '8px',
  },
  moduleRoot: {
    margin: '8px',
    marginTop: '50px',
  },
  moduleHeading: {
    padding: '8px',
    fontWeight: 'bold',
  },
  tableHead: {
    fontWeight: 'bold',
  },
  translationCell: {
    cursor: 'pointer',
    '&:hover': {
      borderStyle: 'groove',
    },
  },
});

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
));

const languagesList = [
  'Japanese',
  'Hindi',
  'Italian',
  'French',
  'German',
  'Russian',
  'Hebrew',
  'Kannada',
  'Korean',
  'Latin',
  'Marathi',
  'Tamil',
  'Turkish',
];

const EditableCell = ({ value, updateValue, isOriginal = false, textLen, index }) => {
  const classes = useStyles();
  const [isClicked, setClicked] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);
  const handleBlur = () => {
    updateValue(currentValue);
    setClicked(false);
  };
  const handleChange = (evt) => {
    setCurrentValue(evt.target.value);
  };
  return (
    <div className='d-flex align-items-center mx-3 my-2' key={index}>
      <textarea
        className='form-control form-control-lg form-control-solid'
        style={{
          resize: 'none',
          height: textLen > 40 ? 70 : 45,
          minWidth: 350,
          backgroundColor: isOriginal ? '#F4F8FB' : '#F5F8FA',
        }}
        value={currentValue}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isOriginal}
      />
    </div>
  );
};

const ModuleWiseText = ({
  moduleData: { name, text },
  currentActiveLanguage,
  mlsData,
  setMlsData,
  updateMLS,
  setChanged,
  index,
}) => {
  const classes = useStyles();
  const handleValueChange = (language, englishText, newValue) => {
    // console.log(
    //   { ...mlsData, [language]: { ...mlsData[language], [englishText]: newValue } },
    //   'mlsData new data '
    // );
    setMlsData({ ...mlsData, [language]: { ...mlsData[language], [englishText]: newValue } });
    // saving new value in the server by changing with hand value.
    updateMLS({ languageDict: { [language]: { [englishText]: newValue } } });
    setChanged(true);
  };
  let gridTemplateColumns = '';
  currentActiveLanguage.forEach(() => {
    gridTemplateColumns += ' 1fr';
  });
  const tableRowStyle = {
    gridTemplateColumns,
    display: 'grid',
  };
  return (
    <>
      <Paper
        elevation={0}
        className={classes.moduleRoot}
        style={{ border: '5px solid white' }}
        key={index}
      >
        <div className='row'>
          <Typography
            variant='h5'
            style={{ fontWeight: '550', color: '#5e6278' }}
            className='p-6 pb-4 text-center  col-4'
          >
            Original text
          </Typography>
          <Typography
            variant='h5'
            style={{ fontWeight: '550', color: '#5e6278' }}
            className='p-6 pb-4 text-center col-8'
          >
            Translated text
          </Typography>
        </div>
        <div className='d-flex'>
          <div style={{ width: `400px` }}>
            <div>
              <div>
                <div
                  className='fw-bolder text-muted fs-6 p-7'
                  style={{
                    fontWeight: 'bold',
                    background: '#F4F8FB',
                    width: 370,
                  }}
                >
                  English
                </div>
              </div>
            </div>
            <div>
              {(text || []).map((englishText, key) => (
                <div style={tableRowStyle} key={key}>
                  <div
                    style={{
                      // color: '#777B82',
                      // width: 400,
                      // height: 63,
                      // overflow: 'auto',
                      background: '#F4F8FB',
                    }}
                    className='fw-bolder fs-6 d-flex align-items-center '
                    component='th'
                  >
                    <EditableCell
                      index={key}
                      value={englishText}
                      updateValue={(newValue) =>
                        handleValueChange('English', englishText, newValue)
                      }
                      textLen={englishText?.length}
                      isOriginal={true}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <TableContainer elevation={0} component={Paper}>
            <div className={classes.table} aria-label='simple table'>
              <div>
                <div style={tableRowStyle}>
                  {(currentActiveLanguage || []).map((lang, key) => (
                    <div
                      key={key}
                      className='fw-bolder text-muted fs-6 p-7'
                      style={{
                        fontWeight: 'bold',
                        background: lang === 'English' ? '#F4F8FB' : 'white',
                        width: 400,
                      }}
                    >
                      {lang}
                    </div>
                  ))}
                </div>
              </div>
              <div>
                {(text || []).map((englishText, key) => (
                  <div key={key} style={tableRowStyle}>
                    {(currentActiveLanguage || []).map((lang, key) => (
                      <EditableCell
                        index={key}
                        value={mlsData[lang]?.[englishText] || ''}
                        updateValue={(newValue) => handleValueChange(lang, englishText, newValue)}
                        textLen={englishText?.length}
                      />
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </TableContainer>
        </div>
      </Paper>
    </>
  );
};

function MLSUI({
  english,
  languageDict,
  defaultLanguage = 'English',
  activeLanguages,
  setDefaultLanguage,
  setActiveLanguages,
  setLanguageDict,
  updateMLS,
  appID,
}) {
  const classes = useStyles();
  const [isChanged, setChanged] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElDefaultLanguage, setAnchorElDefaultLanguage] = useState(null);
  // const [translated, setTranslated] = useState(false);
  const [isTranslating, setTranslating] = useState(false);
  const appSchema = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.APP_SCHEMA);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickDefaultLanguage = (event) => {
    setAnchorElDefaultLanguage(event.currentTarget);
  };

  const handleTranslate = () => {
    setTranslating(true);
    const allTranslation = activeLanguages.map(
      (lang) =>
        new Promise((res, rej) => {
          const translation = [];
          // fetching untranslated words.
          Object.keys(english).forEach((key) => {
            (english[key].text || []).forEach((text) => {
              if (!languageDict[lang]?.[text] || !languageDict[lang]?.[text]?.length) {
                // Empty string gives error in translation; Adding only non-empty strings for translation
                if (text?.trim() !== '') {
                  translation.push(text);
                }
              }
            });
          });
          // translating to the current language
          const translatePromise = translation.map((text) => {
            const promise = new Promise((resolve, reject) => {
              translate(text, { to: lang, engine: 'google', key: GoogleAPI })
                .then((translatedText) => {
                  // storing the translated texts
                  resolve({ [text]: translatedText });
                })
                .catch((error) => reject(error));
            });
            return promise;
          });
          try {
            Promise.all(translatePromise).then((translatedTexts) => {
              // storing the translated texts
              const newTranslations = {};
              translatedTexts.forEach((text) =>
                Object.keys(text).forEach((EnglishText) => {
                  newTranslations[EnglishText] = text[EnglishText];
                })
              );
              res({ [lang]: { ...(languageDict[lang] || {}), ...newTranslations } });
            });
          } catch (error) {
            rej(error);
            console.error('Failed to translate', error);
          }
        })
    );
    Promise.all(allTranslation)
      .then((languaesTranslation) => {
        // console.log(languaesTranslation, 'lt');
        const newTranslation = languageDict;
        languaesTranslation.forEach((languageObj) => {
          const languageName = Object.keys(languageObj)[0];
          newTranslation[languageName] = {
            ...(newTranslation[languageName] || {}),
            ...languageObj[languageName],
          };
        });
        setTranslating(false);
        setLanguageDict(newTranslation);
        setChanged(true);
        updateMLS({ languageDict: newTranslation });
      })
      .catch((error) => {
        // console.log(error, 'err last');
      });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseDefaultLanguage = () => {
    setAnchorElDefaultLanguage(null);
  };
  const handleDefaultMenuClick = (lang) => {
    handleCloseDefaultLanguage();
    setDefaultLanguage(lang);
    // Update the app schema on server; set target lang as default
    supistaApiPost(`settings/${appID}/App`, {
      data: {
        ...appSchema.app,
        defaultLanguage: lang,
      },
    });
    setChanged(true);
    // updating the server side
    updateMLS({ defaultLanguage: lang });
  };
  const handleSupportedLanguage = (event, language) => {
    // Removing the language from active
    if (activeLanguages.includes(language)) {
      const newActiveLanguages = [...activeLanguages];
      const index = newActiveLanguages.indexOf(language);
      if (index > -1) {
        newActiveLanguages.splice(index, 1);
        setActiveLanguages([...newActiveLanguages]);
        // Update the app schema on server; remove the target language from active
        supistaApiPost(`settings/${appID}/App`, {
          data: {
            ...appSchema.app,
            activeLanguages: [...newActiveLanguages],
          },
        });
        updateMLS({ activeLanguages: newActiveLanguages });
      }
    }
    // Adding the language to active
    else {
      const newActiveLanguages = [...activeLanguages, language];
      setActiveLanguages(newActiveLanguages);
      // Update the app schema on server; add the selected language to active
      supistaApiPost(`settings/${appID}/App`, {
        data: {
          ...appSchema.app,
          activeLanguages: newActiveLanguages,
        },
      });
      updateMLS({ activeLanguages: newActiveLanguages });
    }
    setChanged(true);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeLanguages]);

  if (isTranslating) {
    // return <Loading loadingText='Translating...' />;
    return '';
  }
  const handleSave = () => {
    const response = sendMlsData(appID, languageDict, activeLanguages);
    // console.log(response);
    setChanged(false);
    store.dispatch(
      create({
        setPath: reduxConstants.config.APP_STATUS.TOAST,
        value: {
          message: 'Saved successfully',
          time: 5,
          isActive: true,
        },
      })
    );
  };
  return (
    <div className={classes.root}>
      <div className={classes.rootMenu}>
        <div className={classes.heading}>
          {isChanged && (
            <button
              aria-controls='customized-menu'
              aria-haspopup='true'
              onClick={handleSave}
              className='btn btn-sm btn-primary btn-primary mx-2 mb-3'
            >
              Save
            </button>
          )}

          <button
            aria-controls='customized-menu'
            aria-haspopup='true'
            onClick={handleClickDefaultLanguage}
            className='btn btn-sm btn-primary btn-primary mx-2 mb-3'
          >
            Default Language: {defaultLanguage}
          </button>
          <Menu
            id='simple-menu'
            anchorEl={anchorElDefaultLanguage}
            keepMounted
            open={Boolean(anchorElDefaultLanguage)}
            onClose={handleCloseDefaultLanguage}
          >
            {['English', ...languagesList].map((lang, key) => (
              <MenuItem key={key} onClick={() => handleDefaultMenuClick(lang)}>
                {lang}
              </MenuItem>
            ))}
          </Menu>
          <button
            aria-controls='customized-menu'
            aria-haspopup='true'
            variant='contained'
            color='primary'
            onClick={handleTranslate}
            className='btn btn-sm btn-primary mx-2 mb-3'
          >
            Auto Translate Remaining Texts
          </button>
          <button
            aria-controls='customized-menu'
            aria-haspopup='true'
            variant='contained'
            color='primary'
            onClick={handleClick}
            className='btn btn-sm btn-primary mx-2 mb-3'
          >
            Add Language
          </button>
        </div>
        <StyledMenu
          id='customized-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {languagesList.map((lang, key) => (
            <MenuItem
              key={key}
              onClick={(event) => {
                handleSupportedLanguage(event, lang);
              }}
            >
              <Checkbox
                checked={activeLanguages.includes(lang)}
                color='primary'
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />

              <ListItemText primary={lang} />
            </MenuItem>
          ))}
        </StyledMenu>
      </div>
      {Object.keys(english).map((key) => (
        <ModuleWiseText
          index={key}
          moduleData={english[key]}
          mlsData={languageDict}
          setMlsData={setLanguageDict}
          currentActiveLanguage={activeLanguages}
          updateMLS={updateMLS}
          setChanged={setChanged}
        />
      ))}
    </div>
  );
}

MLSUI.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  english: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  languageDict: PropTypes.object.isRequired,
  defaultLanguage: PropTypes.string.isRequired,
  activeLanguages: PropTypes.arrayOf(PropTypes.string).isRequired,
  updateMLS: PropTypes.func.isRequired,
  setDefaultLanguage: PropTypes.func.isRequired,
  setActiveLanguages: PropTypes.func.isRequired,
  setLanguageDict: PropTypes.func.isRequired,
  appID: PropTypes.string.isRequired,
};

export default memo(MLSUI, areEqual);
