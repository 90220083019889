export const defaultCode = `function customizeERP({token, functionID, appID}, supistaCRUDOperation) {
    // Your Code Starts Here

}`;
export const curdOpType = {
  DEFAULT: {
    key: 'DEFAULT',
    name: 'DEFAULT',
  },
  CUSTOM: {
    key: 'CUSTOM',
    name: 'CUSTOM',
  },
};

export const buttonTypeObject = {
  CRUD: {
    key: 'CRUD',
    name: 'CRUD Button',
  },
  actionButton: {
    key: 'actionButton',
    name: 'Action Button',
  },
};
export const componenetType = {
  table: {
    key: 'table',
    name: 'Table',
  },
  calendar: {
    key: 'calendar',
    name: 'Calendar',
  },
};
export const crudTypeButtonObject = {
  POST: {
    key: 'POST',
    label: 'Create',
  },
  GET: {
    key: 'GET',
    label: 'Read',
  },
  PUT: {
    key: 'PUT',
    label: 'Update',
  },
  DELETE: {
    key: 'DELETE',
    label: 'Delete',
  },
  FORMVALIDATION: {
    key: 'FORMVALIDATION',
    label: 'Form Validation',
  },
};
export const columnBooleanTypeObject = {
  AllTrueWithExc: {
    key: 'AllTrueWithExc',
    name: 'All True With Exc',
  },
  AllFalseWithExc: {
    key: 'AllFalseWithExc',
    name: 'All False With Exc',
  },
};
export const defaultTeamAccessObject = {
  type: columnBooleanTypeObject?.AllTrueWithExc?.key,
  ColumnWiseRoles: {},
  filter: {},
};

export const filterTypeObject = {
  teamFilter: {
    key: 'teamFilter',
  },
  columnFilter: {
    key: 'columnFilter',
  },
};
