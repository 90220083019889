import { mls } from 'lib/multilanguagesupport';
import store from 'lib/reusable-components/reusableUtils/redux/store';
import { create } from 'lib/reusable-components/reusableUtils/redux/stateSlice';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import { _supistaApiGet } from 'lib/server-connection/connections';

export const fetchData = (appID, isUpdate = false) => {
  _supistaApiGet(`appSchema/${appID}`)
    .then((res) => {
      if (res.__d3__error !== true) {
        store.dispatch(
          create({
            setPath: reduxConstants.config.APP_SCHEMA,
            value: res,
          })
        );
        store.dispatch(
          create({
            setPath: reduxConstants.config.APP_STATUS.TOAST,
            value: {
              message: `${isUpdate ? mls('App Updated Successfully') : mls('App Fetched Successfully')}`,
              time: 5,
              isActive: true,
            },
          })
        );
      }
    })
    .catch((err) => {
      store.dispatch(
        create({
          setPath: reduxConstants.config.APP_STATUS.ERROR_TOAST,
          value: {
            message: `${isUpdate ? mls('There was a problem updating the app, please try again later!') : mls('There was a problem while fetching App Schema, please try again later!')}`,
            time: 5,
            isActive: true,
          },
        })
      );
    });
};
