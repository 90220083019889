import clsx from 'clsx';

export const OptionSection = ({ optionKey, handleChangOption, option, deleteOptionValidation }) => {
  return (
    <>
      <div className='col-6' style={{ marginRight: 20, marginLeft: -10 }}>
        <div className='d-flex align-items-center'>
          <i
            onClick={() => deleteOptionValidation(optionKey, true)}
            className='fas fa-trash-alt text-danger'
            style={{ marginRight: 15, fontSize: 15, cursor: 'pointer' }}
          ></i>
          <input
            onChange={(e) => handleChangOption(e, optionKey)}
            placeholder='Label'
            className={clsx('form-control form-control-lg')}
            type='text'
            name='label'
            autoComplete='off'
            defaultValue={option.label}
          />
        </div>
      </div>
      <div className='col-6'>
        <input
          onChange={(e) => handleChangOption(e, optionKey)}
          placeholder='Value'
          className={clsx('form-control form-control-lg ')}
          type='text'
          name='value'
          autoComplete='off'
          defaultValue={option.value}
        />
      </div>
    </>
  );
};
