import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Modal, Box } from '@mui/material';
import { KTSVG } from '_metronic/helpers';

import { mls } from 'lib/multilanguagesupport';
import SelectChip from 'app/pages/sharable/SelectChip';

const AddRemoveComponentsModal = ({
  isComponentModalOpen,
  setIsComponentModalOpen,
  formik,
  activePageInEditing
}) => {
  const appComponent = useSelector((state) => state.config_store.appSchema.appComponent);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60vw',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    pt: 0,
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    // justifyContent: 'center',     // Causing scroll issue
    maxHeight: '80vh',
    overflow: 'auto'
  };

  const addComponentToScreen = (component) => {
    const componentsInThisScreen = global.structuredClone((formik.values?.screenGroup?.[activePageInEditing.screenGroupIndex]?.screen?.[activePageInEditing.screenIndex]?.component) || []);
    componentsInThisScreen.push({ componentID: component.componentID });
    formik.setFieldValue(`screenGroup.${activePageInEditing.screenGroupIndex}.screen.${activePageInEditing.screenIndex}.component`, componentsInThisScreen);
  }
  const removeComponentFromScreen = (component) => {
    const componentsInThisScreen = global.structuredClone((formik.values?.screenGroup?.[activePageInEditing.screenGroupIndex]?.screen?.[activePageInEditing.screenIndex]?.component) || []);
    const componentsAfterRemoving = componentsInThisScreen.filter((existingComponent) => {
      return existingComponent.componentID !== component.componentID
    });
    componentsInThisScreen.push({ componentID: component.componentID });
    formik.setFieldValue(`screenGroup.${activePageInEditing.screenGroupIndex}.screen.${activePageInEditing.screenIndex}.component`,componentsAfterRemoving);
  }

  return <>
    <Modal
      open={isComponentModalOpen}
      onClose={() => setIsComponentModalOpen(false)}
    >
      <Box sx={style}>
        <div className='card shadow-none w-100'>
          <div
            className='card-header'
            id='kt_explore_header bg-white d-flex justify-content-between'
            style={{ position: 'sticky', top: 0, paddingTop: 10, paddingLeft: 20, zIndex: 200, width: '100%', background: 'white', marginLeft: '0%' }}
          >
            <h5 className='card-title fw-bolder fs-2' style={{ marginLeft: -20 }}>{mls('Screen Components')}</h5>

            <div className='card-toolbar'>

              {/* Modal close button */}
              <button
                type='button'
                className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
                onClick={() => setIsComponentModalOpen(false)}
                style={{ position: 'absolute', top: 20, right: 5 }}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
              </button>
            </div>
          </div>

          <div className='row mt-5'>
            {!_.isEmpty(appComponent) && Object.values(appComponent).map((component) => {
              return formik.values?.screenGroup?.[activePageInEditing.screenGroupIndex]?.screen?.[activePageInEditing.screenIndex]?.component?.find((componentInScreen) => componentInScreen.componentID === component.componentID)
                ?
                <div className='col-4 my-1' key={component.componentID}>
                  <SelectChip
                    label={component?.name}
                    selected={true}
                    onClick={removeComponentFromScreen.bind(null, component)}
                  />
                </div>
                :
                <div className='col-4 my-1' key={component.componentID}>
                  <SelectChip
                    label={component?.name}
                    selected={false}
                    onClick={addComponentToScreen.bind(null, component)}
                  />
                </div>
            })}
          </div>
        </div>
      </Box>

    </Modal>

  </>
}

export default AddRemoveComponentsModal;