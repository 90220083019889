import React, { useState, useEffect, useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import Header from './Header';
import Tabs from './Tabs';
import ComponentDetails from './TabContent/ComponentDetails/ComponentDetails';
import ComponentAiCustomization from './TabContent/ComponentAiCustomization';
import styles from './ComponentSettingWrapper.module.scss';
import { mls } from 'lib/multilanguagesupport';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import {
  _supistaApiDelete,
  _supistaApiGet,
  _supistaApiPost,
} from 'lib/server-connection/connections';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import './componentSettingWrapper.scss';

import PDFTemplateWrapper from './TabContent/PDFTemplates/PDFTemplateWrapper';

import { toast, ToastContainer } from 'react-toastify';
import RoleAccess from './TabContent/RoleAccess/RoleAccess';
import 'react-toastify/dist/ReactToastify.css';
import './tostifyOverrideScss.scss';
import {
  getConnectedTables,
  tableDataToRelatedTableData,
} from './TabContent/PDFTemplates/helperFuntion';
import { publishToProduction } from 'lib/reusable-components/reusableFunction/publishToProductions';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import useUpdateEffect from 'lib/reusable-components/hooks/useUpdateEffect';
import AdditionalSettings from './TabContent/AdditionalSettings/AdditionalSettings';
import { componenetType } from './data/appComponentData';

const initialComponentSchema = () => ({
  componentID: uuidv4(),
  name: '',
  description: '',
  type: componenetType.table.key,
  table: '',
});
const defaultCrudOp = {
  DELETE: {
    type: 'DEFAULT',
  },
  POST: {
    type: 'DEFAULT',
  },
  PUT: {
    type: 'DEFAULT',
  },
  FORMVALIDATION: {
    type: 'DEFAULT',
  },
  GET: {
    type: 'DEFAULT',
  },
};
const fetchComponentData = async ({ componentID, appID }) => {
  const baseUrl = `app/${appID}/CRUD/${componentID}`;
  const filter = `data=%7B%22__d3__filterdata%22%3A%7B%22where%22%3A%7B%7D%7D%2C%22__d3__screenFilter%22%3A%7B%22where%22%3A%7B%7D%7D%7D&lang=English`;
  const finalUrl = `${baseUrl}?${filter}`;
  const componentRes = await _supistaApiGet(finalUrl);
  return componentRes;
};
const ComponentSettingWrapper = ({ data, show, handleClose, isNew, setIsNew }) => {
  const appSchema = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.APP_SCHEMA);

  const appDatatable = appSchema?.appDatatable ?? {};
  const appComponent = appSchema?.appComponent ?? {};

  const appData = appSchema?.app || {};
  const [tempData, setTempData] = useState({
    app: appData,
    appComponent: appComponent,
  });

  const [componentSchema, setComponentSchema] = useState({
    ...(initialComponentSchema() ?? {}),
    ...(data?.componentSchema ?? {}),
    crudOp: data?.componentSchema?.crudOp ?? defaultCrudOp,
    createdAt: data?.componentSchema?.createdAt ?? Date.now(),
    updatedAt: data?.componentSchema?.updatedAt ?? Date.now(),
  });
  console.log(componentSchema);

  const [connectedScreenGroupSchema2, setConnectedScreenGroupSchema] = useState(
    data.connectedScreenGroupSchema || {}
  );
  const [updatedConnectedScreeens, setUpdatedConnectedScreeens] = useState({});
  const connectedScreenGroupSchema = useMemo(() => {
    const connectedScreens = componentSchema?.connectedScreens ?? {};
    const convertConnectedScreenFunction = ({ connectedScreens }) => {
      let tempConnectedScreens = [];
      Object.keys(connectedScreens).forEach((connectedScreenElem) => {
        const connectedScreenkey = connectedScreens[connectedScreenElem];
        const connectedScreenGroup = tempData?.app?.connectedScreenGroup ?? {};
        if (connectedScreenGroup[connectedScreenkey]) {
          tempConnectedScreens = [
            ...tempConnectedScreens,
            connectedScreenGroup[connectedScreenkey],
          ];
        }
      });
      return tempConnectedScreens;
    };
    const convertedConnectedScreeen = convertConnectedScreenFunction({ connectedScreens });
    return { connectedScreenGroup: convertedConnectedScreeen };
  }, [componentSchema, tempData]);
  const [isComponetDetailsChange, setIsComponetDetailsChange] = useState(false);

  const [componentSchemaList, setComponentSchemaList] = useState(data.componentSchemaList || []);
  const [errors, setErrors] = useState({});
  const location = useLocation();
  const appID = location.pathname.split('/')[1];

  useEffect(() => {
    // if (!data.componentSchema) {
    //   setComponentSchema(initialComponentSchema());
    // }
    if (!data.connectedScreenGroupSchema) {
      setConnectedScreenGroupSchema({
        connectedScreenGroup: [],
      });
    }
  }, [data.componentSchema, data.connectedScreenGroupSchema]);
  const handleAppSchemaUpdate = async () => {
    const apiUrl = `settings/${appID}/App`;
    const payloadData = {
      data: {
        ...tempData.app,
      },
    };
    const componenetUpdateRes = await _supistaApiPost(apiUrl, payloadData);
    if (componenetUpdateRes.__d3__success) {
      return { skip: false, response: componenetUpdateRes?.response };
    }
    return { skip: true };
  };
  const handleComponentsUpdate = async () => {
    const apiUrl = `settings/${appID}/Components`;
    const payloadData = {
      data: {
        ...componentSchema,
        updatedAt: data?.componentSchema?.updatedAt ?? Date.now(),
      },
    };
    const componenetUpdateRes = await _supistaApiPost(apiUrl, payloadData);
    if (componenetUpdateRes.__d3__success) {
      return { skip: false, response: componenetUpdateRes?.response };
    }
    return { skip: true };
  };
  const handleUpatedComponenet = () => {
    const handleComponentsUpdate = async (componentSchema) => {
      const apiUrl = `settings/${appID}/Components`;
      const payloadData = {
        data: {
          ...componentSchema,
          updatedAt: data?.componentSchema?.updatedAt ?? Date.now(),
        },
      };
      const componenetUpdateRes = await _supistaApiPost(apiUrl, payloadData);
      if (componenetUpdateRes.__d3__success) {
        return { skip: false, response: componenetUpdateRes?.response };
      }
      return { skip: true };
    };
    Object.keys(updatedConnectedScreeens).forEach(async (updatedConnectedScreeensKeys) => {
      const updatedConnectedScreeensData = updatedConnectedScreeens[updatedConnectedScreeensKeys];
      const componentsSchmea = await handleComponentsUpdate(updatedConnectedScreeensData);
      if (componentsSchmea.skip) {
        return;
      }
    });
    return { skip: false };
  };
  const handleSave = async (isClose = true) => {
    if (validateForm()) {
      const appUpdateSchmea = await handleAppSchemaUpdate();
      if (appUpdateSchmea.skip) {
        return;
      }
      const componentsSchmea = await handleComponentsUpdate();
      if (componentsSchmea.skip) {
        return;
      }
      const UpatedComponenetSchmea = await handleUpatedComponenet();
      if (UpatedComponenetSchmea.skip) {
        return;
      }
      await publishToProduction(appID);
      setIsNew(false);
      setIsComponetDetailsChange(false);
      toast.success(mls('Componenet Saved!'));
      if (isClose) {
        handleClose();
      }
    }
  };

  const handleDelete = () => {
    // Add your delete logic here
    _supistaApiDelete(`settings/${appID}/Components`, {
      data: {
        componentID: componentSchema.componentID,
        name: componentSchema.name,
      },
    })
      .then(async (res) => {
        await publishToProduction(appID);
        handleClose();
      })
      .catch((err) => {});
  };

  const validateForm = () => {
    let formErrors = {};
    if (!componentSchema.name) formErrors.name = 'Name is required';
    if (!componentSchema.type) formErrors.type = 'Component type is required';
    if (!componentSchema.table) formErrors.table = 'Connected table is required';

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    let formErrors = { ...errors };
    if (!value) {
      formErrors[name] = `${name.charAt(0).toUpperCase() + name.slice(1)} is required`;
    } else {
      delete formErrors[name];
    }
    setErrors(formErrors);
  };
  const setConnectedTableList = (value) => {
    setComponentSchema((prevSchema) => ({
      ...prevSchema,
      connectedTableList: value,
    }));
  };
  const setRelatedTableList = (value) => {
    setComponentSchema((prevSchema) => ({
      ...prevSchema,
      tableList: value,
    }));
  };
  const [componentRowList, setComponentRowList] = useState([]);

  const connectedTablesData = useMemo(() => {
    const appDatatable = appSchema?.appDatatable ?? {};

    const connectedTablesData = getConnectedTables({
      tableId: componentSchema?.table ?? '',
      appDatatable,
    });
    return connectedTablesData;
  }, [componentSchema?.table, appSchema?.appDatatable]);

  useUpdateEffect(() => {
    const connectedTablesData = getConnectedTables({
      tableId: componentSchema?.table ?? '',
      appDatatable,
    });
    let relatedTableList = {};
    Object.keys(connectedTablesData ?? {}).forEach((tableID) => {
      relatedTableList = {
        ...relatedTableList,
        [tableID]: tableDataToRelatedTableData({ tableData: connectedTablesData[tableID] }),
      };
    });
    setRelatedTableList(relatedTableList);
    setConnectedTableList(Object.keys(connectedTablesData ?? {}) ?? []);
  }, [componentSchema?.table, appSchema?.appDatatable]);

  const updateRowListData = async ({ componentID, appID }) => {
    const componentRes = await fetchComponentData({ componentID, appID });
    setComponentRowList(componentRes?.rows ?? []);
  };
  useEffect(() => {
    if (!isNew) {
      updateRowListData({ componentID: componentSchema?.componentID, appID });
    }
  }, [componentSchema?.componentID, appID, isNew]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName={styles.modalFullscreen}
      size='fullscreen'
      className={`ComponentSettingWrapper ${styles.ComponentSettingWrapper}`}
    >
      <ToastContainer position={'bottom-left'} theme={'colored'} />
      <Modal.Header className={styles.header}>
        <Header
          title='Components'
          subtitle={componentSchema.name || 'Untitled Component'}
          handleClose={handleClose}
          handleSave={handleSave}
          handleDelete={handleDelete}
          isNew={isNew}
        />
      </Modal.Header>
      <Modal.Body className={styles.body}>
        <Tabs
          validateForm={validateForm}
          st
          isNew={isNew}
          isComponetDetailsChange={isComponetDetailsChange}
        >
          <div label={mls('Component Details')}>
            <ComponentDetails
              schema={componentSchema}
              setSchema={setComponentSchema}
              connectedTablesData={connectedTablesData}
              setConnectedTableList={setConnectedTableList}
              errors={errors}
              handleBlur={handleBlur}
              handleSave={handleSave}
              appDatatable={appDatatable}
              setIsComponetDetailsChange={setIsComponetDetailsChange}
              isComponetDetailsChange={isComponetDetailsChange}
              isNew={isNew}
            />
          </div>
          {/* <div label={mls('Screen Group Settings')}>
            <ScreenGroupSettings
              schema={connectedScreenGroupSchema}
              // setSchema={setConnectedScreenGroupSchema}
              setSchema={() => {}}
              componentSchemaList={componentSchemaList}
              setComponentSchemaList={setComponentSchemaList}
              appDatatable={appDatatable}
              appComponent={appComponent}
              tempData={tempData}
              setTempData={setTempData}
              selectedTable={componentSchema.table}
              componentSchema={componentSchema}
              setComponentSchema={setComponentSchema}
              updatedConnectedScreeens={updatedConnectedScreeens}
              setUpdatedConnectedScreeens={setUpdatedConnectedScreeens}
            />
          </div> */}
          <div label={mls('Component AI Customization')}>
            <ComponentAiCustomization
              schemaList={componentSchemaList}
              componentSchema={componentSchema}
              setComponentSchema={setComponentSchema}
              appDatatable={appDatatable}
              componentRowList={componentRowList}
              appID={appID}
            />
          </div>
          <div label={mls('PDF Template')}>
            <PDFTemplateWrapper
              componentSchema={componentSchema}
              setComponentSchema={setComponentSchema}
              schemaList={componentSchemaList}
              appDatatable={appDatatable}
              appID={appID}
              appComponent={tempData.appComponent}
            />
          </div>
          <div label={mls('Role Access')}>
            <RoleAccess
              componentSchema={componentSchema}
              setComponentSchema={setComponentSchema}
              schemaList={componentSchemaList}
              appDatatable={appDatatable}
              appID={appID}
              appComponent={tempData.appComponent}
              connectedTablesData={connectedTablesData}
              appSchema={appSchema}
            />
          </div>
          <div label={mls('Additional Settings')}>
            <AdditionalSettings
              componentSchema={componentSchema}
              connectedTablesData={connectedTablesData}
              setConnectedTableList={setConnectedTableList}
              setRelatedTableList={setRelatedTableList}
              appDatatable={appDatatable}
              appSchema={appSchema}
            />
          </div>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};

export default ComponentSettingWrapper;
