let mlsSchema = {
  english: {
    navigation: {
      name: 'Navigation',
      text: [],
    },
  },
};

export const convertToMLSFormat = (appSchema) => {
  if (Object?.keys(appSchema || {})?.length === 0) return null;
  !mlsSchema?.english?.navigation?.text?.includes(appSchema?.app?.name) &&
    mlsSchema?.english?.navigation?.text?.push(appSchema?.app?.name);
  appSchema?.app?.projects?.forEach((project) => {
    !mlsSchema?.english?.navigation?.text?.includes(project?.name) &&
      mlsSchema?.english?.navigation?.text?.push(project?.name);
    project?.screenGroup?.forEach((screenGrp) => {
      projectData(appSchema, screenGrp);
    });
    project?.connectedScreenGroup?.forEach((connScreenGrp) => {
      projectData(appSchema, connScreenGrp);
    });
  });
  return mlsSchema;
};

const projectData = (appSchema, screenGrp) => {
  !mlsSchema?.english?.navigation?.text?.includes(screenGrp?.name) &&
    mlsSchema?.english?.navigation?.text?.push(screenGrp?.name);
  screenGrp?.screen?.forEach((scren) => {
    !mlsSchema?.english?.navigation?.text?.includes(scren?.screenName) &&
      mlsSchema?.english?.navigation?.text?.push(scren?.screenName);
    scren?.component?.forEach((comp) => {
      mlsSchema.english[comp?.componentID] = {
        name: appSchema?.appComponent?.[comp?.componentID]?.name,
        text: [],
      };

      !mlsSchema?.english?.[comp?.componentID]?.text?.includes(
        appSchema?.appComponent?.[comp?.componentID]?.name
      ) &&
        mlsSchema?.english?.[comp?.componentID]?.text?.push(
          appSchema?.appComponent?.[comp?.componentID]?.name
        );
      !mlsSchema?.english?.[comp?.componentID]?.text?.includes(
        appSchema?.appComponent?.[comp?.componentID]?.description
      ) &&
        mlsSchema?.english?.[comp?.componentID]?.text?.push(
          appSchema?.appComponent?.[comp?.componentID]?.description
        );
      appSchema?.appDatatable?.[appSchema?.appComponent?.[comp?.componentID]?.table]?.columnSchema?.forEach(
        (colSch) => {
          !mlsSchema?.english?.[comp?.componentID]?.text?.includes(colSch?.name) &&
            mlsSchema?.english?.[comp?.componentID]?.text?.push(colSch?.name);
          if (colSch?.dataType === 'select') {
            colSch?.selectMenu?.forEach((sel) => {
              !mlsSchema?.english?.[comp?.componentID]?.text?.includes(sel?.label) &&
                mlsSchema?.english?.[comp?.componentID]?.text?.push(sel?.label);
            });
          }
        }
      );
      appSchema?.appComponent?.[comp?.componentID]?.buttons?.forEach((btn) => {
        // console?.log(mlsSchema, 'mlsSchema');
        !mlsSchema?.english?.[comp?.componentID]?.text?.includes(appSchema?.appButton?.[btn]?.name) &&
          mlsSchema?.english?.[comp?.componentID]?.text?.push(appSchema?.appButton?.[btn]?.name);
      });
    });
  });
};

// Adds data from given chartSchema to given MLS data
export const addDataFromChartSchemaToMLSData = (MLSData, analyticsSchema) => {
  const appSheets = analyticsSchema?.appSheets || {}
  MLSData.english['appSheets'] = {};
  MLSData.english['appSheets'].text = [];
  Object.keys(appSheets).forEach((sheetID) => {
    const sheetSchema = appSheets[sheetID]
    MLSData.english['appSheets'].text?.push(sheetSchema?.name);
    sheetSchema?.description && MLSData?.english?.[sheetID]?.text?.push(sheetSchema?.description);
  });

  const appDash = analyticsSchema?.appDash || {}
  MLSData.english['appDash'] = {};
  MLSData.english['appDash'].text = [];
  Object.keys(appDash).forEach((dashID) => {
    const dashSchema = appDash[dashID]
    MLSData.english['appDash'].text?.push(dashSchema?.name);
    dashSchema?.desc && MLSData?.english?.[dashID]?.text?.push(dashSchema?.desc);
  });


  const appCharts = analyticsSchema?.appCharts || {}
  MLSData.english['appCharts'] = {};
  MLSData.english['appCharts'].text = [];
  Object.keys(appCharts).forEach((chartID) => {
    const chartSchema = appCharts[chartID]
    MLSData.english['appCharts'].text?.push(chartSchema?.name);
    chartSchema?.desc && MLSData?.english?.[chartID]?.text?.push(chartSchema?.desc);
  });

  const appTeams = analyticsSchema?.appTeams || {}
  MLSData.english['appTeams'] = {};
  MLSData.english['appTeams'].text = [];
  Object.keys(appTeams).forEach((teamID) => {
    const teamSchema = appTeams[teamID]
    MLSData.english['appTeams'].text?.push(teamSchema?.name);
    teamSchema?.desc && MLSData?.english?.[teamID]?.text?.push(teamSchema?.desc);
  });

  const appCreds = analyticsSchema?.appCreds || {}
  MLSData.english['appCreds'] = {};
  MLSData.english['appCreds'].text = [];
  Object.keys(appCreds).forEach((credID) => {
    const credSchema = appCreds[credID]
    MLSData.english['appCreds'].text?.push(credSchema?.connectionName);
    credSchema?.description && MLSData?.english?.[credID]?.text?.push(credSchema?.description);
  });

  const appReports = analyticsSchema?.appReports || {}
  MLSData.english['appReports'] = {};
  MLSData.english['appReports'].text = [];
  Object.keys(appReports).forEach((reportID) => {
    const reportSchema = appReports[reportID]
    MLSData.english['appReports'].text?.push(reportSchema?.name);
    reportSchema?.description && MLSData?.english?.[reportID]?.text?.push(reportSchema?.desc);
  });


  analyticsSchema?.appProject?.projects?.forEach((project) => {
    const projectID = project?.projectID;
    MLSData.english[projectID] = { name: project?.name };
    MLSData.english[projectID].text = [];
    MLSData.english[projectID].text?.push(project?.name);
    // If project description exists then only add
    project?.description && MLSData?.english?.[projectID]?.text?.push(project?.description);
    project?.sheets?.forEach((sheet) => {
      MLSData?.english?.[projectID]?.text?.push(sheet?.name);
      // If sheet description exists then only add
      sheet?.description && MLSData?.english?.[projectID]?.text?.push(sheet?.description);
      const chartLists = sheet?.charts;
      chartLists?.forEach((chartID) => {
        const chartName = analyticsSchema?.appCharts?.[chartID]?.name;
        chartName && MLSData?.english?.[projectID]?.text?.push(chartName);
      });
    });
  });
  return MLSData;
};

// Adds data from given campaignSchema to given MLS data
export const addDataFromCampaignSchemaToMLSData = (MLSData, campaignSchema) => {
  campaignSchema?.d3_resultVal?.forEach((result) => {
    const id = result?._id;
    MLSData.english[id] = { name: result?.campaignGroupName, text: [result?.campaignGroupName] };
  });
  // console?.log(MLSData, 'Final MLSData');
  return MLSData;
};
