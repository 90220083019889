import React, { useEffect, useRef } from 'react';
import styles from './PDFTemplate.module.scss';
import Editor from '@monaco-editor/react';

const CodeContainer = ({
  sampleData,
  htmlContent,
  setSampleData,
  codeContainerTypes,
  codeContainerType,
  handelContainetChange,
  variableData,
  setVariableData,
}) => {
  const monacoRef = useRef(null);
  const handleEditorDidMount = (editor, monaco) => {
    monacoRef.current = editor;

    editor.updateOptions({ readOnly: false });
    editor.setValue(JSON.stringify(sampleData));
    editor.getAction('editor.action.formatDocument').run();

    setTimeout(() => {
      editor.updateOptions({ readOnly: true });
    }, 3000);
  };

  useEffect(() => {
    if (monacoRef.current) {
      monacoRef.current.updateOptions({ readOnly: false });
      monacoRef.current.setValue(JSON.stringify(sampleData));
      monacoRef.current.getAction('editor.action.formatDocument').run();
      setTimeout(() => {
        monacoRef.current.updateOptions({ readOnly: true });
      }, 3000);
    }
  }, [sampleData, codeContainerType]);

  return (
    <div className={styles.codeContainer}>
      <div
        style={
          codeContainerType === codeContainerTypes.data.key
            ? { height: '100%' }
            : { display: 'none', height: '100%' }
        }
      >
        <Editor
          height='100%'
          defaultLanguage='json'
          value={JSON.stringify(sampleData)}
          onChange={() => {}}
          theme='vs-dark'
          options={{
            automaticLayout: true,
            formatOnPaste: true,
            formatOnType: true,
            // readOnly: true,
          }}
          onMount={handleEditorDidMount}
        />
      </div>
      <div
        style={
          codeContainerType === codeContainerTypes.code.key
            ? { height: '100%' }
            : { display: 'none', height: '100%' }
        }
      >
        <Editor
          height='100%'
          defaultLanguage='handlebars'
          value={htmlContent}
          onChange={(value) => handelContainetChange(value ?? '')}
          theme='vs-dark'
        />
      </div>
      <div
        style={
          codeContainerType === codeContainerTypes.variables.key
            ? { height: '100%' }
            : { display: 'none', height: '100%' }
        }
      >
        <Editor
          height='100%'
          defaultLanguage='json'
          value={variableData}
          onChange={(value) => setVariableData(value ?? '')}
          theme='vs-dark'
        />
      </div>
    </div>
  );
};

export default CodeContainer;
