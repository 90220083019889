import React, { memo, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import getAppID from '../../../utils/getAppID';
import { supistaApiGet, supistaApiSchema, supistaApiPost } from '../../connections/connections';
// import executeAction from '../../../redux/actions';
// import store from '../../../redux/reduxStore';
import { areEqual } from '../sharable/commons';
// import constants from '../sharable/commons';
import { convertToMLSFormat } from './convertToMLSFormat';
import {
  addDataFromChartSchemaToMLSData,
  addDataFromCampaignSchemaToMLSData,
} from './convertToMLSFormat';
import sampleChartSchema from './sampleChartSchema.json';
import MLSUI from './MLSUI';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';

function MLS() {
  const history = useHistory();
  const appID = getAppID(history.location.pathname);
  const appSchema = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.APP_SCHEMA);
  // var MLSData = convertToMLSFormat(appSchema);
  const [MLSData, setMLSData] = useState(convertToMLSFormat(appSchema));
  const loggedInUser = useSelector((store) => store.userSchema);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getCharts = () => {
      supistaApiSchema(`${appID}/allSchema`, {
        appID: appID,
        response: { ...loggedInUser },
      })
        .then((response) => {
          // MLSData = addDataFromChartSchemaToMLSData(MLSData, response.response);
          var newMLSData = addDataFromChartSchemaToMLSData(MLSData, response.response);
          setMLSData(newMLSData);
        })
        .then(() => {
          // Fetching campaign schema
          supistaApiPost(`plugins/${appID}/campaigns/getAllCampaignGroupSchema`, { data: {} }).then(
            (response) => {
              var newMLSData = addDataFromCampaignSchemaToMLSData(
                MLSData,
                response.response.campaignSchema
              );
              setMLSData(newMLSData);
              setIsLoading(false);
              // console.log(response, 'campaign');
            }
          );
        });
    };
    getCharts();
  }, []);

  const [defaultLanguage, setDefaultLanguage] = useState(
    appSchema?.app?.defaultLanguage || 'English'
  );
  const [activeLanguages, setActiveLanguages] = useState(appSchema?.app?.activeLanguages || []);
  const [languageDict, setLanguageDict] = useState({});

  const updateMLS = (newMLSData) => {};

  const getMlsData = () => {
    const allResponses = (activeLanguages || []).map((lang) => {
      const promise = new Promise((resolve, reject) => {
        supistaApiGet(`appMLS/${appID}/${lang}`)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => reject(err));
      });
      return promise;
    });
    Promise.all(allResponses).then((allResponse) => {
      const dict = {};
      // eslint-disable-next-line array-callback-return
      allResponse.map((res) => {
        dict[res.languageID] = res.dict || {};
      });
      // console.log(dict, 'hollaaas');
      setLanguageDict(dict);
    });
  };

  useState(() => getMlsData());
  if (!MLSData) return '';

  return (
    <>
      {isLoading ? (
        <div className='h-100 d-flex align-items-center justify-content-center'>
          <CircularProgress />
        </div>
      ) : (
        <MLSUI
          english={MLSData.english}
          languageDict={languageDict}
          defaultLanguage={defaultLanguage}
          activeLanguages={activeLanguages}
          updateMLS={updateMLS}
          setDefaultLanguage={setDefaultLanguage}
          setActiveLanguages={setActiveLanguages}
          setLanguageDict={setLanguageDict}
          appID={appID}
        />
      )}
    </>
  );
}

export default memo(MLS, areEqual);
