/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import {
  CsvSuccess,
  CreateNewTablePopup,
  UploadCsvFile,
  CsvFailed,
  CreateNewDataTableManually,
  CreateNewDataTableSettings,
} from './pages';
import Box from '@mui/material/Box';
import { KTSVG } from '_metronic/helpers';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: '70vw',
  height: '70vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxHeight: '80vh',
  overflow: 'auto',
  borderRadius: '.5rem',
};

// export const SELECTDATASOURCE = 'SelectDataSource'
export const CREATENEWTABLEPOPUP = 'CreateNewTablePopup';
export const UPLOADCSVFILE = 'UploadCsvFile';
export const CSVSUCCESS = 'CsvSuccess';
export const CSVFAILED = 'CsvFailed';
export const CSVDETAIL = 'CsvDetail';
export const SELECTCONNECTION = 'SelectConnection';

const CreateNewTableWrapper = ({
  setOpen,
  allDataTableData,
  dataIndex,
  columnUpdates,
  setColumnUpdates,
  setDataTableData,
}) => {
  //storing csv data
  const [csvData, setCsvData] = useState([]);
  const [filename, setFilename] = useState('');
  // const [tableSchema, setTableSchema] = useState({});
  const [isManual, setIsManual] = useState(false);
  const [stackOfModalPages, setStackOfModalPages] = useState([CREATENEWTABLEPOPUP]);
  console.log(stackOfModalPages);

  // Controls which page to set as default after modal is closed
  const triggerClose = () => {
    setStackOfModalPages([CREATENEWTABLEPOPUP]);
  };

  // console.log(csvData, 'csvdata');

  const back = () => {
    setStackOfModalPages((old) => {
      const newArray = [...old];
      newArray.pop();
      return newArray;
    });
  };

  const setNewActivePage = (targetPage) => {
    setStackOfModalPages((old) => {
      const newArray = [...old];
      newArray.push(targetPage);
      return newArray;
    });
  };

  // useEffect(() => {
  //   tableSchema && dataTableSchema && setDataTableData([...dataTableSchema, tableSchema]);
  // }, [tableSchema]);

  return (
    <Modal open={true} onClose={() => setOpen(false)}>
      <Box sx={style}>
        <div
          onClick={(e) => e.stopPropagation()}
          // className='modal-dialog modal-dialog-centered mw-900px'
        >
          {/* Modal close button */}
          <button
            type='button'
            className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
            // id='kt_explore_close'
            onClick={() => {
              setOpen(false);
              triggerClose();
            }}
            style={{ position: 'absolute', top: 20, right: 30 }}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
          </button>

          {/* Back button */}
          {stackOfModalPages.length > 1 && (
            <button
              type='button'
              className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
              // id='kt_explore_close'
              onClick={back}
              style={{ position: 'absolute', top: 20, left: 20 }}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr063.svg' className='svg-icon-2' />
            </button>
          )}

          {/* {stackOfModalPages[stackOfModalPages.length - 1] === SELECTDATASOURCE && (
            <SelectDataSource
              setFilename={setFilename}
              setIsManual={setIsManual}
              setActiveModalPage={setNewActivePage}
            />
          )} */}

          {stackOfModalPages[stackOfModalPages.length - 1] === CREATENEWTABLEPOPUP && (
            <CreateNewTablePopup
              setFilename={setFilename}
              setIsManual={setIsManual}
              setActiveModalPage={setNewActivePage}
            />
          )}
          {/* {stackOfModalPages[stackOfModalPages.length - 1] === SELECTCONNECTION && (
            <SelectExternalConnection
              setFilename={setFilename}
              setIsManual={setIsManual}
              setActiveModalPage={setNewActivePage}
            />
          )} */}

          {/* This is the main setting of the data table */}
          {stackOfModalPages[stackOfModalPages.length - 1] === CSVDETAIL && (
            <CreateNewDataTableSettings
              allDataTableData={allDataTableData}
              setDataTableData={setDataTableData}
              dataIndex={dataIndex}
              columnUpdates={columnUpdates}
              setColumnUpdates={setColumnUpdates}
              // setFilename={setFilename}
              isManual={isManual}
              // setTableSchema={setTableSchema}
              filename={filename}
              csvData={csvData}
              setCsvData={setCsvData}
              setActiveModalPage={setNewActivePage}
              // setDataTableData={setDataTableData}
              // dataTableSchema={dataTableSchema}
              // Also setting the modal page to first page of the modal
              setOpen={(arg) => {
                setOpen(arg);
                setNewActivePage(CREATENEWTABLEPOPUP);
              }}
            />
          )}
          {stackOfModalPages[stackOfModalPages.length - 1] === UPLOADCSVFILE && (
            <UploadCsvFile
              setIsManual={setIsManual}
              setFilename={setFilename}
              setCsvData={setCsvData}
              setActiveModalPage={setNewActivePage}
            />
          )}
          {stackOfModalPages[stackOfModalPages.length - 1] === CSVSUCCESS && (
            <CsvSuccess setActiveModalPage={setNewActivePage} />
          )}
          {stackOfModalPages[stackOfModalPages.length - 1] === CSVFAILED && (
            <CsvFailed setActiveModalPage={setNewActivePage} />
          )}
        </div>
      </Box>
    </Modal>
  );
};

export { CreateNewTableWrapper as CreateNewTable };
