import React, { useState, useMemo } from 'react';
import AICodeForm from './AICodeForm';
import AIGeneratedCodeEditor from './AIGeneratedCodeEditor';
import styles from '../ComponentSettingWrapper.module.scss';
import { getConnectedTables } from './PDFTemplates/helperFuntion';
import { _supistaApiPost } from 'lib/server-connection/connections';

import { v4 as uuid } from 'uuid';
import {
  buttonTypeObject,
  crudTypeButtonObject,
  curdOpType,
  defaultCode,
} from '../data/appComponentData';

const getDefaultGeneratedCode = ({ componentSchema }) => {
  const crudOpData = componentSchema?.crudOp ?? {};
  const finalCode = {
    POST:
      crudOpData?.POST?.type === curdOpType.DEFAULT.key
        ? { functionCode: defaultCode }
        : crudOpData?.POST,
    GET:
      crudOpData?.GET?.type === curdOpType.DEFAULT.key
        ? { functionCode: defaultCode }
        : crudOpData?.GET,
    DELETE:
      crudOpData?.DELETE?.type === curdOpType.DEFAULT.key
        ? { functionCode: defaultCode }
        : crudOpData?.DELETE,
    PUT:
      crudOpData?.PUT?.type === curdOpType.DEFAULT.key
        ? { functionCode: defaultCode }
        : crudOpData?.PUT,
    [crudTypeButtonObject.FORMVALIDATION.key]:
      crudOpData?.[crudTypeButtonObject.FORMVALIDATION.key]?.type === curdOpType.DEFAULT.key
        ? { functionCode: defaultCode }
        : crudOpData?.[crudTypeButtonObject.FORMVALIDATION.key],
    actionButton: componentSchema?.actionButton ?? {},
  };
  return finalCode;
};
const getDefaultCode = ({ componentSchema, actionButtonKey, buttonType }) => {
  if (buttonType === buttonTypeObject.CRUD.key) {
    const crudOpData = componentSchema?.crudOp ?? {};
    if (crudOpData?.[actionButtonKey]?.type === curdOpType.CUSTOM.key) {
      return crudOpData?.[actionButtonKey];
    }
    const functionID = uuid();

    const updatedCrudFunction = {
      fuctionName: 'displayFunc',
      functionID: functionID,
      functionCode: defaultCode,
      params: [],
      type: curdOpType.CUSTOM.key,
    };
    return updatedCrudFunction;
  } else if (buttonType === buttonTypeObject.actionButton.key) {
    const actionButtonData = componentSchema?.actionButton ?? {};
    if (actionButtonData?.[actionButtonKey]) {
      return actionButtonData?.[actionButtonKey];
    }
    const functionID = uuid();
    const updatedCrudFunction = {
      fuctionName: 'actionFunc',
      functionID: functionID,
      functionCode: defaultCode,
      displayWhen: {},
      displayTo: [],
      title: 'New Action',
    };

    return updatedCrudFunction;
  }
};

const isCrudCustomizedFunction = ({ componentSchema }) => {
  const crudOpData = componentSchema?.crudOp ?? {};
  let isCrudCustomized = false;
  Object.keys(crudOpData).forEach((crudOpkey) => {
    const crudOpElem = crudOpData[crudOpkey];
    if (crudOpElem?.type === curdOpType.CUSTOM.key) {
      isCrudCustomized = true;
    }
  });
  if (Object.keys(componentSchema?.actionButton ?? {})[0]) {
    isCrudCustomized = true;
  }
  return isCrudCustomized;
};
const ComponentAiCustomization = ({
  schema,
  componentRowList,
  componentSchema,
  appID,
  appDatatable,
  setComponentSchema,
}) => {
  const [generatedCode, setGeneratedCode] = useState(getDefaultGeneratedCode({ componentSchema }));
  const [isSchemaInitialized, setIsSchemaInitialized] = useState(
    isCrudCustomizedFunction({ componentSchema })
  );
  const connectedTableData = useMemo(() => {
    let tableName = [];
    let columnSchema = [];
    const connectedTablesData = getConnectedTables({
      tableId: componentSchema?.table ?? '',
      appDatatable,
    });
    Object.keys(connectedTablesData).forEach((connectedTablesKey) => {
      tableName = [...tableName, connectedTablesKey];
      const connectedTablesElem = connectedTablesData[connectedTablesKey];
      const newColumSchema = (connectedTablesElem?.columnSchema ?? []).map((columnElem) => {
        return { ...columnElem, tableID: connectedTablesKey };
      });
      columnSchema = [...columnSchema, ...newColumSchema];
    });
    return {
      tableName: tableName,
      columnSchema: columnSchema,
    };
  }, [componentSchema?.table, appDatatable]);

  const [isAiQueryLoading, setIsAiQueryLoading] = useState(false);

  const handleGenerateCode = async (requests) => {
    // Initialize default schema
    const baseUrl = `aiQuery/${appID}`;
    const data = {
      queryArray: [
        {
          promptType: 'CRUD',
          query: requests?.create ?? '',
          apiType: 'POST',
        },
        {
          promptType: 'CRUD',
          query: requests?.read ?? '',
          apiType: 'GET',
        },
        {
          promptType: 'CRUD',
          query: requests?.delete ?? '',
          apiType: 'DELETE',
        },
        {
          promptType: 'CRUD',
          query: requests?.update ?? '',
          apiType: 'PUT',
        },
        {
          promptType: 'CRUD',
          query: requests?.formValidation ?? '',
          apiType: 'PUT',
        },
        {
          promptType: 'CRUD',
          query: requests?.actionButtons ?? '',
          apiType: 'PUT',
        },
      ],
      data: {
        tableName: connectedTableData?.tableName,
        columnSchema: connectedTableData?.columnSchema,
      },
    };

    setIsAiQueryLoading(true);
    const componentRes = await _supistaApiPost(baseUrl, { data });
    if (componentRes) {
      const queryData = {
        create: componentRes[0] ?? '',
        read: componentRes[1] ?? '',
        delete: componentRes[2] ?? '',
        update: componentRes[3] ?? '',
        formValidation: componentRes[4] ?? '',
        actionButtons: componentRes[5] ?? '',
      };
      const POSTData = getDefaultCode({
        componentSchema,
        actionButtonKey: crudTypeButtonObject.POST.key,
        buttonType: buttonTypeObject.CRUD.key,
      });
      const GETData = getDefaultCode({
        componentSchema,
        actionButtonKey: crudTypeButtonObject.GET.key,
        buttonType: buttonTypeObject.CRUD.key,
      });
      const DELETEData = getDefaultCode({
        componentSchema,
        actionButtonKey: crudTypeButtonObject.DELETE.key,
        buttonType: buttonTypeObject.CRUD.key,
      });
      const PUTData = getDefaultCode({
        componentSchema,
        actionButtonKey: crudTypeButtonObject.PUT.key,
        buttonType: buttonTypeObject.CRUD.key,
      });
      const FORMVALIDATIONData = getDefaultCode({
        componentSchema,
        actionButtonKey: crudTypeButtonObject.FORMVALIDATION.key,
        buttonType: buttonTypeObject.CRUD.key,
      });
      const actionButtonID = uuid();

      const actionButtonData = getDefaultCode({
        componentSchema,
        actionButtonKey: actionButtonID,
        buttonType: buttonTypeObject.actionButton.key,
      });
      const finalGenratedCode = {
        POST: { ...POSTData, functionCode: queryData?.create?.response ?? '' },
        GET: { ...GETData, functionCode: queryData?.read?.response ?? '' },
        DELETE: { ...DELETEData, functionCode: queryData?.delete?.response ?? '' },
        PUT: { ...PUTData, functionCode: queryData?.update?.response ?? '' },
        [crudTypeButtonObject.FORMVALIDATION.key]: {
          ...FORMVALIDATIONData,
          functionCode: queryData?.formValidation?.response ?? '',
        },
      };
      const actionButton = {
        ...actionButtonData,
        functionCode: queryData?.actionButtons?.response ?? '',
      };
      const updatedComonentSchema = {
        ...(componentSchema ?? {}),
        crudOp: { ...(componentSchema?.crudOp ?? {}), ...finalGenratedCode },
        actionButton: {
          ...(componentSchema?.actionButton ?? {}),
          [actionButtonID]: { ...actionButton },
        },
      };
      setComponentSchema(updatedComonentSchema);
      setIsAiQueryLoading(false);
      setIsSchemaInitialized(true);
    } else {
      setIsSchemaInitialized(true);
      setIsAiQueryLoading(false);
    }
  };
  const handleAddCodeManually = () => {
    setIsSchemaInitialized(true);
  };
  const handleEditCode = (name, value) => {
    setGeneratedCode((prevCode) => ({
      ...prevCode,
      [name]: { ...prevCode[name], functionCode: value },
    }));
  };
  const handleActionButtonsEdit = (actionButtons) => {
    if (schema) {
      schema.actionButton = actionButtons;
      setGeneratedCode((prevCode) => ({
        ...prevCode,
        actionButton: schema.actionButton,
      }));
    }
  };

  return (
    <div className={styles.aiCustomization}>
      {!isSchemaInitialized ? (
        <AICodeForm
          onGenerateCode={handleGenerateCode}
          appID={appID}
          isAiQueryLoading={isAiQueryLoading}
          handleAddCodeManually={handleAddCodeManually}
          componentSchema={componentSchema}
          setComponentSchema={setComponentSchema}
          componentRowList={componentRowList}
        />
      ) : (
        <AIGeneratedCodeEditor
          schema={{ crudOp: generatedCode }}
          onEdit={handleEditCode}
          onActionButtonsEdit={handleActionButtonsEdit}
          componentSchema={componentSchema}
          setComponentSchema={setComponentSchema}
          appDatatable={appDatatable}
          componentRowList={componentRowList}
          connectedTableData={connectedTableData}
          appID={appID}
        />
      )}
    </div>
  );
};

export default ComponentAiCustomization;
