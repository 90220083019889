import React from 'react';
import { mls } from 'lib/multilanguagesupport';
import style from './roleAccess.module.scss';
import CheckBox from 'lib/reusable-components/Components/CheckBox/CheckBox';
import { FiFilter } from 'react-icons/fi';
import { defaultTeamAccessObject, filterTypeObject } from '../../data/appComponentData';
interface roleAccessTeamCrudContainerProps {
  appTeams: any;
  roleAccessSchema: any;
  filterData: any;
  setRoleAccessSchema: Function;
  setFilterData: Function;
}
const getIsAllTrue = ({ key, roleAccessSchema }: { key: string; roleAccessSchema: any }) => {
  const isFound = Object.keys(roleAccessSchema).some((roleAccessElemKey: string) => {
    const roleAccessElem = roleAccessSchema[roleAccessElemKey] ?? {};
    return roleAccessElem[key] ? false : true;
  });
  return !isFound;
};
const RoleAccessTeamCrudContainer = ({
  appTeams,
  roleAccessSchema,
  setRoleAccessSchema,
  setFilterData,
  filterData,
}: roleAccessTeamCrudContainerProps) => {
  const updateTeamAccess = ({
    teamKey,
    teamAccessData,
  }: {
    teamKey: string;
    teamAccessData: any;
  }) => {
    setRoleAccessSchema({ ...roleAccessSchema, [teamKey]: teamAccessData });
  };

  const handleAllSelect = ({ key, value }: { key: string; value: boolean }) => {
    let tempRoleAcess: { [key: string]: any } = {};

    Object.keys(roleAccessSchema).forEach((roleAccessTeamKey) => {
      let roleAccessTeamElem = { ...roleAccessSchema[roleAccessTeamKey] };
      if (!value) {
        if (key === 'R') {
          delete roleAccessTeamElem['U'];
        }
        delete roleAccessTeamElem[key];
      } else {
        if (key === 'D') {
          roleAccessTeamElem = { ...roleAccessTeamElem, [key]: { filter: {} } };
        } else {
          roleAccessTeamElem = { ...roleAccessTeamElem, [key]: defaultTeamAccessObject };
        }

        if (key === 'R') {
          if (!roleAccessTeamElem?.U) {
            roleAccessTeamElem = { ...roleAccessTeamElem, U: defaultTeamAccessObject };
          }
        }
      }

      tempRoleAcess = { ...tempRoleAcess, [roleAccessTeamKey]: roleAccessTeamElem };
    });
    setRoleAccessSchema(tempRoleAcess);
  };
  const isRead = getIsAllTrue({ key: 'R', roleAccessSchema });

  const handleFilter = ({
    key,
    disabled,
    selectedTeamsKey,
    specialCase = false,
  }: {
    key: string;
    selectedTeamsKey: string;
    disabled?: boolean;
    specialCase?: boolean;
  }) => {
    if (disabled) {
      return;
    }
    setFilterData({
      ...filterData,
      popupStatus: true,
      filters: roleAccessSchema?.[selectedTeamsKey]?.[key]?.componentFilter ?? {},
      selectedTeamsKey: selectedTeamsKey,
      key: key,
      filterType: filterTypeObject.teamFilter.key,
      specialCase,
    });
  };
  const filterLength = ({
    key,
    disabled,
    selectedTeamsKey,
  }: {
    key: string;
    selectedTeamsKey: string;
    disabled?: boolean;
  }) => {
    if (disabled) {
      return '';
    }
    const filterLength = Object.keys(
      roleAccessSchema?.[selectedTeamsKey]?.[key]?.componentFilter ?? {}
    )?.length;

    if (filterLength > 0) {
      return (
        <div>
          (<b>{mls('Applied')}</b>: {filterLength})
        </div>
      );
    }
    return '';
  };
  return (
    <>
      <div className={style.roleAccessTeamCrudContainer}>
        <div
          className={style.roleAccessGridContainer}
          style={{
            gridTemplateColumns: `18rem repeat(${4},  auto)`,
          }}
        >
          <div
            className={`${style.gridCell} ${style.upperColumnGridCell}  ${style.sideColumnGridCell}  ${style.teamNameColumnGridCell}`}
          >
            {mls('Team Name')}
          </div>
          <div className={`${style.gridCell} ${style.upperColumnGridCell}`}>
            <CheckBox
              checked={getIsAllTrue({ key: 'C', roleAccessSchema })}
              onClick={(value: boolean) => {
                handleAllSelect({ key: 'C', value });
              }}
            />
            {mls('Create')}
          </div>
          <div className={`${style.gridCell} ${style.upperColumnGridCell}`}>
            <CheckBox
              checked={isRead}
              onClick={(value: boolean) => {
                handleAllSelect({ key: 'R', value });
              }}
            />
            {mls('Read')}/{mls('Filter')}
          </div>
          <div
            className={`${style.gridCell} ${style.upperColumnGridCell}  ${
              !isRead ? style.valueGridDisabled : ''
            }`}
          >
            <CheckBox
              checked={getIsAllTrue({ key: 'U', roleAccessSchema })}
              onClick={(value: boolean) => {
                handleAllSelect({ key: 'U', value });
              }}
              disabled={!isRead}
            />
            {mls('Update')}/{mls('Filter')}
          </div>
          <div className={`${style.gridCell} ${style.upperColumnGridCell}`}>
            <CheckBox
              checked={getIsAllTrue({ key: 'D', roleAccessSchema })}
              onClick={(value: boolean) => {
                handleAllSelect({ key: 'D', value });
              }}
            />
            {mls('Delete')}/{mls('Filter')}
          </div>
          {React.Children.toArray(
            Object.keys(roleAccessSchema ?? []).map((selectedTeamsKey) => {
              const roleAccessElem = roleAccessSchema[selectedTeamsKey];
              const selectedTeamElem = appTeams[selectedTeamsKey];
              const isCreate = roleAccessElem?.C ? true : false;
              const isRead = roleAccessElem?.R ? true : false;
              const isUpdate = roleAccessElem?.U ? true : false;
              const isDelete = roleAccessElem?.D ? true : false;
              const handleSelection = ({ key, value }: { key: string; value: boolean }) => {
                let finalTeamAccess: { [key: string]: any } = { ...roleAccessElem };
                if (value) {
                  if (key === 'D') {
                    finalTeamAccess = { ...finalTeamAccess, [key]: { filter: {} } };
                  } else {
                    finalTeamAccess = { ...finalTeamAccess, [key]: defaultTeamAccessObject };
                  }

                  if (key === 'R') {
                    if (!finalTeamAccess?.U) {
                      finalTeamAccess = { ...finalTeamAccess, U: defaultTeamAccessObject };
                    }
                  }
                } else {
                  finalTeamAccess = { ...roleAccessElem };
                  if (key === 'R') {
                    delete finalTeamAccess['U'];
                  }
                  delete finalTeamAccess[key];
                }
                updateTeamAccess({ teamKey: selectedTeamsKey, teamAccessData: finalTeamAccess });
              };

              return (
                <>
                  <div className={`${style.gridCell} ${style.sideColumnGridCell}`}>
                    {mls(selectedTeamElem?.name)}
                  </div>
                  <div
                    className={`${style.gridCell} ${style.cellInnerDiv} ${style.valueColumnGridCell}`}
                  >
                    <CheckBox
                      checked={isCreate}
                      onClick={(value: boolean) => {
                        handleSelection({ key: 'C', value });
                      }}
                    />
                  </div>
                  <div
                    className={`${style.gridCell}  ${style.cellInnerDiv} 
                   ${style.valueColumnGridCell} ${!isRead ? style.valueGridDisabled : ''}`}
                  >
                    <CheckBox
                      checked={isRead}
                      onClick={(value: boolean) => {
                        handleSelection({ key: 'R', value });
                      }}
                    />
                    <FiFilter
                      className={`${style.valueFilterIcon} `}
                      onClick={() =>
                        handleFilter({
                          key: 'R',
                          disabled: !isRead,
                          selectedTeamsKey,
                          specialCase: true,
                        })
                      }
                    />

                    {filterLength({ key: 'R', disabled: !isRead, selectedTeamsKey })}
                  </div>
                  <div
                    className={`${style.gridCell}  ${style.cellInnerDiv} ${
                      style.valueColumnGridCell
                    } ${!isRead || !isUpdate ? style.valueGridDisabled : ''}`}
                  >
                    <CheckBox
                      checked={isUpdate}
                      onClick={(value: boolean) => {
                        handleSelection({ key: 'U', value });
                      }}
                      disabled={!isRead}
                    />
                    <FiFilter
                      className={`${style.valueFilterIcon} ${
                        !isRead || !isUpdate ? style.valueGridDisabled : ''
                      }`}
                      onClick={() =>
                        handleFilter({
                          key: 'U',
                          disabled: !isRead || !isUpdate,
                          selectedTeamsKey,
                          specialCase: true,
                        })
                      }
                    />
                    {filterLength({ key: 'U', disabled: !isRead || !isUpdate, selectedTeamsKey })}
                  </div>
                  <div
                    className={`${style.gridCell}  ${style.cellInnerDiv} ${
                      style.valueColumnGridCell
                    } ${!isRead ? style.valueGridDisabled : ''}`}
                  >
                    <CheckBox
                      checked={isDelete}
                      onClick={(value: boolean) => {
                        handleSelection({ key: 'D', value });
                      }}
                    />
                    <FiFilter
                      className={`${style.valueFilterIcon} `}
                      onClick={() =>
                        handleFilter({
                          key: 'D',
                          disabled: !isDelete,
                          selectedTeamsKey,
                          specialCase: true,
                        })
                      }
                    />
                    {filterLength({ key: 'D', disabled: !isDelete, selectedTeamsKey })}
                  </div>
                </>
              );
            })
          )}
        </div>
      </div>
    </>
  );
};

export default RoleAccessTeamCrudContainer;
