export const defaultHtmlContent = `
<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta http-equiv="X-UA-Compatible" content="ie=edge">
  <title>PDF Template</title>
  <style type="text/css">
    body {
      font-family: 'Roboto Condensed', sans-serif;
      margin:0;
    }

    .border {
      background-color: white;
      width:  calc( 19cm - 10mm );
      margin-left: 1cm;
      margin-top: 1cm;
      margin-bottom: 1cm;
      border-color: teal;
      border-width: 1px;
      border-style: solid;
    }

    .parent-border {
      background-color: white;
      width: calc( 21cm - 10mm );
      height: auto;
      border-color: teal;
      border-width: 1px;
      border-style: solid;
    }

    .table-row-border>th {
      border-left-color: white;
      border-right-color: white;
      border-bottom-color: teal;
      border-top-color: teal;
      border-width: 2px;
      border-style: solid;
      margin: 0;
      padding: 0;
      width: 4cm;
      height: 1cm;
      text-align: center;
      color: teal;
    }

    td {
      height: 1cm;
      text-align: center;
    }

    .table-addresses>th {
      color: teal;
      text-align: center;
      width: 4cm;
      text-align: left;
    }

    table.invoice-table-address {
      margin-left: 1cm;
    }

    table.invoice-table-address td {
      font-size: 15px;
      text-align: left;
      height: 0.5cm;
    }

    .parent {
      position: relative;
    }

    .child {
      position: absolute;
    }

    .invoice-table {
      margin-left: 1cm;
      margin-right: 1cm;
      /* border: teal 1px solid; */
    }

    .parent-invoice-logo-type {
      height: 3cm;
      /* border: teal 1px solid; */
    }

    .parent-invoice-table-address {
      margin-top: 1cm;
      height: 4cm;
      /* border: teal 1px solid; */
    }

    .parent-invoice-table {
      margin-top: 1cm;
      /* border: teal 1px solid; */
    }

    .parent-invoice-total {
      margin-top: 1cm;
      /* border: teal 1px solid; */
      height: 1cm;
    }

    .parent-invoice-terms {
      margin-top: 4cm;
      /* border: teal 1px solid; */
      height: 5cm;
    }

    .invoice-type {
      font-size: 50px;
      font-weight: 700;
      color: teal;
      left: 1cm;
      bottom: 0cm;
    }

    .invoice-logo {
      right: 1cm;
      bottom: 0cm;
    }

    .invoice-total-text {
      font-size: 30px;
      font-weight: 700;
      color: teal;
      left: 1cm;
      bottom: 0cm;
    }

    .invoice-total {
      right: 1cm;
      bottom: 0cm;
      font-size: 30px;
      font-weight: 700;
    }

    .invoice-terms {
      left: 1cm;
      bottom: 0cm;
    }
  </style>
</head>

<body>
  <div class="parent-border">
    <div class="border">
      <div class="parent parent-invoice-logo-type">
        <span class="invoice-type child">
                    INVOICE 
                </span>
        {{!-- <img class="invoice-logo child" src="https://www.supista.com/assets/img/logo.png" alt="logo" width="100" height="100"> --}}
        <img class="invoice-logo child" src="https://d1csarkz8obe9u.cloudfront.net/posterpreviews/free-logo-space-template-design-39cac50098804820776e4efc76d5eb85_screen.jpg?ts=1586864091" alt="logo" width="100" height="100">
      </div>
      <br/>
      <br/>
        <h4>&nbsp;  &nbsp; &nbsp; &nbsp; Edit Html to Create PDF Template</h4>
      <br/>
      <br/>
  </div>
</body>

</html>
`;
export const defaultHtmlContent2 = `
<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta http-equiv="X-UA-Compatible" content="ie=edge">
  <title>PDF Template</title>
  <style type="text/css">
    body {
      font-family: 'Roboto Condensed', sans-serif;
      margin:0;
    }

    .border {
      background-color: white;
      width:  calc( 19cm - 10mm );
      margin-left: 1cm;
      margin-top: 1cm;
      margin-bottom: 1cm;
      border-color: teal;
      border-width: 1px;
      border-style: solid;
    }

    .parent-border {
      background-color: white;
      width: calc( 21cm - 10mm );
      height: auto;
      border-color: teal;
      border-width: 1px;
      border-style: solid;
    }

    .table-row-border>th {
      border-left-color: white;
      border-right-color: white;
      border-bottom-color: teal;
      border-top-color: teal;
      border-width: 2px;
      border-style: solid;
      margin: 0;
      padding: 0;
      width: 4cm;
      height: 1cm;
      text-align: center;
      color: teal;
    }

    td {
      height: 1cm;
      text-align: center;
    }

    .table-addresses>th {
      color: teal;
      text-align: center;
      width: 4cm;
      text-align: left;
    }

    table.invoice-table-address {
      margin-left: 1cm;
    }

    table.invoice-table-address td {
      font-size: 15px;
      text-align: left;
      height: 0.5cm;
    }

    .parent {
      position: relative;
    }

    .child {
      position: absolute;
    }

    .invoice-table {
      margin-left: 1cm;
      margin-right: 1cm;
      /* border: teal 1px solid; */
    }

    .parent-invoice-logo-type {
      height: 3cm;
      /* border: teal 1px solid; */
    }

    .parent-invoice-table-address {
      margin-top: 1cm;
      height: 4cm;
      /* border: teal 1px solid; */
    }

    .parent-invoice-table {
      margin-top: 1cm;
      /* border: teal 1px solid; */
    }

    .parent-invoice-total {
      margin-top: 1cm;
      /* border: teal 1px solid; */
      height: 1cm;
    }

    .parent-invoice-terms {
      margin-top: 4cm;
      /* border: teal 1px solid; */
      height: 5cm;
    }

    .invoice-type {
      font-size: 50px;
      font-weight: 700;
      color: teal;
      left: 1cm;
      bottom: 0cm;
    }

    .invoice-logo {
      right: 1cm;
      bottom: 0cm;
    }

    .invoice-total-text {
      font-size: 30px;
      font-weight: 700;
      color: teal;
      left: 1cm;
      bottom: 0cm;
    }

    .invoice-total {
      right: 1cm;
      bottom: 0cm;
      font-size: 30px;
      font-weight: 700;
    }

    .invoice-terms {
      left: 1cm;
      bottom: 0cm;
    }
  </style>
</head>

<body>
  <div class="parent-border">
    <div class="border">
      <div class="parent parent-invoice-logo-type">
        <span class="invoice-type child">
                    INVOICE 
                </span>
        <img class="invoice-logo child" src="https://www.supista.com/assets/img/logo.png" alt="" width="100" height="100">
      </div>
<br/>

            &nbsp; &nbsp; &nbsp; &nbsp; <b>Name:</b> {{firstname}} {{lastname}} <br/>
            &nbsp; &nbsp; &nbsp; &nbsp; <b>Email:</b> {{contact.email}}<br/>
            &nbsp; &nbsp; &nbsp; &nbsp; <b>Number:</b> {{contact.number}}<br/>
            &nbsp; &nbsp; &nbsp; &nbsp; <b>ContactInner Email:</b> {{contact.contactInner.email}}<br/>
            &nbsp; &nbsp; &nbsp; &nbsp; <b>ContactInner Number:</b> {{contact.contactInner.number}}<br/>
            &nbsp; &nbsp; &nbsp; &nbsp; <b>Friends:</b> {{friends}}<br/>
   {{!-- &nbsp; &nbsp; &nbsp; &nbsp; <b>Friends 0:</b> {{friends[0]}}<br/>
            &nbsp; &nbsp; &nbsp; &nbsp; <b>Friends2 1:</b> {{friends2[1].name}}<br/> --}}
            
      <div class="parent parent-invoice-table-address">
        <table class="child invoice-table-address" style="border-spacing: 0;">
          <tr class="table-addresses">
            <th>FROM</th>
            <th>BILL TO</th>
            <th>SHIP TO</th>
            <th>INVOICE</th>
          </tr>
          <tr class="temp">
            <td>WORLDTHRILL INFORMATIONS PRIVATE LIMITED</td>
            <td>JARSH INNOVATIONS PRIVATE LIMITED</td>
            <td>JARSH COMPLEX</td>
            <td>INVOICE</td>
          </tr>
          <tr>
            <td>H.No: 18-A-37WP</td>
            <td>H.No: 13-26/4</td>
            <td>H.No: 13-26/4</td>
            <td>Invoice number</td>
          </tr>
          <tr>
            <td>Hyderabad </td>
            <td>Mumbai</td>
            <td>Mumbai</td>
            <td>Date:</td>
          </tr>
          <tr>
            <td>500054</td>
            <td>509321</td>
            <td>509876</td>
            <td>#####</td>
          </tr>
        </table>
      </div>

      <div class="parent parent-invoice-table">
        <table class="invoice-table" style="border-spacing: 0;">
          <tr class="table-row-border">
            <th>S.NO</th>
            <th>NAME</th>
            <th>QTY</th>
            <th>DISCOUNT</th>
            <th>TAX</th>
            <th>PRICE</th>
          </tr>
          <tr>
            <td>1</td>
            <td>Milk</td>
            <td>2 litres</td>
            <td>10%</td>
            <td>10%</td>
            <td>80</td>
          </tr>
          <tr>
            <td>1</td>
            <td>Milk</td>
            <td>2 litres</td>
            <td>10%</td>
            <td>10%</td>
            <td>80</td>
          </tr>
          <tr>
            <td>1</td>
            <td>Milk</td>
            <td>2 litres</td>
            <td>10%</td>
            <td>10%</td>
            <td>80</td>
          </tr>
          <tr>
            <td>1</td>
            <td>Milk</td>
            <td>2 litres</td>
            <td>10%</td>
            <td>10%</td>
            <td>80</td>
          </tr>
          <tr>
            <td>1</td>
            <td>Milk</td>
            <td>2 litres</td>
            <td>10%</td>
            <td>10%</td>
            <td>80</td>
          </tr>

          <tr>
            <td>1</td>
            <td>Milk</td>
            <td>2 litres</td>
            <td>10%</td>
            <td>10%</td>
            <td>80</td>
          </tr>


        </table>

      </div>

      <div class="parent  parent-invoice-total">
        <span class="invoice-total-text child">
                    TOTAL :
                </span>

        <span class="invoice-total child">
                    RS: 12000/-
                </span>
      </div>

      <div class="parent  parent-invoice-terms">
        <div class="child  invoice-terms">
          <h4>TERMS AND CONDITIONS</h4>
          <p>Payment is due within 15 days</p>
          <p>State bank of india</p>
          <p>Account number: XXXXXX123565</p>
          <p>IFSC: 000345432</p>
        </div>
      </div>
    </div>
  </div>
</body>

</html>
`;
