const sample = {
  name: 'Product',
  columnName: '__d3__id__product',
  dataType: 'manyToMany', // manyToOne, oneToMany, oneToOne
  // conectedTableID: 'product',
  conectedComponentID: 'product',
};
// const specialDataType = ['hasOne', 'hasMany', 'belongsToMany', 'belongsTo'];
const specialDataType = ['hasOne', 'hasMany', 'belongsToMany'];

const getInnerData = ({ tableId, appDatatable }: { tableId: string; appDatatable: any }) => {
  let finalTabeData: { [key: string]: any } = {};
  let finalConectedTableId: any[] = [];
  let belongsToTableId: any[] = [];
  const tableData = appDatatable[tableId];
  if (tableData) {
    finalTabeData = tableData;
    if (tableData?.relations) {
      Object.values(tableData?.relations ?? {}).forEach((tableElem: any) => {
        const isSpecialDataType = specialDataType.some((elem) => elem === tableElem?.type);
        if (isSpecialDataType) {
          const tableData = appDatatable?.[tableElem?.target];
          if (tableData) {
            finalConectedTableId = [
              ...finalConectedTableId,
              {
                tableId: tableElem?.target,
                relationType: tableElem?.type,
                screenName: `Screen - ${tableElem?.target}`,
              },
            ];
          }
        }
        if ('belongsTo' === tableElem?.type) {
          const belongsToTableData = appDatatable?.[tableElem?.target];
          if (belongsToTableData) {
            belongsToTableId = [
              ...belongsToTableId,
              {
                tableId: tableElem?.target,
                relationType: tableElem?.type,
                screenName: `Screen - ${tableElem?.target}`,
              },
            ];
          }
        }
      });
    }
  }

  return { finalTabeData, finalConectedTableId, belongsToTableId };
};
export const getConnectedTables = ({
  tableId,
  appDatatable,
}: {
  tableId: string;
  appDatatable: any;
}) => {
  let connectedTableList: { [key: string]: any } = {};

  const { finalTabeData, finalConectedTableId, belongsToTableId } = getInnerData({
    tableId,
    appDatatable,
  });
  if (finalTabeData?.tableID) {
    // level 1
    connectedTableList = {
      ...connectedTableList,
      [finalTabeData?.tableID]: {
        ...finalTabeData,
        relationType: 'MAIN',
        screenName: finalTabeData?.name,
      },
    };
    (belongsToTableId ?? []).forEach(({ tableId, relationType, screenName }: any) => {
      if (!connectedTableList[tableId]) {
        connectedTableList = {
          ...connectedTableList,
          [tableId]: { ...(appDatatable[tableId] ?? {}), relationType, screenName },
        };
      }
    });
    (finalConectedTableId ?? []).forEach(({ tableId, relationType, screenName }: any) => {
      const { finalTabeData, finalConectedTableId, belongsToTableId } = getInnerData({
        tableId,
        appDatatable,
      });
      if (finalTabeData?.tableID) {
        // level 2
        if (!connectedTableList[finalTabeData?.tableID]) {
          connectedTableList = {
            ...connectedTableList,
            [finalTabeData?.tableID]: { ...finalTabeData, relationType, screenName },
          };
        }
        (belongsToTableId ?? []).forEach(({ tableId, relationType, screenName }: any) => {
          if (!connectedTableList[tableId]) {
            connectedTableList = {
              ...connectedTableList,
              [tableId]: { ...(appDatatable[tableId] ?? {}), relationType, screenName },
            };
          }
        });
        (finalConectedTableId ?? []).forEach(({ tableId, relationType, screenName }: any) => {
          const { finalTabeData, finalConectedTableId, belongsToTableId } = getInnerData({
            tableId,
            appDatatable,
          });

          if (finalTabeData?.tableID) {
            // level 3
            if (!connectedTableList[finalTabeData?.tableID]) {
              connectedTableList = {
                ...connectedTableList,
                [finalTabeData?.tableID]: { ...finalTabeData, relationType, screenName },
              };
            }
          }
          (belongsToTableId ?? []).forEach(({ tableId, relationType, screenName }: any) => {
            if (!connectedTableList[tableId]) {
              connectedTableList = {
                ...connectedTableList,
                [tableId]: { ...(appDatatable[tableId] ?? {}), relationType, screenName },
              };
            }
          });
          (finalConectedTableId ?? []).forEach(({ tableId, relationType, screenName }: any) => {
            const { finalTabeData, belongsToTableId } = getInnerData({
              tableId,
              appDatatable,
            });
            if (finalTabeData?.tableID) {
              // level 4
              if (!connectedTableList[finalTabeData?.tableID]) {
                connectedTableList = {
                  ...connectedTableList,
                  [finalTabeData?.tableID]: { ...finalTabeData, relationType, screenName },
                };
              }
              (belongsToTableId ?? []).forEach(({ tableId, relationType, screenName }: any) => {
                if (!connectedTableList[tableId]) {
                  connectedTableList = {
                    ...connectedTableList,
                    [tableId]: { ...(appDatatable[tableId] ?? {}), relationType, screenName },
                  };
                }
              });
            }
          });
        });
      }
    });
  }

  return connectedTableList;
};

export const tableDataToRelatedTableData = ({ tableData }: { tableData: any }) => {
  let defaultView: any[] = [];

  (tableData?.columnSchema ?? []).forEach((elem: any) => {
    const isStartWithD3 = (elem?.columnName ?? '').startsWith('__d3__');
    if (!isStartWithD3) {
      defaultView = [
        ...defaultView,
        {
          columnName: elem?.columnName ?? '',
          filters: {},
        },
      ];
    }
  });
  if (tableData?.relationType === 'belongsTo') {
    const id = {
      columnName: 'id',
      filters: {},
    };
    return {
      defaultView: [id],
      relationType: tableData?.relationType,
    };
  } else if (tableData?.relationType === 'MAIN') {
    return {
      defaultView: defaultView ?? [],
      relationType: tableData?.relationType,
    };
  }
  return {
    defaultView: defaultView ?? [],
    relationType: tableData?.relationType,
    screenName: tableData?.screenName,
  };
};
export const getAppComponentColumnSchema = ({
  connectedTableList,
  appDatatable,
}: {
  connectedTableList: string[];
  appDatatable: any;
}) => {
  let allColumnSchema: any[] = [];
  (connectedTableList ?? []).forEach((connectedTablekey) => {
    const connectedTableElem = appDatatable?.[connectedTablekey] ?? {};
    const newColumnSchema = (connectedTableElem?.columnSchema ?? [])?.map(
      (columnSchemaElem: any) => ({
        ...columnSchemaElem,
        tableName: connectedTablekey,
      })
    );
    allColumnSchema = [...allColumnSchema, ...newColumnSchema];
  });
  return allColumnSchema;
};
