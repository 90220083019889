import React, { useState } from 'react';
import { mls } from 'lib/multilanguagesupport';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { INITIAL_EVENTS, createEventId } from './event-utils.js';
import listPlugin from '@fullcalendar/list';
import PopUpDivChild from 'lib/reusable-components/PopUpDivChild/PopUpDivChild';
import styles from './myCalendar.module.scss';
import multiMonthPlugin from '@fullcalendar/multimonth';
import { RiRefreshLine } from 'react-icons/ri';
const FullCalendarComp = ({
  calendarData,
  refetch,
  isLoading,
}: {
  calendarData: any;
  refetch: Function;
  isLoading: boolean;
}) => {
  const [currentEvents, setCurrentEvents] = useState([]);

  const defaultNewEvent = { selectInfo: {}, title: '' };
  const [isCreateNewEvent, setIsCreateNewEvent] = useState(false);
  const [newEventData, setNewEventData] = useState<{
    selectInfo: { [key: string]: any };
    title: string;
  }>(defaultNewEvent);

  function handleDateSelect(selectInfo: any) {
    setIsCreateNewEvent(true);
    console.log(selectInfo);
    setNewEventData({ ...newEventData, selectInfo });
  }
  function handleEventClick(clickInfo: any) {
    if (window?.confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
      clickInfo.event.remove();
    }
  }

  const handleEvents = (events: any) => {
    setCurrentEvents(events);
  };

  const handleDone = () => {
    const selectInfo = newEventData?.selectInfo;
    const title = newEventData?.title;
    let calendarApi = selectInfo?.view?.calendar;
    if (calendarApi?.addEvent) {
      calendarApi.addEvent({
        id: createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay,
      });
    }
    setIsCreateNewEvent(false);
    setNewEventData(defaultNewEvent);
  };

  return (
    <div className={styles.calendarComponentInner}>
      <h2 className={styles.calendarComponentHeading}>
        {mls('My Calendar')}
        <div className={`${styles.refetchButton}`} onClick={() => refetch()}>
          <div className={` ${isLoading ? styles.refetchLoading : ''}`}>
            <RiRefreshLine />
          </div>
        </div>
      </h2>

      {isCreateNewEvent ? (
        <PopUpDivChild
          initialValue={isCreateNewEvent}
          setPopUp={() => setIsCreateNewEvent(false)}
          popupTitle={mls('Add Events')}
          fullScreenMode
        >
          <input
            type='text'
            className='primaryInput'
            value={newEventData.title}
            placeholder='Title'
            onChange={(e) => {
              setNewEventData({ ...newEventData, title: e.target.value });
            }}
          />
          <button onClick={() => handleDone()} type='button' className='primaryButton'>
            {mls('Done')}
          </button>
        </PopUpDivChild>
      ) : null}
      <div className={styles.mainCalendarComponentInner}>
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin, multiMonthPlugin]}
          events={calendarData}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            // right: 'timeGridDay,timeGridWeek,dayGridMonth,dayGridYear,listMonth',
            right: 'timeGridDay,timeGridWeek,dayGridMonth,multiMonthYear,listMonth',
          }}
          height={'100%'}
          eventBorderColor={'#B4C9D8'}
          eventBackgroundColor={'#f1faff'}
          eventTextColor={'#009ef7'}
          initialView='dayGridMonth'
          editable={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          weekends={false}
          initialEvents={INITIAL_EVENTS}
          select={handleDateSelect}
          // eventContent={renderEventContent} // custom render function
          eventClick={handleEventClick}
          eventsSet={handleEvents} // called after events are initialized/added/changed/removed
        />
      </div>
    </div>
  );
};

export default FullCalendarComp;
