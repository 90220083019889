import { supistaApiPost } from '../../connections/connections';

export function sendMlsData(appID, languageDict, activeLanguages) {
  (activeLanguages || []).map(async (lang) => {
    const dataToSend = {
      data: {
        dict: languageDict[lang],
        appID,
        languageID: lang,
      },
    };

    await supistaApiPost(`settings/${appID}/mls`, dataToSend)
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        // console.log(err);
      });
  });
}
