import { mls } from 'lib/multilanguagesupport';
import { Accordion, ListGroup } from 'react-bootstrap';

import * as editPageConstants from '../constants';

const ProjectNavbar = ({
  project,
  closeEdit,
  activePageInEditing,
  setActivePageInEditing,
  formik
}) => {
  return (
    <>
      <div className='col-4 h-100 px-5' style={{ overflow: 'scroll' }}>
        <div className='bg-white p-5 px-8' style={{ minHeight: '100%' }}>

          <div className='d-flex align-items-center justify-content-start my-1'>
            <button className='btn btn-sm btn-light-primary my-1' onClick={closeEdit}>
              {mls('Close')}
            </button>
          </div>

          <div className='menu-item'
            style={{ cursor: 'pointer' }}
            onClick={() => setActivePageInEditing({
              activePage: editPageConstants.PROJECT,
              screenGroupIndex: null,
              screenIndex: null
            })}
          >
            <div className='menu-content mt-3 px-0'>
              <h1 className='menu-section'>{formik.values.name || mls('<Untitled>')}</h1>
            </div>
          </div>

          <div className='pb-5 mt-5'>
            <span style={{ color: 'grey' }} className='menu-section fs-7 ls-1'>
              {mls('Screen Groups: ')}
            </span>
          </div>

          <Accordion activeKey={activePageInEditing.screenGroupIndex}>
            {formik.values?.screenGroup?.map((screenGroup, screenGroupIndex) => {
              return <Accordion.Item
                eventKey={screenGroupIndex}
                className='my-1'
                key={screenGroupIndex}
              >
                <Accordion.Header
                  className='projects-navbar-accordion-header'
                  onClick={() => {
                    setActivePageInEditing({
                      activePage: editPageConstants.SCREEN_GROUP,
                      screenGroupIndex,
                      screenIndex: null
                    })
                  }}
                >
                  {screenGroup?.name || mls('<Untitled>')}
                </Accordion.Header>
                <Accordion.Body className='p-0'>
                  {screenGroup?.screen?.map((screen, screenIndex) => {
                    return <ListGroup
                      as='ul'
                      variant='flush'
                      key={screenIndex}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setActivePageInEditing({
                          activePage: editPageConstants.SCREEN,
                          screenGroupIndex,
                          screenIndex
                        })
                      }}
                    >
                      <ListGroup.Item as='li'
                        className='ps-10 border-0'
                        // 'primary' if selected, else none
                        variant={
                          (activePageInEditing.activePage === editPageConstants.SCREEN &&
                            activePageInEditing.screenGroupIndex === screenGroupIndex &&
                            activePageInEditing.screenIndex === screenIndex)
                          &&
                          'primary'
                        }

                      // active={
                      // (activePageInEditing.activePage === editPageConstants.SCREEN &&
                      // activePageInEditing.screenGroupIndex === screenGroupIndex &&
                      // activePageInEditing.screenIndex === screenIndex)
                      // }
                      >
                        {screen?.screenName || mls('<Untitled>')}
                      </ListGroup.Item>
                    </ListGroup>
                  })}
                </Accordion.Body>
              </Accordion.Item>
            })}
          </Accordion>

          {/* <div className='pb-5 mt-5'>
            <span style={{ color: 'grey' }} className='menu-section fs-7 ls-1'>
              {mls('Connected Screen Groups: ')}
            </span>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default ProjectNavbar;
