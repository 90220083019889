import Modal from '@mui/material/Modal';

const ConfirmDeleteModal = ({ isDeleteModalOpen, setIsDeleteModalOpen, handleDelete, what }) => {

  return (
    <Modal open={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
      <div className='modal-dialog modal-dialog-centered mw-600px'>
        <div className='modal-content d-flex justify-content-center align-items-center p-5 mx-auto' style={{ maxWidth: '60vw' }}>
          <p className='p-5 mb-7 fs-4 fw-bold'>Are you sure you want to delete this {what?.toLowerCase() || what}?</p>
          <div className='d-flex'>
            <button
              data-bs-dismiss='modal'
              onClick={handleDelete}
              style={{ marginTop: -15 }}
              className='btn btn-sm mb-2 btn-danger btn-light-primary mx-4'
            >
              Delete {what}
            </button>
            <button
              onClick={() => setIsDeleteModalOpen(false)}
              style={{ marginTop: -15 }}
              className='btn btn-sm mb-2 btn-primary btn-light-primary mx-4'
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmDeleteModal;