import { mls } from "lib/multilanguagesupport";
import { _supistaApiGet, _supistaApiPost } from "lib/server-connection/connections";
import reduxConstants from "lib/reusable-components/reusableUtils/redux/reduxConstants";
import store from "lib/reusable-components/reusableUtils/redux/store";
import { create } from "lib/reusable-components/reusableUtils/redux/stateSlice";

export const publishToProduction = (appID) => {
  _supistaApiPost(`auth/${appID}/publishToProduction`, {
    data: {},
  })
    .then(async (response) => {
      // console.log(response);
      await fetchData(appID, true);
    })
    .catch((err) => {
      // console.log(err);
    });
};


const fetchData = async (appID) => {
  if (!appID) {
    return;
  }

  try {
    const appSchema = await _supistaApiGet(`appSchema/${appID}`);

    store.dispatch(
      create({
        setPath: reduxConstants.config.APP_SCHEMA,
        value: appSchema,
      })
    );

  } catch (error) {
    store.dispatch(
      create({
        setPath: reduxConstants.config.APP_STATUS.ERROR_TOAST,
        value: {
          message: mls('There was a problem while fetching App Schema, please try again later!'),
          time: 5,
          isActive: true,
        },
      })
    );
  }
};
