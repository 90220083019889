import { useState } from 'react';
import { mls } from 'lib/multilanguagesupport';
import { Card } from './components/Card';
import { EditTableSettingsPopup } from './components/EditTableSettingsPopup/EditTableSettingsPopup';
import { CreateNewTable } from './modal/add-database/CreateNewTableWrapper';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import useUpdateERPSchema from 'lib/reusable-components/reusableUtils/updateSchema/useUpdateSchema';

const DataTable = () => {
  const [columnUpdates, setColumnUpdates] = useState({
    addColumns: [],
    updateColumns: [],
    deleteColumns: [],
    renameColumns: {},
    dropTable: false,
    createTable: true,
  });

  const allDataTableSchema = useSelector((state) => {
    return (
      state.config_store?.[reduxConstants.config.APP_SCHEMA]?.[reduxConstants.config.DATA_TABLE] ||
      {}
    );
  });

  const updateERPSchema = useUpdateERPSchema();

  const handleDataTableSchema = (key, value) => {
    updateERPSchema(
      `${reduxConstants.config.APP_SCHEMA}.${reduxConstants.config.DATA_TABLE}`,
      key,
      value
    );
  };

  // const [dataIndex, setDataIndex] = useState(0);
  const [OpenEditTable, setOpenEditTable] = useState(false);
  const [openNewTable, setOpenNewTable] = useState(false);
  // const [isJoinedTable, setIsJoinedTable] = useState();

  const handleEditTableClose = () => setOpenEditTable(false);

  return (
    <>
      {openNewTable && (
        <CreateNewTable
          setDataTableData={handleDataTableSchema}
          setOpen={setOpenNewTable}
          allDataTableData={allDataTableSchema}
          dataIndex={openNewTable}
          columnUpdates={columnUpdates}
          setColumnUpdates={setColumnUpdates}
        />
      )}

      {OpenEditTable && (
        <EditTableSettingsPopup
          columnUpdates={columnUpdates}
          // setColumnUpdates={setColumnUpdates}
          show={true}
          allDataTableData={allDataTableSchema}
          setDataTableData={handleDataTableSchema}
          dataIndex={OpenEditTable}
          handleClose={handleEditTableClose}
        />
      )}
      <div className='d-flex justify-content-end align-items-center mb-3 p-5 bg-white'>
        <button
          className='btn btn-sm btn-primary ms-1'
          onClick={() => {
            setOpenNewTable(true);
            setColumnUpdates({ ...columnUpdates, createTable: true });
          }}
        >
          {mls('Create New Table')}
        </button>
      </div>
      <div className='row'>
        {!_.isEmpty(allDataTableSchema) ? (
          Object.keys(allDataTableSchema)
            ?.filter((tableName) => allDataTableSchema[tableName]?.tableType !== 'junction')
            .map((tableName) => {
              return (
                <div className='col-md-4 col-sm-6 mb-6' key={tableName}>
                  <Card
                    handleShow={() => {
                      setOpenEditTable(tableName);
                      setColumnUpdates({ ...columnUpdates, createTable: false });
                    }}
                    index={tableName}
                    title={allDataTableSchema[tableName].name}
                    numOfColumns={allDataTableSchema[tableName].columnSchema?.length}
                    description={allDataTableSchema[tableName].description}
                  />
                </div>
              );
            })
        ) : (
          <div className='alert alert-primary' role='alert'>
            {mls('No data tables')}
          </div>
        )}
      </div>
    </>
  );
};

export default DataTable;
