import { useState } from 'react';
import { arrayMoveImmutable } from 'array-move';

import AddRemoveComponentsModal from '../AddRemoveComponentsModal';
import DragAndDrop from '../DragAndDrop';

import { mls } from 'lib/multilanguagesupport';
import * as editPageConstants from '../../constants';
import { useHistory } from 'react-router';
import getAppID from 'lib/server-connection/getAppID';
import { Link } from 'react-router-dom';

export const ScreenForm = ({
  formik,
  activePageInEditing,
  setActivePageInEditing
}) => {
  const [isComponentModalOpen, setIsComponentModalOpen] = useState(false);

  const history = useHistory();
  const appID = getAppID(history.location.pathname);

  const handleReArrangeComponents = (dragEndDetails) => {
    const reArrangedComponents = arrayMoveImmutable(formik.values.screenGroup?.[activePageInEditing.screenGroupIndex]?.screen?.[activePageInEditing.screenIndex]?.component,
      dragEndDetails.source.index,
      dragEndDetails.destination.index
    );
    formik.setFieldValue(`screenGroup.${activePageInEditing.screenGroupIndex}.screen.${activePageInEditing.screenIndex}.component`,
      reArrangedComponents
    );
  }
  return <>
    <AddRemoveComponentsModal
      isComponentModalOpen={isComponentModalOpen}
      setIsComponentModalOpen={setIsComponentModalOpen}
      activePageInEditing={activePageInEditing}
      formik={formik}
    />
    <label className='form-label fs-6 fw-bolder text-dark'>{mls('Name')}</label>
    <input
      {...formik.getFieldProps(`screenGroup.${activePageInEditing.screenGroupIndex}.screen.${activePageInEditing.screenIndex}.screenName`)}
      placeholder='Screen name'
      className='form-control form-control-lg form-control-solid'
    />
    
    {/* <label className='form-label fs-6 fw-bolder text-dark mt-5'>{mls('Type')}</label>
    <select
      value="screen"
      { ...formik.getFieldProps(`screenGroup.${activePageInEditing.screenGroupIndex}.screen.${activePageInEditing.screenIndex}.type`) }
      className='form-select form-control form-control-lg form-control-solid'

    >
      <option value='' hidden>{mls('Select...')}</option>
      <option value='screen'>{mls('Screen')}</option>
      <option value='parentScreen'>{mls('Parent Screen')}</option>
      <option value='childScreen'>{mls('Child Screen')}</option>
    </select> */}

    <div className='d-flex justify-content-between px-2'>
      <label className='form-label fs-6 fw-bolder text-dark mt-5'>{mls('Description')}</label>
      <label className='form-label text-muted fs-6 fw-bolder text-dark mt-5'>
        {formik.values?.screenGroup?.[activePageInEditing.screenGroupIndex]?.screen?.[activePageInEditing.screenIndex]?.description?.length || 0}/150
      </label>
    </div>
    <textarea
      {...formik.getFieldProps(`screenGroup.${activePageInEditing.screenGroupIndex}.screen.${activePageInEditing.screenIndex}.description`)}
      maxLength={150}
      style={{ resize: 'none', height: 80 }}
      placeholder='Description'
      className='form-control form-control-lg form-control-solid'
    />
    <div className='d-flex justify-content-between px-2'>
      <label className='form-label fs-6 fw-bolder text-dark mt-4'>{mls('Components')}</label>
      <span className="mt-4">
        <Link className='text-muted text-hover-primary mx-4' to={`/${appID}/components?new=true`}>
          {mls('Create New Component')}
        </Link>
        <button onClick={() => { setIsComponentModalOpen(true) }} className=' btn btn-sm btn-primary btn-light-primary'>
          {mls('Select components')}
        </button>
      </span>
      
    </div>
    <DragAndDrop
      represents={editPageConstants.COMPONENT}
      listForDragAndDrop={formik.values?.screenGroup?.[activePageInEditing.screenGroupIndex]?.screen?.[activePageInEditing.screenIndex]?.component}
      onDragEnd={handleReArrangeComponents}
      setActivePageInEditing={setActivePageInEditing}
    />
  </>
}

export default ScreenForm;