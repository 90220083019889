import { useEffect, useRef, useState } from 'react';
import Select, { components } from 'react-select';
import { mls } from 'lib/multilanguagesupport';

export const CalcSection = ({
  dataTableSchema,
  currentColumn,
  currentColumnIndex,
  setDataTableSchema,
  colIndex,
}) => {
  const [colOptions, setColOptions] = useState([]);
  const [op, setOp] = useState('');
  const [selection, setSelection] = useState(2);
  const [update, setUpdate] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');

  const selectRef = useRef();
  console.log(colOptions, 'colOptions');
  useEffect(() => {
    let tmp = dataTableSchema.columnSchema
      .filter((data, index) => index !== colIndex && data.dataType === 'number')
      .map((data) => {
        return { value: data.name, label: data.name };
      });
    setColOptions(tmp);
  }, []);

  const addFormula = (e) => {
    e.preventDefault();
    if (colOptions.length === 0) {
      setErrorMessage('No columns with type "number" available.');
      return;
    }
    setErrorMessage('');

    let tmp = dataTableSchema;
    tmp.columnSchema[colIndex]?.calc?.push({
      params: [],
      op: '',
    });
    setDataTableSchema(tmp);
    setUpdate((prev) => prev + 1);
  };

  const handleDelete = (idx) => {
    let tmp = dataTableSchema.columnSchema[colIndex]?.calc.filter((_, index) => index !== idx);
    dataTableSchema.columnSchema[colIndex]['calc'] = tmp;
    setDataTableSchema(dataTableSchema);
    setUpdate((prev) => prev + 1);
  };

  const handleSelectChange = (e, idx) => {
    if (e.target.value === 'isActive') {
      selectRef.current.clearValue();
      setSelection(1);
    }
    if (op === 'isActive' && e.target.value !== 'isActive') {
      selectRef.current.clearValue();
      setSelection(2);
    }
    setOp(e.target.value);
    let tmp = dataTableSchema;
    // console.log(e);
    tmp.columnSchema[colIndex].calc[idx]['op'] = e.target.value;
  };

  const handleParamsChange = (e, idx) => {
    let tmp = dataTableSchema;
    // console.log(e);
    tmp.columnSchema[colIndex].calc[idx]['params'] = e.map((data) => data.value);
    setDataTableSchema(tmp);
    setUpdate((prev) => prev + 1);
  };

  const Menu = (props) => {
    const optionSelectedLength = props.getValue().length || 0;
    return (
      <components.Menu {...props}>
        {optionSelectedLength < selection ? (
          props.children
        ) : (
          <div style={{ margin: 15 }}>
            You can only select {selection === 1 ? 'one' : 'two'} option
            {selection === 1 ? '' : 's'}.
          </div>
        )}
      </components.Menu>
    );
  };
  const isValidNewOption = (inputValue, selectValue) =>
    inputValue.length > 0 && selectValue.length < 2;

  return (
    <div className='px-7 mt-7 py-5' style={{ background: '#F5F8FA' }}>
      <p className='mb-5 fs-6 fw-bolder text-dark'>{mls('Formula')}</p>
      {errorMessage && <p className='text-danger mx-4'>{errorMessage}</p>}
      {dataTableSchema.columnSchema[colIndex]?.calc?.length === 0 && (
        <p className='text-muted mx-4'>No formula available.</p>
      )}
      {dataTableSchema.columnSchema[colIndex]?.calc?.length !== 0 && (
        <div className='row'>
          <div className='col-6 '>
            <label className='form-label fs-6 fw-bolder text-dark mx-9'>{mls('Operation')}</label>
          </div>
          <div className='col-6 '>
            <label className='form-label fs-6 fw-bolder text-dark mx-5'>{mls('Columns')}</label>
          </div>
        </div>
      )}
      {dataTableSchema.columnSchema[colIndex]?.calc?.map((data, key) => (
        <div className='row' key={key}>
          <div className='col-6'>
            <div className='d-flex align-items-center'>
              <i
                onClick={() => handleDelete(key)}
                className='fas fa-trash-alt text-danger'
                style={{ marginRight: 15, fontSize: 15, cursor: 'pointer' }}
              ></i>
              <select
                name='validation'
                className='form-select form-control form-control-lg'
                onChange={(e) => handleSelectChange(e, key)}
              >
                {/* <option disabled selected value> No columns available </option> */}
                <option value='' hidden>
                  {mls('Select...')}
                </option>
                <option selected={data.op === '+' ? true : false} value='+'>
                  {mls('Addition')}
                </option>
                <option selected={data.op === '-' ? true : false} value='-'>
                  {mls('Subtraction')}
                </option>
                <option selected={data.op === '*' ? true : false} value='*'>
                  {mls('Multiplication')}
                </option>
                <option selected={data.op === '/' ? true : false} value='/'>
                  {mls('Division')}
                </option>
                <option selected={data.op === 'isTrue' ? true : false} value='isActive'>
                  {mls(' Is Active')}
                </option>
              </select>
            </div>
          </div>
          <div style={{ marginTop: -10 }} className='col-6'>
            {colOptions && (
              <Select
                ref={selectRef}
                styles={{
                  control: () => ({
                    display: 'flex',
                    background: 'white',
                    border: '1px solid #E4E6EF',
                    borderRadius: 5,
                    padding: 2.7,
                    width: '100%',
                  }),
                }}
                components={{ Menu }}
                className='form-control form-control-solid w-100'
                closeMenuOnSelect={false}
                options={colOptions}
                isMulti
                isValidNewOption={isValidNewOption}
                defaultValue={data.params.map((data) => {
                  return { value: data, label: data };
                })}
                onChange={(e) => handleParamsChange(e, key)}
              />
            )}
          </div>
        </div>
      ))}
      <button onClick={addFormula} className='btn btn-sm btn-primary btn-light-primary mx-4 mt-3'>
        {mls('Add formula')}
      </button>
    </div>
  );
};
