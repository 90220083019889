import React, { useState } from 'react';
import styles from './PDFTemplate.module.scss';
import { mls } from 'lib/multilanguagesupport';
import { MdOutlineArrowBackIos } from 'react-icons/md';

import { CircularProgress } from '@mui/material';

interface createTemplateHeaderProps {
  setIsNewTemplate: Function;
  setIsTemplateForm: Function;
  downloadPdfButtonRef: any;
  isTemplateForm: boolean;
  isNewTemplate: boolean;
  isTemplateSetting: boolean;
  setIsTemplateSetting: Function;
  templateData: any;
  selectedTemplateID: string;
  setSelectedTemplateID: Function;
  componentSchema: any;
  setComponentSchema: Function;
}
const CreateTemplateHeader = ({
  setIsNewTemplate,
  setIsTemplateForm,
  downloadPdfButtonRef,
  isTemplateForm,
  isNewTemplate,
  isTemplateSetting,
  setIsTemplateSetting,
  templateData,
  setSelectedTemplateID,
  selectedTemplateID,
  componentSchema,
  setComponentSchema,
}: createTemplateHeaderProps) => {
  const [isPdfDownloading, setIsPdfDownloading] = useState(false);
  const handleBackButton = () => {
    if (!isTemplateForm) {
      setIsTemplateForm(true);
    } else {
      setIsTemplateSetting(false);
    }
  };
  const downloadPdf = async () => {
    if (downloadPdfButtonRef?.current?.downloadPdf) {
      setIsPdfDownloading(true);
      const dowloadingRes = await downloadPdfButtonRef?.current?.downloadPdf();
      if (!dowloadingRes.skip) {
        setIsPdfDownloading(false);
      }
    }
  };
  const handleSave = () => {
    if (selectedTemplateID) {
      setComponentSchema({
        ...componentSchema,
        PDFTemplate: {
          ...(componentSchema?.PDFTemplate ?? {}),
          [selectedTemplateID]: templateData,
        },
      });
    }
    setIsTemplateSetting(false);
    setIsTemplateForm(false);
  };
  const handleDelete = () => {
    if (selectedTemplateID) {
      let finalPDFTemplate: { [key: string]: any } = {
        ...(componentSchema?.PDFTemplate ?? {}),
      };

      delete finalPDFTemplate[selectedTemplateID];
      setComponentSchema({
        ...componentSchema,
        PDFTemplate: finalPDFTemplate,
      });
    }
    setIsTemplateSetting(false);
    setIsTemplateForm(false);
  };
  return (
    <div className={styles.createTemplateHeader}>
      <div className={styles.backButton} onClick={() => handleBackButton()}>
        <MdOutlineArrowBackIos />
      </div>
      <div className={styles.buttonContainer}>
        {!isTemplateForm && !isNewTemplate ? (
          <>
            <button
              type='button'
              className='primaryButton secondaryColor'
              onClick={() => handleDelete()}
            >
              {/* {isPdfDownloading ? (
            <>
              <CircularProgress size={10} color='inherit' style={{ color: 'white' }} /> &nbsp;
            </>
          ) : null} */}
              {mls('Delete')}
            </button>
          </>
        ) : null}
        {!isTemplateForm ? (
          <>
            <button type='button' className='primaryButton' onClick={() => handleSave()}>
              {/* {isPdfDownloading ? (
            <>
              <CircularProgress size={10} color='inherit' style={{ color: 'white' }} /> &nbsp;
            </>
          ) : null} */}
              {mls('Save')}
            </button>
          </>
        ) : null}

        {!isTemplateForm ? (
          <>
            <button
              type='button'
              className='primaryButton secondaryButtonColor'
              onClick={() => downloadPdf()}
            >
              {isPdfDownloading ? (
                <>
                  <CircularProgress size={10} color='inherit' style={{ color: 'white' }} /> &nbsp;
                </>
              ) : null}
              {mls('Download PDF')}
            </button>
          </>
        ) : null}
      </div>
    </div>
  );
};
export default CreateTemplateHeader;
