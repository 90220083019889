import Modal from '@mui/material/Modal';

const ConfirmDelete = ({
  handleDelete,
  accordionRef,
  dataTableSchema,
  dataIndex,
  colSchIndex,
  confirmDeleteModal,
  setConfirmDeleteModal,
  deleteColIndex
}) => {
  return (
    <Modal open={confirmDeleteModal} onClose={() => setConfirmDeleteModal(false)}>
      <div className='modal-dialog modal-dialog-centered mw-600px'>
        <div className='modal-content d-flex justify-content-center align-items-center p-5'>
          <p className='p-5 mb-7 fs-4 fw-bold'>Are you sure you want to delete this column?</p>
          <div className='d-flex'>
            <button
              data-bs-dismiss='modal'
              onClick={() => {
                console.log(dataTableSchema.columnSchema[colSchIndex]);
                handleDelete(deleteColIndex);
                accordionRef.current[colSchIndex].click();
                setConfirmDeleteModal(false);
              }}
              style={{ marginTop: -15 }}
              className='btn btn-sm mb-2 btn-danger btn-light-primary mx-4'
            >
              Delete column
            </button>
            <button
              onClick={() => setConfirmDeleteModal(false)}
              style={{ marginTop: -15 }}
              className='btn btn-sm mb-2 btn-primary btn-light-primary mx-4'
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export { ConfirmDelete };
