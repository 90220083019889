import React from 'react';
import { mls } from 'lib/multilanguagesupport';
import style from './roleAccess.module.scss';
import CheckBox from 'lib/reusable-components/Components/CheckBox/CheckBox';
import { FiFilter } from 'react-icons/fi';
import {
  columnBooleanTypeObject,
  defaultTeamAccessObject,
  filterTypeObject,
} from '../../data/appComponentData';

const getIsAllTrue = ({
  roleAccessElem,
  connectedTablesData,
}: {
  roleAccessElem: any;
  connectedTablesData: any;
}) => {
  const columnWiseRolesLength = Object.keys(roleAccessElem?.ColumnWiseRoles ?? {})?.length;
  if (roleAccessElem?.type === columnBooleanTypeObject?.AllTrueWithExc?.key) {
    return !(columnWiseRolesLength >= 1);
  } else {
    let allColumn: any[] = [];
    Object.keys(connectedTablesData ?? {}).forEach((connectedTablesKey) => {
      const connectedTablesElem = connectedTablesData[connectedTablesKey];
      allColumn = [...allColumn, ...(connectedTablesElem?.columnSchema ?? [])];
    });
    return columnWiseRolesLength === allColumn?.length;
  }
};
interface columnWiseRolesContainerProps {
  connectedTablesData: any;
  selectedTeamForColumn: string;
  roleAccessSchema: any;
  setRoleAccessSchema: Function;
  filterData: any;
  setFilterData: Function;
}
const ColumnWiseRolesContainer = ({
  connectedTablesData,
  selectedTeamForColumn,
  roleAccessSchema,
  setRoleAccessSchema,
  setFilterData,
  filterData,
}: columnWiseRolesContainerProps) => {
  const roleAccessElem = roleAccessSchema?.[selectedTeamForColumn];

  if (!roleAccessElem) {
    return <></>;
  }
  const isCreate = getIsAllTrue({ roleAccessElem: roleAccessElem?.C ?? {}, connectedTablesData });
  const isRead = getIsAllTrue({ roleAccessElem: roleAccessElem?.R ?? {}, connectedTablesData });
  const isUpdate = getIsAllTrue({ roleAccessElem: roleAccessElem?.U ?? {}, connectedTablesData });
  // const isCreate =
  //   roleAccessElem?.C?.type === columnBooleanTypeObject?.AllTrueWithExc?.key ? true : false;
  // const isRead =
  //   roleAccessElem?.R?.type === columnBooleanTypeObject?.AllTrueWithExc?.key ? true : false;
  // const isUpdate =
  //   roleAccessElem?.U?.type === columnBooleanTypeObject?.AllTrueWithExc?.key ? true : false;
  const updateTeamAccess = ({
    teamKey,
    teamAccessData,
  }: {
    teamKey: string;
    teamAccessData: any;
  }) => {
    setRoleAccessSchema({ ...roleAccessSchema, [teamKey]: teamAccessData });
  };
  const handleSelection = ({ key, value }: { key: string; value: boolean }) => {
    let finalTeamAcces: { [key: string]: any } = { ...roleAccessElem };
    let filterList = finalTeamAcces?.[key]?.filterList ?? {};
    if (
      (value
        ? columnBooleanTypeObject?.AllTrueWithExc?.key
        : columnBooleanTypeObject?.AllFalseWithExc?.key) !== finalTeamAcces?.[key]?.type
    ) {
      filterList = {};
    }

    finalTeamAcces = {
      ...(finalTeamAcces ?? {}),
      [key]: {
        ...(finalTeamAcces?.[key] ?? {}),
        type: value
          ? columnBooleanTypeObject?.AllTrueWithExc?.key
          : columnBooleanTypeObject?.AllFalseWithExc?.key,
        ColumnWiseRoles: {},
        filterList,
      },
    };
    updateTeamAccess({ teamKey: selectedTeamForColumn, teamAccessData: finalTeamAcces });
  };
  const roleAccessCrudLength = Object.keys(roleAccessElem ?? {}).length;
  const handleFilter = ({
    key,
    disabled,
    selectedTeamsKey,
    uniqueColumnName,
  }: {
    key: string;
    selectedTeamsKey: string;
    uniqueColumnName: string;
    disabled?: boolean;
  }) => {
    if (disabled) {
      return;
    }
    setFilterData({
      ...filterData,
      popupStatus: true,
      filters: roleAccessSchema?.[selectedTeamsKey]?.[key]?.columnFilter?.[uniqueColumnName] ?? {},
      selectedTeamsKey: selectedTeamsKey,
      key: key,
      filterType: filterTypeObject.columnFilter.key,
      uniqueColumnName,
    });
  };
  const filterLength = ({
    key,
    disabled,
    selectedTeamsKey,
    uniqueColumnName,
  }: {
    key: string;
    selectedTeamsKey: string;
    uniqueColumnName: string;
    disabled?: boolean;
  }) => {
    if (disabled) {
      return '';
    }
    const filterLength = Object.keys(
      roleAccessSchema?.[selectedTeamsKey]?.[key]?.columnFilter?.[uniqueColumnName] ?? {}
    )?.length;

    if (filterLength > 0) {
      return (
        <div>
          (<b>{mls('Applied')}</b>: {filterLength})
        </div>
      );
    }
    return '';
  };
  return (
    <div className={style.columnWiseRolesGridOuterContainer}>
      <div
        className={style.columnWiseRolesGridInnerContainer}
        style={{
          gridTemplateColumns: `18rem repeat(${
            roleAccessCrudLength + (roleAccessElem.D ? 0 : 1)
          },  auto)`,
        }}
      >
        <div
          className={`${style.gridCell} ${style.upperColumnGridCell}  ${style.sideColumnGridCell}  ${style.teamNameColumnGridCell}`}
        >
          {mls('Columns')}
        </div>
        <div className={`${style.gridCell} ${style.upperColumnGridCell}`}>{mls('Table Name')}</div>
        {roleAccessElem?.C ? (
          <div className={`${style.gridCell} ${style.upperColumnGridCell}`}>
            <CheckBox
              checked={isCreate}
              onClick={(value: boolean) => {
                handleSelection({ key: 'C', value });
              }}
            />
            {mls('Create')}
          </div>
        ) : null}

        {roleAccessElem?.R ? (
          <div className={`${style.gridCell} ${style.upperColumnGridCell}`}>
            <CheckBox
              checked={isRead}
              onClick={(value: boolean) => {
                handleSelection({ key: 'R', value });
              }}
            />
            {/* {mls('Read')}/{mls('Filter')} */}
            {mls('Read')}
          </div>
        ) : null}
        {roleAccessElem?.U ? (
          <div className={`${style.gridCell} ${style.upperColumnGridCell}`}>
            <CheckBox
              checked={isUpdate}
              disabled={!isRead}
              onClick={(value: boolean) => {
                handleSelection({ key: 'U', value });
              }}
            />
            {/* {mls('Update')}/{mls('Filter')} */}
            {mls('Update')}
          </div>
        ) : null}

        {React.Children.toArray(
          Object.keys(connectedTablesData ?? {}).map((connectedTablesKey) => {
            const connectedTablesElem = connectedTablesData[connectedTablesKey];

            return (
              <>
                {(connectedTablesElem?.columnSchema ?? []).map((columnElem: any) => {
                  const uniqueColumnName = `${connectedTablesKey}.${columnElem?.columnName}`;
                  const getTrueValue = ({
                    key,
                    roleAccessElem,
                    uniqueColumnName,
                  }: {
                    key: string;
                    roleAccessElem: any;
                    uniqueColumnName: string;
                  }) => {
                    const selectedRoleAccess = roleAccessElem?.[key] ?? {};
                    const isSlected = !selectedRoleAccess?.ColumnWiseRoles?.[uniqueColumnName]
                      ? true
                      : false;
                    // if (selectedRoleAccess?.type === columnBooleanTypeObject?.AllTrueWithExc?.key) {
                    if (selectedRoleAccess?.type === defaultTeamAccessObject?.type) {
                      return isSlected;
                    } else {
                      return !isSlected;
                    }
                  };
                  const isCreate = getTrueValue({
                    key: 'C',
                    roleAccessElem,
                    uniqueColumnName,
                  });
                  const isRead = getTrueValue({
                    key: 'R',
                    roleAccessElem,
                    uniqueColumnName,
                  });
                  const isUpdate = getTrueValue({
                    key: 'U',
                    roleAccessElem,
                    uniqueColumnName,
                  });
                  // const isRead = !roleAccessElem?.R?.ColumnWiseRoles[uniqueColumnName]
                  //   ? true
                  //   : false;
                  // const isUpdate = !roleAccessElem?.U?.ColumnWiseRoles[uniqueColumnName]
                  //   ? true
                  //   : false;

                  const handleSelection = ({ key, value }: { key: string; value: boolean }) => {
                    let finalTeamAcces: { [key: string]: any } = { ...roleAccessElem };
                    const finalValue =
                      finalTeamAcces?.[key]?.type === defaultTeamAccessObject?.type
                        ? value
                        : !value;

                    if (finalValue) {
                      let tempRoles = { ...(finalTeamAcces?.[key]?.ColumnWiseRoles ?? {}) };
                      let tempFilterList = { ...(finalTeamAcces?.[key]?.filterList ?? {}) };
                      delete tempRoles[uniqueColumnName];
                      delete tempFilterList[uniqueColumnName];

                      finalTeamAcces = { ...roleAccessElem };

                      finalTeamAcces = {
                        ...finalTeamAcces,
                        [key]: {
                          ...(finalTeamAcces?.[key] ?? {}),
                          ColumnWiseRoles: tempRoles,
                          filterList: tempFilterList,
                        },
                      };
                      // if (key === 'R') {
                      //   delete finalTeamAcces['U'];
                      // }
                      // delete finalTeamAcces[key];
                    } else {
                      const defaultTeamAccessObject = {
                        // filters: {},
                      };
                      finalTeamAcces = {
                        ...finalTeamAcces,
                        [key]: {
                          ...(finalTeamAcces?.[key] ?? {}),
                          ColumnWiseRoles: {
                            ...(finalTeamAcces?.[key]?.ColumnWiseRoles ?? {}),
                            [uniqueColumnName]: defaultTeamAccessObject,
                          },
                        },
                      };
                      // if (key === 'R') {
                      //   if (!finalTeamAcces?.U) {
                      //     finalTeamAcces = {
                      //       ...finalTeamAcces,
                      //       U: defaultTeamAccessObject,
                      //     };
                      //   }
                      // }
                    }
                    updateTeamAccess({
                      teamKey: selectedTeamForColumn,
                      teamAccessData: finalTeamAcces,
                    });
                  };

                  return (
                    <>
                      <div className={`${style.gridCell} ${style.sideColumnGridCell}`}>
                        {columnElem?.name}
                      </div>
                      <div
                        className={`${style.gridCell} ${style.cellInnerDiv} ${style.valueColumnGridCell}`}
                      >
                        {connectedTablesElem?.name}
                      </div>
                      {roleAccessElem?.C ? (
                        <div
                          className={`${style.gridCell}  ${style.cellInnerDiv} ${style.valueColumnGridCell}`}
                        >
                          <CheckBox
                            checked={isCreate}
                            onClick={(value: boolean) => {
                              handleSelection({ key: 'C', value });
                            }}
                          />
                          {/* <FiFilter
                            className={`${style.valueFilterIcon}  ${
                              !isCreate ? style.valueGridDisabled : ''
                            }`}
                            onClick={() =>
                              handleFilter({
                                key: 'C',
                                disabled: !isCreate,
                                selectedTeamsKey: selectedTeamForColumn,
                                uniqueColumnName,
                              })
                            }
                          />
                          {filterLength({
                            key: 'C',
                            disabled: !isCreate,
                            selectedTeamsKey: selectedTeamForColumn,
                            uniqueColumnName,
                          })} */}
                        </div>
                      ) : null}
                      {roleAccessElem?.R ? (
                        <div
                          className={`${style.gridCell}  ${style.cellInnerDiv} ${style.valueColumnGridCell}`}
                        >
                          <CheckBox
                            checked={isRead}
                            onClick={(value: boolean) => {
                              handleSelection({ key: 'R', value });
                            }}
                          />
                          {/* <FiFilter
                            className={`${style.valueFilterIcon}  ${
                              !isRead ? style.valueGridDisabled : ''
                            }`}
                            onClick={() =>
                              handleFilter({
                                key: 'R',
                                disabled: !isRead,
                                selectedTeamsKey: selectedTeamForColumn,
                                uniqueColumnName,
                              })
                            }
                          />
                          {filterLength({
                            key: 'R',
                            disabled: !isRead,
                            selectedTeamsKey: selectedTeamForColumn,
                            uniqueColumnName,
                          })} */}
                        </div>
                      ) : null}

                      {roleAccessElem?.U ? (
                        <div
                          className={`${style.gridCell}  ${style.cellInnerDiv} ${style.valueColumnGridCell}`}
                        >
                          <CheckBox
                            checked={isUpdate}
                            onClick={(value: boolean) => {
                              handleSelection({ key: 'U', value });
                            }}
                            disabled={!isRead}
                          />
                          {/* <FiFilter
                            className={`${style.valueFilterIcon} ${
                              !isRead || !isUpdate ? style.valueGridDisabled : ''
                            }`}
                            onClick={() =>
                              handleFilter({
                                key: 'U',
                                disabled: !isRead || !isUpdate,
                                selectedTeamsKey: selectedTeamForColumn,
                                uniqueColumnName,
                              })
                            }
                          />
                          {filterLength({
                            key: 'U',
                            disabled: !isRead || !isUpdate,
                            selectedTeamsKey: selectedTeamForColumn,
                            uniqueColumnName,
                          })} */}
                        </div>
                      ) : null}
                    </>
                  );
                })}
              </>
            );
          })
        )}
      </div>
    </div>
  );
};

export default ColumnWiseRolesContainer;
