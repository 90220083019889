import clsx from 'clsx';
import { useState } from 'react';
import { mls } from 'lib/multilanguagesupport';
import { CalcSection } from './CalcSection';
import { ForeignFieldSection } from './ForeignFieldSection';
import { OptionSection } from './OptionSection';
import { ValidationSection } from './ValidationSection';
import { charactersNotAllowedInTableAndColumnName } from './util/utilFunctions';

export const ColumnSettings = ({
  sch,
  // dataIndex,
  index, // column index
  // setSampleData,
  // sampleData,
  // setUpdateDataTable,
  children,
  // accordionRef,
  // columnExistCounter,
  // setColumnExistCounter,
  dataTableSchema,
  setDataTableSchema,
  allDataTableData,
  // columnUpdates,
  // setColumnUpdates,
  // setLocalDataTableSchema,
  columnNameError,
  setColumnNameError,
  setTableUpdates,
  tableUpdates,
}) => {
  // const [columnSchema, setColumnSchema] = useState({ ...sch });
  // const [update, setUpdate] = useState(0);
  const [columnNameExist, setColumnNameExist] = useState(false);
  const originalColumnName = dataTableSchema.columnSchema[index].name;
  // const [localDataTableSchema, setLocalDataTableSchema] = useState(dataTableSchema);
  // console.log(dataTableSchema, allDataTableData, 'dataTableSchema datatbaledata');
  // console.log(sch, columnSchema, 'sch columnSchema');
  const updateColumn = ({ columnData }) => {
    const isNewAddColumn = (tableUpdates?.updateColumns ?? [])?.some((columnElem) => {
      return columnElem?.columnName === columnData?.columnName;
    });
    console.log(isNewAddColumn);
    setTableUpdates({
      ...tableUpdates,
      updateColumns: [...(tableUpdates?.updateColumns ?? []), columnData],
    });
  };
  const handleColTypeChange = (e) => {
    // setLocalDataTableSchema((old) => {
    const newData = global.structuredClone(dataTableSchema);
    const newType = e.target.value;
    const column = newData.columnSchema[index];

    // Update the type
    column.dataType = newType;

    // Remove options if the new type is not 'multipleSelect' or 'select'
    if (!['multipleSelect', 'select'].includes(newType)) {
      delete column.options;
    } else if (!column.options) {
      column.options = [];
    }

    // Remove fetchForeignData if the new type is not 'foreignField'
    if (newType !== 'foreignField') {
      delete column.fetchForeignData;
    } else if (!column.fetchForeignData) {
      column.fetchForeignData = {};
    }

    // Remove formula if the new type is not 'formula'
    if (newType !== 'formula') {
      delete column.formula;
    } else if (!column.formula) {
      column.formula = [];
    }
    updateColumn({ columnData: column });

    setDataTableSchema(newData);
    //   return newData;
    // });
  };

  const handleChangeColumnName = (e, idx) => {
    // setLocalDataTableSchema((old) => {
    const newData = global.structuredClone(dataTableSchema);
    // newData.columnSchema[idx].name = e.target.value;
    newData.columnSchema[idx].columnName = e.target.value.replace(' ', '');
    setDataTableSchema(newData);
    //   return newData;
    // });
    checkIfColumnNameExists(e.target.value, idx);
    checkColumnNameValid(e.target.value);
  };

  const handleChangeColumnLabel = (e, idx) => {
    // setLocalDataTableSchema((old) => {
    const newData = global.structuredClone(dataTableSchema);
    newData.columnSchema[idx].name = e.target.value;
    setDataTableSchema(newData);
    //   return newData;
    // });
  };

  // If the column name user is setting is already used for another column, revert back to original column name on input field blur
  const revertToOriginalColumnName = () => {
    if (columnNameExist || columnNameError) {
      // setLocalDataTableSchema((old) => {
      const newData = { ...dataTableSchema };
      newData.columnSchema[index].name = originalColumnName;
      newData.columnSchema[index].columnName = originalColumnName.replace(' ', '');
      setDataTableSchema(newData);
      //   return newData;
      // });
      setColumnNameExist(false);
      setColumnNameError(false);
    }
  };

  const checkIfColumnNameExists = (newName, indx) => {
    for (var i = 0; i < dataTableSchema?.columnSchema?.length; i++) {
      if (dataTableSchema.columnSchema[i].name === newName && i !== indx) {
        setColumnNameExist(true);
        setColumnNameError(true);
        break;
      } else {
        setColumnNameExist(false);
        setColumnNameError(false);
      }
    }
  };

  const checkColumnNameValid = (newName) => {
    const containsCharacterWhichIsNotAllowed = charactersNotAllowedInTableAndColumnName.some(
      (char) => {
        return newName.includes(char);
      }
    );
    containsCharacterWhichIsNotAllowed &&
      setColumnNameError(
        "Column name can only contain these special characters: space (' '), hyphen (-) and underscore (_)"
      );
  };

  const handleReadOnlyChange = (e, idx) => {
    // setLocalDataTableSchema((old) => {
    const newData = { ...dataTableSchema };
    newData.columnSchema[index].readOnly = newData.columnSchema[index]?.readOnly ? false : true;
    setDataTableSchema(newData);
    //   return newData;
    // });
  };

  const addOption = (e, option = { label: '', value: '' }) => {
    e.preventDefault();
    let tempProps = dataTableSchema.columnSchema[index];
    if (!tempProps?.selectMenu) {
      tempProps.selectMenu = [];
      // Object.preventExtensions(tempProps);
    }
    tempProps.selectMenu.push(option);

    const newColumnSchema = [
      ...dataTableSchema.columnSchema.slice(0, index),
      tempProps,
      ...dataTableSchema.columnSchema.slice(index + 1),
    ];
    setDataTableSchema({ ...dataTableSchema, columnSchema: newColumnSchema });
    // console.log(
    //   { ...dataTableSchema, columnSchema: newColumnSchema },
    //   tempProps,
    //   option,
    //   'tempPropstempProps'
    // ); // true
    // setUpdate((prev) => prev + 1);
  };

  const handleChangOption = (e, optionIndex) => {
    setDataTableSchema((prevSchema) => {
      const updatedColumnSchema = [...prevSchema.columnSchema];
      const columnToUpdate = { ...updatedColumnSchema[index] };

      const updatedSelectMenu = [...(columnToUpdate.selectMenu || [])];
      updatedSelectMenu[optionIndex] = {
        ...updatedSelectMenu[optionIndex],
        [e.target.name]: e.target.value,
      };

      columnToUpdate.selectMenu = updatedSelectMenu;
      updatedColumnSchema[index] = columnToUpdate;

      return {
        ...prevSchema,
        columnSchema: updatedColumnSchema,
      };
    });
  };

  const deleteOptionValidation = (indx, isOption = false) => {
    let tmp = dataTableSchema.columnSchema[index][isOption ? 'selectMenu' : 'validation'].filter(
      // eslint-disable-next-line eqeqeq
      (emp, index) => indx != index
    );
    let tmp1 = dataTableSchema;
    tmp1.columnSchema[index][isOption ? 'selectMenu' : 'validation'] = tmp;
    setDataTableSchema(tmp1);
    // setUpdate((prev) => prev + 1);
    // setUpdateDataTable((prev) => prev + 1);
  };

  // useEffect(() => {
  //   // setColumnSchema(dataTableSchema.columnSchema[index]);
  // }, [dataTableSchema.columnSchema, index]);

  if (dataTableSchema.columnSchema[index].dataType === 'belongsTo') {
    return <></>;
  }
  return (
    <div id={`edit_card_${index}`} data-bs-parent='#kt_accordion_1'>
      <div>
        <form className='fv-row mb-10'>
          <div className='row px-4 pb-4'>
            <div className='col-6'>
              <label className='form-label fs-6 fw-bolder text-dark'>{mls('Display name')}</label>
              <input
                placeholder='Display name'
                value={dataTableSchema.columnSchema[index].name}
                onChange={(e) => handleChangeColumnLabel(e, index)}
                // onBlur={revertToOriginalColumnName}
                className={clsx('form-control form-control-lg form-control-solid')}
                type='text'
                name='name'
                autoComplete='off'
                style={{ backgroundColor: '#e4e6ef' }}
              />
            </div>

            <div className='col-6'>
              <label className='form-label fs-6 fw-bolder text-dark'>{mls('Column name')}</label>
              <input
                placeholder='Column name'
                value={dataTableSchema.columnSchema[index].columnName}
                onChange={(e) => handleChangeColumnName(e, index)}
                onBlur={revertToOriginalColumnName}
                className={clsx('form-control form-control-lg form-control-solid')}
                type='text'
                name='columnName'
                autoComplete='off'
                style={{ backgroundColor: '#e4e6ef' }}
              />
              {columnNameExist && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {mls('Column name already exists.')}
                    </span>
                  </div>
                </div>
              )}
              {columnNameError && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {mls(columnNameError)}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='row px-4'>
            <div className=' col-6'>
              <label className='form-label fs-6 fw-bolder text-dark'>{mls('Column Type')}</label>
              <select
                name='dataType'
                onChange={handleColTypeChange}
                className='form-select form-control form-control-lg form-control-solid'
                value={dataTableSchema.columnSchema[index].dataType || 'text'}
                style={{ backgroundColor: '#e4e6ef' }}
              >
                <option value='text'>{mls('Text')}</option>
                <option value='number'>{mls('Number')}</option>
                <option value='decimal'>{mls('Decimal')}</option>
                <option value='password'>{mls('Password')}</option>
                <option value='foreignDataSelect'>{mls('Foreign key')}</option>
                <option value='formula'>{mls('Formula')}</option>
                <option value='multipleSelect'>{mls('Multiple')}</option>
                <option value='select'>{mls('Select')}</option>
                <option value='date'>{mls('Date')}</option>
                <option value='time'>{mls('Time')}</option>
                <option value='dateTime'>{mls('Date and Time')}</option>
                <option value='checkbox'>{mls('Checkbox')}</option>
                <option value='switch'>{mls('Switch')}</option>
                <option value='file'>{mls('File')}</option>
                <option value='image'>{mls('Image')}</option>
                <option value='user'>{mls('Add user to the table')}</option>
                <option value='multipleUser'>{mls('Add multiple user to the table')}</option>
                {(!dataTableSchema.dbType || dataTableSchema.dbType === '__d3__supista') && (
                  <>
                    <option value='__d3__CreatedAtDate'>{mls('Create Date')}</option>
                    <option value='__d3__CreatedAtTime'>{mls('Create Time')}</option>
                    <option value='__d3__UpdatedAtDate'>{mls('Update Date')}</option>
                    <option value='__d3__UpdatedAtTime'>{mls('Update Time')}</option>
                  </>
                )}
              </select>
            </div>
          </div>

          {dataTableSchema.columnSchema[index].dataType === 'foreignDataSelect' && (
            <ForeignFieldSection
              currentColumn={dataTableSchema._id}
              setDataTableSchema={setDataTableSchema}
              // currentColumnIndex={dataIndex}
              colIndex={index}
              allDataTableData={allDataTableData}
              dataTableSchema={dataTableSchema}
            />
          )}
          {dataTableSchema.columnSchema[index].dataType === 'formula' && (
            <CalcSection
              currentColumn={dataTableSchema._id}
              setDataTableSchema={setDataTableSchema}
              // currentColumnIndex={dataIndex}
              colIndex={index}
              dataTableSchema={dataTableSchema}
            />
          )}
          {(dataTableSchema.columnSchema[index].dataType === 'multipleSelect') |
          (dataTableSchema.columnSchema[index].dataType === 'select') ? (
            <div
              className='row px-4 py-4'
              style={{ background: '#F5F8FA', marginLeft: 0.5, marginRight: 0.5 }}
            >
              <p style={{ marginLeft: -10 }} className=' mb-5 fs-6 fw-bolder text-dark'>
                Options
              </p>
              {dataTableSchema.columnSchema[index]?.options?.length === 0 && (
                <p className='text-muted'>{mls('No options available.')}</p>
              )}
              {dataTableSchema.columnSchema[index]?.options?.length !== 0 && (
                <div className='row'>
                  <div className='col-6 '>
                    <label className='form-label mx-6 fs-6 fw-bolder text-dark'>
                      {mls('Label')}
                    </label>
                  </div>
                  <div className='col-6 '>
                    <label className='form-label mx-4 fs-6 fw-bolder text-dark'>
                      {mls('Value')}
                    </label>
                  </div>
                </div>
              )}
              {/* {console.log(
                dataTableSchema.columnSchema[index]?.selectMenu,
                index,
                'dataTableSchema.columnSchema[index]?.selectMenu'
              )} */}
              {dataTableSchema.columnSchema[index]?.selectMenu?.map((option, key) => (
                <div className='d-flex justify-content-between mt-1' key={key}>
                  <OptionSection
                    optionKey={key}
                    option={option}
                    deleteOptionValidation={deleteOptionValidation}
                    handleChangOption={handleChangOption}
                  />
                </div>
              ))}
              <button
                className='w-25 mx-2 btn btn-sm btn-primary btn-light-primary mt-3'
                onClick={addOption}
              >
                {mls('Add Option')}
              </button>
            </div>
          ) : (
            <></>
          )}
          <ValidationSection
            columnSchema={dataTableSchema.columnSchema[index]}
            setColumnSchema={(updatedColumnSchema) => {
              setDataTableSchema((prev) => {
                const newColumnSchema = [...prev.columnSchema];
                newColumnSchema[index] = updatedColumnSchema;
                return { ...prev, columnSchema: newColumnSchema };
              });
            }}
            index={index}
            setDataTableSchema={setDataTableSchema}
          />
          <div className='d-flex justify-content-between'>
            <label className='mt-5 ms-10 form-check form-switch form-check-custom form-check-solid'>
              <span className='form-label fs-6 fw-bolder text-dark mx-3 '>{mls('Read-Only')}</span>
              <input
                onChange={(e) => handleReadOnlyChange(e, index)}
                className='form-check-input'
                type='checkbox'
                checked={dataTableSchema.columnSchema[index].readOnly}
              />
            </label>
            {mls(children)}
          </div>
        </form>
      </div>
    </div>
  );
};
