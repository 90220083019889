import clsx from 'clsx';
import React, { FC, useRef, useState } from 'react';
import { KTSVG, toAbsoluteUrl } from '../../../helpers';
import { HeaderNotificationsMenu, HeaderUserMenu, QuickLinks } from '../../../partials';
import { useLayout } from '../../core';
import { supistaApiPost } from '../../../../app/connections/connections';
import { useLocation } from 'react-router-dom';
import { mls } from '../../../../lib/multilanguagesupport';
import { fetchData } from '../../../../utils/fetchAppSchemaFromServer';

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1';

const Topbar = () => {
  const { config } = useLayout();
  const target = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();

  const params = location.pathname.split('/')[1];

  // console.log(params);

  const publishToProduction = () => {
    supistaApiPost(`auth/${params}/publishToProduction`, {
      data: {},
    })
      .then((response) => {
        // console.log(response);
        fetchData(params, true);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      {/* Search */}
      {/* <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
        <Search />
      </div> */}
      {/* Activities */}
      {/* begin::Drawer toggle */}
      {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <div
          className={clsx('btn btn-icon btn-active-light-primary', toolbarButtonHeightClass)}
          id='kt_activities_toggle'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen032.svg'
            className={toolbarButtonIconSizeClass}
          />
        </div> */}
      {/* end::Drawer toggle */}
      {/* </div> */}
      {/* Quick links */}
      {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}> */}
      {/* begin::Menu wrapper */}
      {/* <div
          className={clsx('btn btn-icon btn-active-light-primary', toolbarButtonHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen025.svg'
            className={toolbarButtonIconSizeClass}
          />
        </div>
        <QuickLinks /> */}
      {/* end::Menu wrapper */}
      {/* </div> */}

      {/* CHAT */}
      {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}> */}
      {/* begin::Menu wrapper */}
      {/* <div
          className={clsx(
            'btn btn-icon btn-active-light-primary position-relative',
            toolbarButtonHeightClass
          )}
          id='kt_drawer_chat_toggle'
        >
          <KTSVG
            path='/media/icons/duotune/communication/com012.svg'
            className={toolbarButtonIconSizeClass}
          />

          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span>
        </div> */}
      {/* end::Menu wrapper */}
      {/* </div> */}

      {/* NOTIFICATIONS */}
      {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}> */}
      {/* begin::Menu- wrapper */}
      {/* <div
          className={clsx(
            'btn btn-icon btn-active-light-primary position-relative',
            toolbarButtonHeightClass
          )}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen022.svg'
            className={toolbarButtonIconSizeClass}
          />
        </div>
        <HeaderNotificationsMenu /> */}
      {/* end::Menu wrapper */}
      {/* </div> */}

      <div className='d-flex align-items-center py-1'>
        {/* begin::Wrapper */}
        <div className='me-4'>
          {/* begin::Menu */}
          {/* <a
            href='#'
            className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            {mls('Revert Changes')}
          </a> */}

          {/* end::Menu */}
        </div>
        {/* end::Wrapper */}

        {/* begin::Button */}

        {/* <button className='btn btn-sm btn-primary' onClick={publishToProduction}>
          {mls('Publish Changes')}
        </button> */}
        {/* end::Button */}
      </div>

      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
          ref={target}
          onClick={() => setIsOpen(true)}
        >
          {/* <img src={toAbsoluteUrl('/media/avatars/150-2.jpg')} alt='metronic' /> */}
          <i
            className='bi bi-person-circle text-primary'
            style={{ fontSize: '2.5rem', cursor: 'pointer' }}
          ></i>
        </div>
        <HeaderUserMenu isOpen={isOpen} setIsOpen={setIsOpen} target={target} />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
          </div>
        </div>
      )}
    </div>
  );
};

export { Topbar };
