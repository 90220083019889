import { v5 as uuidv5 } from 'uuid';
export const filterThemeColor = [
  // { borderColor: '#d50000', backgroundColor: '#ffe6d9', textColor: '#d50000' },
  { borderColor: '#e67c73', backgroundColor: '#ffe5de', textColor: '#aa4843' },
  { borderColor: '#B4C9D8', backgroundColor: '#f1faff', textColor: '#009ef7' },
  // { borderColor: '#f4511e', backgroundColor: '#ffe6d6', textColor: '#f4511e' },
  { borderColor: '#f6bf26', backgroundColor: '#ffeeca', textColor: '#a78e5c' },
  { borderColor: '#33b679', backgroundColor: '#d0fbe1', textColor: '#3d7959' },
  { borderColor: '#0b8043', backgroundColor: '#8aeea8', textColor: '#0b8043' },
  { borderColor: '#039be5', backgroundColor: '#d4f4ff', textColor: '#0068ad' },
  { borderColor: '#3f51b5', backgroundColor: '#cad2fe', textColor: '#3f51b5' },
  // { borderColor: '#7986cb', backgroundColor: '#ebeeff', textColor: '#7986cb' },
  { borderColor: '#b0bbff', backgroundColor: '#f4cbff', textColor: '#5363c9' },

  { borderColor: '#8e24aa', backgroundColor: '#eda8ff', textColor: '#8e24aa' },
  { borderColor: '#616161', backgroundColor: '#d6d6d6', textColor: '#616161' },
];
const lastCharHashString = '1b671a64-40d5-491e-99b0-da01ff1f3341';

function getLastCharHashMod(str: string) {
  if (str.length === 0) return 0;
  const hashString = uuidv5(str, lastCharHashString);

  const lastThreeChars = hashString.slice(-3);

  let hashValue = 0;
  for (let i = 0; i < lastThreeChars.length; i++) {
    hashValue += lastThreeChars.charCodeAt(i) * Math.pow(31, i);
  }
  const modValue = hashValue % 10;
  return modValue;
}
export const getThemeColorByValue = ({ value }: { value: string }) => {
  const indexNumber = getLastCharHashMod(value);
  return filterThemeColor[indexNumber] ?? filterThemeColor[0];
};
