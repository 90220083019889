import { useEffect } from 'react';

export const useOutsideClick = ({
  ref,
  onClickOutside = () => {},
}: {
  ref: any;
  onClickOutside: Function;
}) => {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        const { isScrollClick } = scrollCheckFunction(event);
        if (isScrollClick) {
          return;
        }
        onClickOutside();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onClickOutside]);
};

const scrollCheckFunction = (event: any) => {
  const path = event.composedPath();
  const e = event;
  let isScrollClick = false;
  let scrollStart = { x: 0, y: 0 };
  let clickedElement = null;
  for (let element of path) {
    if (element instanceof HTMLElement) {
      const { clientWidth, clientHeight, scrollWidth, scrollHeight } = element;
      const rect = element.getBoundingClientRect();

      // Check for vertical scrollbar
      const verticalScrollbar =
        scrollHeight > clientHeight && e.clientX > rect.right - 17 && e.clientX <= rect.right;

      // Check for horizontal scrollbar
      const horizontalScrollbar =
        scrollWidth > clientWidth && e.clientY > rect.bottom - 17 && e.clientY <= rect.bottom;

      if (verticalScrollbar || horizontalScrollbar) {
        isScrollClick = true;
        break;
      }
    }
  }
  return { isScrollClick, scrollStart, clickedElement };
};
