export const CsvFailed=()=>{
  return(
    <div className='modal-content d-flex justify-content-center align-items-center p-5 bg'>
      <h1 className='mb-5 mt-10'>Upload your CSV file which you use in your business</h1>
      <p>We will use our magic AI to convert it to automate your business Process</p>
      <span className='fs-3 mb-10 mt-10'></span>
      <span className='fs-3 mb-10 mt-10'></span>
      <span className='fs-3 mb-10 mt-10'></span>
      <h2 className="mb-10">Please Upload valid CSV File</h2>
      <button className="btn btn-light mb-7">Try Again</button>
    </div>
  )
}