// import { DateTime } from 'luxon';
// import supportedDateFormats from '../../../../../dependencies/supportedDateFormats';

// const checkDate = (value) => {
//   for(let i=0; i < supportedDateFormats.length; i++) {
//     const dateTimeObj = DateTime.fromFormat(value, supportedDateFormats[i]);
//     console.log('Date check: ', supportedDateFormats[i], value, dateTimeObj.isValid);
//     if(dateTimeObj.isValid) return true;
//   }
//   return false;
// }

// const checkDecimal = (value) => {
//   if (value === '') {
//     return false;
//   }
//   let val = value.replace(',', '');
//   let decimalNumberRegex = /^-?\d+\.\d+$/;
//   return decimalNumberRegex.test(val);
// }

// export const checkNumber = (value) => {
//   if (value === '') {
//     return false;
//   }
//   let val = value.replace(',', '');
//   let numberRegex = /^-?\d+$/;
//   return numberRegex.test(val);
// }

function isValidDate(value) {
  // Check if the value contains any alphabetic characters
  const containsAlphabet = /[a-zA-Z]/.test(value);
  if (containsAlphabet) {
    return false;
  }
  const date = new Date(value);
  return !isNaN(date.getTime());
}

function isValidInteger(value) {
  const intRegex = /^-?\d+$/;
  return intRegex.test(value);
}

function isValidDecimal(value) {
  const decimalRegex = /^-?\d*\.\d+$/;
  return decimalRegex.test(value);
}

export const columnTypeChecker = (data) => {
  let res = [];
  for (let key in data) {    
    if (isValidDecimal(data[key])) {
      res.push({ name: key, dataType: 'decimal', columnName: key.replace(' ', '') });
    } else if (isValidInteger(data[key])) {
      res.push({ name: key, dataType: 'number', columnName: key.replace(' ', '') });
    } else if (isValidDate(data[key])) {
      res.push({ name: key, dataType: 'datePicker', columnName: key.replace(' ', '') });
    } else {
      res.push({ name: key, dataType: 'text', columnName: key.replace(' ', '') });
    }
  }
  
  return res;
};
