import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';

import { mls } from 'lib/multilanguagesupport';

import { useHistory, useLocation } from 'react-router-dom';

const DeleteTable = ({ setDeleteOpen, deleteOpen, handleDeleteTable, tableID }) => {
  const [joinTablesWhereThisTableExists, setJoinTableWhereThisTableExists] = useState([]);
  const appJointable = useSelector((state) => state.config_store.appSchema.appJointable);

  const location = useLocation();
  const history = useHistory();
  const appID = location.pathname.split('/')[1];

  // Find if the data table selected for deletion is a part of any joined table
  useEffect(() => {
    const joinTablesWhereExists = [];
    appJointable && Object.values(appJointable).forEach((joinedTable) => {
      if(joinedTable.tables.includes(tableID)) {
        joinTablesWhereExists.push(joinedTable.name)
      }
    });
    setJoinTableWhereThisTableExists(joinTablesWhereExists);
  }, [appJointable, deleteOpen, tableID]);
  
  if(joinTablesWhereThisTableExists.length > 0) {
    return (
      <Modal open={deleteOpen} onClose={() => setDeleteOpen(false)}>
      <div className='modal-dialog modal-dialog-centered mw-600px'>
        <div className='modal-content d-flex justify-content-center align-items-center p-5 mx-auto' style={{ maxWidth: '90vw' }}>
          <div className="alert alert-danger w-100 text-center">
            {mls("Cannot delete this data table")}
          </div>
          <div className="justify-content-start w-100 p-5">
            <p className='justify-start'>This data table is a part of the following joined table{joinTablesWhereThisTableExists.length > 1 && 's'}:</p>
            <ul>
              {joinTablesWhereThisTableExists.map((name) => {
                return <li>{name}</li>
              })}
            </ul>
          </div>
          <div className='d-flex'>
            <button
              data-bs-dismiss='modal'
              onClick={() => history.push(`/${appID}/join-tables`)}
              style={{ marginTop: 0 }}
              className='btn btn-sm mb-2 btn-primary mx-4'
            >
              {mls('Go to Join Tables')}
            </button>
            <button
              onClick={() => setDeleteOpen(false)}
              style={{ marginTop: 0 }}
              className='btn btn-sm mb-2 btn-primary btn-light-primary mx-4'
            >
              {mls('Cancel')}
            </button>
          </div>
        </div>
      </div>
    </Modal>
    )
  }
  
  return (
    <Modal open={deleteOpen} onClose={() => setDeleteOpen(false)}>
      <div className='modal-dialog modal-dialog-centered mw-600px'>
        <div className='modal-content d-flex justify-content-center align-items-center p-5 mx-auto' style={{ maxWidth: '90vw' }}>
          <p className='p-5 mb-7 fs-4 fw-bold'>{mls("Are you sure you want to delete this table?")}</p>
          <div className='d-flex'>
            <button
              data-bs-dismiss='modal'
              onClick={handleDeleteTable}
              style={{ marginTop: -15 }}
              className='btn btn-sm mb-2 btn-danger btn-light-primary mx-4'
            >
              {mls('Delete table')}
            </button>
            <button
              onClick={() => setDeleteOpen(false)}
              style={{ marginTop: -15 }}
              className='btn btn-sm mb-2 btn-primary btn-light-primary mx-4'
            >
              {mls('Cancel')}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export { DeleteTable };
