import React, { useState } from 'react';
import styles from './myCalendar.module.scss';
import CheckBox from 'lib/reusable-components/Components/CheckBox/CheckBox';
import { mls } from 'lib/multilanguagesupport';
// import { Accordion } from 'react-bootstrap';
// import { Form } from 'react-bootstrap';
import ObjectDropDown from 'lib/reusable-components/Components/DropDowns/ObjectDropDown';

const FilterSection = ({
  filterList,
  filterSchema,
  addFilter,
  removeFilter,
  setFilterSchema,
  selectedFilterColumn,
  setSelectedFilterColumn,
}: {
  filterList: any;
  filterSchema: any;
  addFilter: Function;
  removeFilter: Function;
  setFilterSchema: Function;
  selectedFilterColumn: string;
  setSelectedFilterColumn: Function;
}) => {
  const filterListElem = filterList[selectedFilterColumn];
  const [isClearFilters, setIsClearFilters] = useState(false);
  const handleSelectedFilter = (columnName: string) => {
    const finalFilterData = defaultFilterSchema({ filterList: filterList, columnName: columnName });
    setIsClearFilters(false);
    setSelectedFilterColumn(columnName);
    setFilterSchema(finalFilterData);
  };
  const clearFilters = () => {
    setIsClearFilters(true);
    setFilterSchema({});
  };
  return (
    <div className={styles.filterSelectionOuter}>
      <div className={styles.filterSelectionheading}>
        <h2>{mls('Filter')}</h2>
        <div>
          <div className={'linkButton'} onClick={() => clearFilters()}>
            {mls('Clear Filters')}
          </div>
          &nbsp;
        </div>
      </div>
      <div className={styles.filterSelectionDropDown}>
        <ObjectDropDown
          object={filterList ?? {}}
          dropDownValue={selectedFilterColumn}
          nameKey={'columnName'}
          mainKey={'columnName'}
          ObjectInObject
          setdropDownValue={handleSelectedFilter}
        />
      </div>
      <div className={styles.filterSelectionContainer}>
        {React.Children.toArray(
          (filterListElem?.values ?? []).map((valueElem: any) => {
            const checked = (filterSchema?.[filterListElem?.columnName]?.in ?? []).some(
              (valueString: string) => valueString === valueElem?.value
            );
            const handleCheckSelectColumn = (checked: boolean) => {
              if (checked) {
                addFilter({
                  columnName: filterListElem?.columnName,
                  value: valueElem?.value,
                });
              } else {
                removeFilter({
                  columnName: filterListElem?.columnName,
                  value: valueElem?.value,
                });
              }
              setIsClearFilters(false);
            };

            return (
              <div
                className={styles.filterSelectionElem}
                onClick={() => {
                  handleCheckSelectColumn(!checked);
                }}
                style={
                  {
                    // border: `1px solid ${valueElem?.borderColor}`,
                    // color: valueElem?.textColor,
                    // background: valueElem?.backgroundColor,
                  }
                }
              >
                <CheckBox
                  checked={isClearFilters ? !checked : checked}
                  checkBoxSize={'mediumCheckBox'}
                  onClick={handleCheckSelectColumn}
                  checkBoxCheckedColor={valueElem?.borderColor ?? ''}
                  // checkBoxUncheckedColor={valueElem?.borderColor ?? ''}
                  // checkBoxCheckedColor={valueElem?.borderColor ?? ''}
                  checkBoxBorderUncheckedColor={valueElem?.borderColor ?? ''}
                />
                {valueElem?.value}
              </div>
            );
          })
        )}
        {/*         
        <Accordion>
          {React.Children.toArray(
            Object.keys(filterList ?? {}).map((filterListKey) => {
              const filterListElem = filterList[filterListKey];
              return (
                <Accordion.Item
                  eventKey={filterListElem?.columnName}
                  className='selectedColumnAccordion'
                >
                  <Accordion.Button style={{ padding: '.5rem 1rem', cursor: 'pointer' }}>
                    <Form.Label column sm={11} style={{ cursor: 'pointer' }}>
                      {filterListElem?.columnName}:
                    </Form.Label>
                  </Accordion.Button>
                  <Accordion.Body style={{ padding: '.5rem 1rem' }}>
                    {React.Children.toArray(
                      (filterListElem?.values ?? []).map((valueElem: any) => {
                        const checked = (filterSchema?.[filterListElem?.columnName]?.in ?? []).some(
                          (valueString: string) => valueString === valueElem?.value
                        );
                        const handleCheckSelectColumn = (checked: boolean) => {
                          if (checked) {
                            addFilter({
                              columnName: filterListElem?.columnName,
                              value: valueElem?.value,
                            });
                          } else {
                            removeFilter({
                              columnName: filterListElem?.columnName,
                              value: valueElem?.value,
                            });
                          }
                        };

                        return (
                          <div
                            className={styles.filterSelectionElem}
                            onClick={() => {
                              handleCheckSelectColumn(!checked);
                            }}
                            style={
                              {
                                // border: `1px solid ${valueElem?.borderColor}`,
                                // color: valueElem?.textColor,
                                // background: valueElem?.backgroundColor,
                              }
                            }
                          >
                            <CheckBox
                              checked={checked}
                              checkBoxSize={'mediumCheckBox'}
                              onClick={handleCheckSelectColumn}
                              checkBoxCheckedColor={valueElem?.borderColor ?? ''}
                              // checkBoxUncheckedColor={valueElem?.borderColor ?? ''}
                              // checkBoxCheckedColor={valueElem?.borderColor ?? ''}
                              checkBoxBorderUncheckedColor={valueElem?.borderColor ?? ''}
                            />
                            {valueElem?.value}
                          </div>
                        );
                      })
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              );
            })
          )}
        </Accordion> */}
      </div>
    </div>
  );
};

export default FilterSection;

const defaultFilterSchema = ({
  filterList,
  columnName,
}: {
  filterList: any;
  columnName: string;
}) => {
  const firstFilterListData = filterList?.[columnName] ?? {};
  if (firstFilterListData) {
    let filterValues: string[] = [];
    (firstFilterListData?.values ?? []).forEach((valueElem: any, i: number) => {
      if (i < 5) {
        filterValues = [...filterValues, valueElem?.value ?? ''];
      }
    });
    return { [firstFilterListData?.columnName]: { in: filterValues } };
  }
  return {};
};
