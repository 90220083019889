import { mls } from 'lib/multilanguagesupport';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import { create } from 'lib/reusable-components/reusableUtils/redux/stateSlice';
import { Alert } from 'react-bootstrap-v5';
import { useDispatch } from 'react-redux';

function MessageHandling() {
  const dispatch = useDispatch();
  const toastData = useStoreSchema(
    reduxConstants.STORE_NAME,
    reduxConstants.config.APP_STATUS.TOAST
  );
  const errorToastData = useStoreSchema(
    reduxConstants.STORE_NAME,
    reduxConstants.config.APP_STATUS.ERROR_TOAST
  );
  const returnObj = [];
  if (toastData?.isActive) {
    returnObj.push(
      <Alert
        variant='success'
        className='fixed-bottom w-lg-25 w-sm-75 w-md-50'
        style={{ bottom: 15, left: 15, zIndex: 999999999 }}
      >
        {mls(toastData.message)}
      </Alert>
    );
    setTimeout(() => {
      dispatch(
        create({
          setPath: reduxConstants.config.APP_STATUS.TOAST,
          value: {},
        })
      );
    }, (toastData?.time || 5) * 1000);
  }
  if (errorToastData?.isActive) {
    returnObj.push(
      <Alert
        variant='danger'
        className='fixed-bottom w-lg-25 w-sm-75 w-md-50'
        style={{ bottom: 15, left: 15, zIndex: 999999999 }}
      >
        {mls(errorToastData.message)}
      </Alert>
    );
    setTimeout(() => {
      dispatch(
        create({
          setPath: reduxConstants.config.APP_STATUS.ERROR_TOAST,
          value: {},
        })
      );
    }, (toastData?.time || 5) * 1000);
  }
  return <>{returnObj}</>;
}

export default MessageHandling;
