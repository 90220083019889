/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
// import { Redirect } from 'react-router-dom';
import { mls } from 'lib/multilanguagesupport';
import { create } from 'lib/reusable-components/reusableUtils/redux/stateSlice';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import { supistaTrackingDataSubmitApi } from './network/userActivityTrackingConnection';
import './passwordField.css';
import { OtpForm } from './components/OtpForm';
import { _supistaApiPost } from 'lib/server-connection/connections';
const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
});

const initialValues = {
  email: '',
  password: '',
};

export function Login() {
  const appID = 'supista';
  const history = useHistory();
  const dispatch = useDispatch();
  const [passordShow, setPasswordShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const searchString = (history.location.search || '').split('?')[1] || '';
  const search = searchString.split('&');
  const searchParameters = {};

  search.forEach((parameter) => {
    const key = parameter.split('=');
    searchParameters[key[0]] = parameter.split('=')[1];
  });

  // checking for Demo User Account.
  useState(() => {
    if (searchParameters.loginKey === '78mG8hg*^sa6123j76nJKGJHG') {
      initialValues.email = 'demo@supista.com';
      initialValues.password = 'Supista@AI123';

      supistaTrackingDataSubmitApi({
        type: 'demoUserRegister',
        data: {
          userID: searchParameters.trackUserID,
          UserName: 'Demo User',
          AppName: 'SupistaDemo',
          NavSource: searchParameters.medium,
          ActivityName: 'User has come visited the login link.',
        },
      });
    }
  });
  const [isOtpRequired, setIsOtpRequired] = useState(false);
  const [tempAppData, setTempAppData] = useState({});
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      const data = {
        data: {
          emailID: values.email,
          password: values.password,
        },
      };

      _supistaApiPost(`auth/${appID}/login`, data)
        .then((res) => {
          // console.log(res);
          setLoading(false);
          if (res.hasOwnProperty('__d3__success')) {
            setSubmitting(false);
            if (
              res.response?.authVerification?.email?.active ||
              res.response?.authVerification?.mobile?.active
            ) {
              console.log(res);

              setIsOtpRequired(true);
              setTempAppData(res.response);
            } else {
              dispatch(
                create({
                  setPath: reduxConstants.config.LOGIN,
                  value: { ...res.response, isLoggedIn: res.response?.isLoggedin || false },
                })
              );
              dispatch(
                create({
                  setPath: reduxConstants.config.USER_SCHEMA,
                  value: res.response,
                })
              );
            }
            // dispatch(auth.actions.login('access-token-8f3ae836da744329a6f93bf20594b5cc'));
          } else {
            setSubmitting(false);
            setStatus('The login detail is incorrect');
          }
        })
        .catch(() => {
          setLoading(false);
          setSubmitting(false);
          setStatus('Sorry, looks like there are some errors detected, please try again.');
        });
    },
  });

  if (isOtpRequired) {
    return (
      <OtpForm
        appID={appID}
        setIsOtpRequired={setIsOtpRequired}
        tempAppData={tempAppData}
        setTempAppData={setTempAppData}
      />
    );
  }
  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>{mls('Sign In to Supista')}</h1>
        <div className='text-gray-400 fw-bold fs-4'>
          {mls('New Here')}?{' '}
          <Link to='/auth/registration' className='link-primary fw-bolder'>
            {mls('Create an Account')}
          </Link>
        </div>
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        // <div className='mb-10 bg-light-info p-8 rounded'>
        //   <div className='text-info'>
        //     Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
        //     continue.
        //   </div>
        // </div>
        <></>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>{mls('Email')}</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid'
            // { 'is-invalid': formik.touched.email && formik.errors.email },
            // {
            //   'is-valid': formik.touched.email && !formik.errors.email,
            // }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span className='text-danger' role='alert'>
              {mls(formik.errors.email)}
            </span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>{mls('Password')}</label>
            {/* end::Label */}
            {/* begin::Link */}
            <Link
              to='/auth/forgot-password'
              className='link-primary fs-6 fw-bolder'
              style={{ marginLeft: '5px' }}
            >
              {mls('Forgot Password ?')}
            </Link>
            {/* end::Link */}
          </div>
        </div>
        <div className='password-input-container'>
          <input
            type={passordShow ? 'text' : 'password'}
            autoComplete='off'
            placeholder='Password'
            {...formik.getFieldProps('password')}
            className={clsx('form-control form-control-lg form-control-solid')}
          />
          <i
            className={`bi ${passordShow ? 'bi-eye-slash' : 'bi-eye'} password-toggle`}
            id='togglePassword'
            onClick={() => setPasswordShow(!passordShow)}
          ></i>
        </div>
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span className='text-danger' role='alert'>
                {mls(formik.errors.password)}
              </span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>{mls('Continue')}</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              {mls('Please wait...')}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </form>
  );
}
