import React, { useEffect, useRef, useState } from 'react';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import './dropDown.scss';
import { mls } from 'lib/multilanguagesupport';
import ReactSelectDropDown from './ReactSelectDropDown';
import { FaCaretDown } from 'react-icons/fa';
interface DropDownProps {
  object: any;
  dropDownValue: any;
  disableArray?: any[];
  setdropDownValue: any;
  changeToReset?: boolean;
  className?: string;
  search?: boolean;
  inputPlaceHolder?: string;
  nameKey?: string;
  alternateName?: string | null;
  mainKey?: string;
  isChildernInsert?: boolean;
  ObjectInArray?: boolean;
  ObjectInObject?: boolean;
  children?: any;
  withIndex?: boolean;
  groupDropDown?: boolean;
  returnGroupKeyWithValue?: boolean;
  valueIsKey?: boolean;
  outerContainerRef?: React.RefObject<HTMLDivElement> | null;
  isDropDownFixed?: boolean;
}

const ObjectDropDown = ({
  object,
  dropDownValue,
  setdropDownValue,
  changeToReset = false,
  className = '',
  search = false,
  inputPlaceHolder = '',
  nameKey = 'name',
  alternateName = null,
  mainKey = 'key',
  isChildernInsert = false,
  ObjectInArray = false,
  ObjectInObject = false,
  children = null,
  withIndex = false,
  outerContainerRef = null,
  groupDropDown = false,
  returnGroupKeyWithValue = false,
  valueIsKey = true,
  disableArray = [],
  isDropDownFixed = true,
}: DropDownProps) => {
  // const groupDropDownSample = {
  //   groupKey: {
  //     key: 'groupKey',
  //     title: 'groupTitle',
  //     options: {
  //       optionKey: {
  //         [mainKey]: 'optionKey',
  //         [nameKey]: 'optionTitle',
  //       },
  //     },
  //   },
  // };

  const alternateNameKey = alternateName ?? nameKey;
  const [dropDownData, setdropDownData] = useState({ isOpen: false, top: false, left: false });
  const [filteredData, setFilteredData] = useState(object);

  const defaultSearch = () => {
    if (groupDropDown) {
      let newName = '';
      Object.keys(filteredData).some((groupKey: string) => {
        newName =
          object[groupKey]?.options?.[dropDownValue]?.[nameKey] ??
          object[groupKey]?.options?.[dropDownValue]?.[alternateNameKey] ??
          '';
        // return true;
        return newName;
      });
      return newName || '';
    }
    if (ObjectInObject) {
      return object[dropDownValue]?.[nameKey] ?? object[dropDownValue]?.[alternateNameKey];
    }
    if (valueIsKey) {
      return object[dropDownValue] ?? object[dropDownValue];
    }
    return dropDownValue;
  };
  const [searchInput, setSearchInput] = useState(defaultSearch());

  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleDropDown = () => {
    setdropDownData((currentData) => {
      if (!currentData.isOpen) {
        if (outerContainerRef && outerContainerRef?.current && wrapperRef.current) {
          const finalBottomSpace =
            outerContainerRef.current.scrollHeight - wrapperRef.current.offsetTop;
          const dropDownHeight = 200;
          if (dropDownHeight >= finalBottomSpace) {
            return { ...currentData, isOpen: !currentData.isOpen, top: true };
          } else {
            return { ...currentData, isOpen: !currentData.isOpen, top: false };
          }
        }
      }
      return { ...currentData, isOpen: !currentData.isOpen };
    });
  };
  const stopPropgation = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setdropDownData((currentData) => ({ ...currentData, isOpen: true }));
  };
  interface handleSelectionProps {
    mainKey: any;
    groupKey?: string;
    index: number;
    disable?: boolean;
  }
  const handleSelection = ({
    mainKey,
    index,
    groupKey = '',
    disable = false,
  }: handleSelectionProps) => {
    let dropDownResponse: any;

    if (disable) {
      return;
    }
    dropDownResponse = setdropDownValue(mainKey);
    if (returnGroupKeyWithValue) {
      dropDownResponse = setdropDownValue({ data: mainKey, groupKey });
    }
    if (withIndex) {
      dropDownResponse = setdropDownValue({ data: mainKey, index });
    }
    if (!dropDownResponse?.isError) {
      setFilteredData(object);
      handleDropDown();
      setSearchInput(() => {
        if (groupDropDown) {
          return (
            object[groupKey].options[mainKey]?.[nameKey] ??
            object[groupKey].options[mainKey]?.[alternateNameKey]
          );
        }

        if (ObjectInObject) {
          return object[mainKey]?.[nameKey] ?? object[mainKey]?.[alternateNameKey];
        }
        return mainKey?.[nameKey] ?? mainKey?.[alternateNameKey] ?? mainKey;
      });
    }
  };

  const handleFilter = (value: string) => {
    let keyword = value.toLowerCase();

    if (groupDropDown) {
      let newFilterObject: { [key: string]: any } = {};
      Object.keys(object).forEach((groupKey: string) => {
        const groupValue = object[groupKey];
        if (groupValue?.title !== null) {
          const isTitleFound = groupValue?.title?.toLowerCase()?.includes(keyword);
          if (isTitleFound) {
            newFilterObject = { ...newFilterObject, [groupKey]: groupValue };
            return;
          }
          Object.keys(groupValue.options).forEach((groupValueElemKey: string) => {
            const groupValueElem = groupValue.options[groupValueElemKey];
            if (groupValueElem?.[nameKey] !== null) {
              const groupValueNameFound = groupValueElem?.[nameKey]
                ?.toLowerCase()
                ?.includes(keyword);
              if (groupValueNameFound) {
                newFilterObject = {
                  ...newFilterObject,
                  [groupKey]: {
                    ...groupValue,
                    options: {
                      ...(newFilterObject[groupKey]?.options ?? {}),
                      [groupValueElemKey]: groupValueElem,
                    },
                  },
                };
                return;
              }
            }
            if (groupValueElem?.[alternateNameKey] !== null) {
              const groupValueAlternateNameFound = groupValueElem?.[alternateNameKey]
                ?.toLowerCase()
                ?.includes(keyword);
              if (groupValueAlternateNameFound) {
                newFilterObject = {
                  ...newFilterObject,
                  [groupKey]: {
                    ...groupValue,
                    options: {
                      ...(newFilterObject[groupKey]?.options ?? {}),
                      [groupValueElemKey]: groupValueElem,
                    },
                  },
                };
                return;
              }
            }
          });
        }
      });

      setFilteredData(newFilterObject);
      setSearchInput(value);
      return;
    }
    if (ObjectInObject) {
      const filtered: any = Object.values(object).filter(function (str: any) {
        if (str?.[nameKey] !== null) {
          return str?.[nameKey]?.toLowerCase()?.includes(keyword);
        }
        if (str?.[alternateNameKey] !== null) {
          return str?.[alternateNameKey]?.toLowerCase()?.includes(keyword);
        }
        return str.toLowerCase()?.includes(keyword);
      });
      const nestedObject: any = {};
      for (const obj of filtered) {
        nestedObject[obj?.[mainKey]] = obj;
      }
      setFilteredData(nestedObject);
      setSearchInput(value);
      return;
    }
    // const filtered = object.filter(function (str: any) {
    //   if (str?.[nameKey] !== null) {
    //     return str?.[nameKey].toLowerCase().includes(keyword);
    //   }
    //   return str.toLowerCase().includes(keyword);
    // });
    // setFilteredData(filtered);
  };

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        //NEW
        const scrollCheckFunction = () => {
          const path = event.composedPath();
          const e = event;
          let isScrollClick = false;
          let scrollStart = { x: 0, y: 0 };
          let clickedElement = null;
          for (let element of path) {
            if (element instanceof HTMLElement) {
              const { clientWidth, clientHeight, scrollWidth, scrollHeight } = element;
              const rect = element.getBoundingClientRect();

              // Check for vertical scrollbar
              const verticalScrollbar =
                scrollHeight > clientHeight &&
                e.clientX > rect.right - 17 &&
                e.clientX <= rect.right;

              // Check for horizontal scrollbar
              const horizontalScrollbar =
                scrollWidth > clientWidth &&
                e.clientY > rect.bottom - 17 &&
                e.clientY <= rect.bottom;

              if (verticalScrollbar || horizontalScrollbar) {
                isScrollClick = true;
                break;
              }
            }
          }
          return { isScrollClick, scrollStart, clickedElement };
        };
        const { isScrollClick } = scrollCheckFunction();
        if (isScrollClick) {
          return;
        }
        //NEW
        setSearchInput(defaultSearch());
        setFilteredData(object);
        handleDropDown();
      }
    }
    // if (wrapperRef.current) {
    //   setDropDownWidth(wrapperRef?.current?.getBoundingClientRect()?.width ?? 0);
    // }
    if (dropDownData.isOpen) {
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRef, dropDownData]);

  const isSelected = (element: any, dropdown: any) => {
    const isDisable = disableArray.some((disableKey) => {
      return element?.[mainKey] ?? element === disableKey;
    });

    if (!isNaN(Number(element)) && !isNaN(Number(dropdown))) {
      return {
        className: `dropDownItems ${Number(element) === Number(dropdown) ? ' selected' : ''} ${
          isDisable ? 'disableDownItems' : ''
        }`,
        isDisable,
      };
    }
    return {
      className: `dropDownItems ${element === dropdown ? ' selected' : ''} ${
        isDisable ? 'disableDownItems' : ''
      }`,
      isDisable,
    };
  };

  useUpdateEffect(() => {
    setSearchInput(defaultSearch());
  }, [changeToReset]);
  useUpdateEffect(() => {
    setFilteredData(object);
    setSearchInput(defaultSearch());
  }, [object]);
  useUpdateEffect(() => {
    setSearchInput(defaultSearch());
  }, [dropDownValue]);
  const handleKeyDown = (e: any) => {
    if (e.key === 'Backspace') {
      let str = searchInput ?? '';
      str = str.substring(0, str.length - 1);
      handleFilter(str);
    }
  };
  const dropDownOption = [
    {
      value: 'chocolate',
      label: 'Chocolate',
      filteredData,
      groupDropDown,
      isSelected,
      handleSelection,
      nameKey,
      alternateNameKey,
      dropDownValue,
      ObjectInObject,
      object,
      isDropDownFixed,
      //More
      handleDropDown,
      isChildernInsert,
      children,
      search,
      inputPlaceHolder,
      searchInput,
      stopPropgation,
      handleFilter,
      defaultSearch,
      handleKeyDown,
      //Extra
      dropDownData,
      CustomOption,
      ValueContainer,
    },
  ];

  return (
    <div className={`CustomDropdown ${className}`} ref={wrapperRef}>
      <ReactSelectDropDown options={dropDownOption} />
      {isDropDownFixed === false ? (
        <div
          className={`dropDownArray ${dropDownData.isOpen ? 'open' : ''} ${
            dropDownData.top ? 'upperDropDown' : ''
          }`}
        >
          <CustomOption options={dropDownOption} />
        </div>
      ) : null}
    </div>
  );
};
export default ObjectDropDown;

interface groupDropDownProps {
  filteredData: any;
  isSelected: Function;
  handleSelection: Function;
  dropDownValue: any;
  nameKey: string;
  alternateNameKey: string;
}
const GroupDropDown = (groupDropDownProps: groupDropDownProps) => {
  const { filteredData, isSelected, handleSelection, dropDownValue, nameKey, alternateNameKey } =
    groupDropDownProps;
  return (
    <div className='nestedDropDownGroupWrapperOuter'>
      {React.Children.toArray(
        Object.keys(filteredData).map((groupKey) => (
          <GroupDropDownInner
            groupKey={groupKey}
            filteredData={filteredData}
            isSelected={isSelected}
            handleSelection={handleSelection}
            dropDownValue={dropDownValue}
            nameKey={nameKey}
            alternateNameKey={alternateNameKey}
          />
        ))
      )}
    </div>
  );
};

interface groupInnerDropDownProps {
  filteredData: any;
  isSelected: Function;
  handleSelection: Function;
  dropDownValue: any;
  nameKey: string;
  alternateNameKey: string;
  groupKey: string;
}
const GroupDropDownInner = (groupInnerDropDownProps: groupInnerDropDownProps) => {
  const {
    filteredData,
    isSelected,
    handleSelection,
    dropDownValue,
    nameKey,
    alternateNameKey,
    groupKey,
  } = groupInnerDropDownProps;
  const [isOpen, setIsOpen] = useState(true);
  const handleDivOpen = () => {
    setIsOpen((currentData) => !currentData);
  };
  return (
    <div
      className={`nestedDropDownGroupWrapper ${isOpen ? 'open' : ''}`}
      title={filteredData[groupKey]?.title}
    >
      <div className='nestedDropDownGroupTitle' onClick={() => handleDivOpen()}>
        <div className='groupDropDownTitle'>{mls(filteredData[groupKey]?.title)}</div>
        <div className='groupDropDownTitleArrow'>
          {/* <IoIosArrowDown /> */}
          <FaCaretDown />
        </div>
      </div>
      <div className='nestedItemsContainer'>
        {React.Children.toArray(
          Object.keys(filteredData[groupKey]?.options ?? {}).map((optionsKey, index) => {
            const classData = isSelected(optionsKey, dropDownValue);
            return (
              <div
                className={classData.className}
                onClick={() =>
                  handleSelection({
                    mainKey: optionsKey,
                    index: index,
                    groupKey,
                    disable: classData.isDisable,
                  })
                }
                title={
                  filteredData[groupKey].options?.[optionsKey]?.[nameKey] ??
                  filteredData[groupKey].options?.[optionsKey]?.[alternateNameKey] ??
                  ''
                }
              >
                {filteredData[groupKey].options?.[optionsKey]?.[nameKey] ??
                  filteredData[groupKey].options?.[optionsKey]?.[alternateNameKey] ??
                  ''}
                {/* {stringToBold(filteredData[groupKey].options[optionsKey]?.name)} */}
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

const CustomOption = (props: any) => {
  const { options, innerProps } = props;

  const optionData = options[0];
  const {
    groupDropDown,
    filteredData,
    isSelected,
    handleSelection,
    nameKey,
    alternateNameKey,
    dropDownValue,
    ObjectInObject,
    object,
  } = optionData;

  return (
    <div {...innerProps}>
      <div>
        <>
          {groupDropDown ? (
            <GroupDropDown
              filteredData={filteredData}
              isSelected={isSelected}
              handleSelection={handleSelection}
              dropDownValue={dropDownValue}
              nameKey={nameKey}
              alternateNameKey={alternateNameKey}
            />
          ) : (
            <>
              {React.Children.toArray(
                Object.keys(filteredData).map((key: any, index: number) => {
                  return (
                    <div
                      className={isSelected(key, dropDownValue).className}
                      onClick={() => handleSelection({ mainKey: key, index })}
                      title={
                        ObjectInObject
                          ? object[key]?.[nameKey] ?? object[key]?.[alternateNameKey]
                          : object[key]
                      }
                    >
                      {ObjectInObject
                        ? object[key]?.[nameKey] ?? object[key]?.[alternateNameKey]
                        : object[key]}
                    </div>
                  );
                })
              )}
            </>
          )}
          {Object.keys(filteredData).length === 0 ? (
            <div className='dropDownItems' style={{ cursor: 'auto' }}>
              No Options
            </div>
          ) : null}
        </>
        {/* </div> */}
      </div>
    </div>
  );
};

const ValueContainer = (props: any) => {
  const { options } = props;
  const optionData = options[0];
  const {
    handleDropDown,
    isChildernInsert,
    children,
    search,
    inputPlaceHolder,
    searchInput,
    stopPropgation,
    handleFilter,
    defaultSearch,
    dropDownData,
    handleKeyDown,
  } = optionData;

  return (
    <div style={{ width: '100%' }}>
      <div
        className={search ? 'mainTitle searchDiv' : 'mainTitle'}
        onClick={() => {
          handleDropDown();
        }}
        onTouchEnd={() => {
          handleDropDown();
        }}
      >
        {isChildernInsert ? (
          <>{children}</>
        ) : (
          <>
            {search ? (
              <input
                className='searchInput'
                type='text'
                placeholder={inputPlaceHolder}
                value={searchInput}
                onClick={(e) => stopPropgation(e)}
                onKeyDown={(e) => handleKeyDown(e)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleFilter(e.target.value);
                }}
              />
            ) : (
              <div className='textDiv' title={defaultSearch()}>
                {defaultSearch() || inputPlaceHolder}
              </div>
            )}
            <div className={dropDownData.isOpen ? `dropDownarrow open` : 'dropDownarrow'}>
              {/* <IoIosArrowDown /> */}
              <FaCaretDown />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
