import { useState } from 'react';
import { KTSVG } from '_metronic/helpers';
// import ConfirmDeleteModal from './ConfirmDeleteModal';
// import { useHistory, useLocation } from 'react-router-dom';

import ConfirmDeleteModal from 'app/pages/sharable/ConfirmDeleteModal';

import * as editPageConstants from '../constants';

export const DragAndDropCard = ({
  index,
  name,
  description,
  activePageInEditing,
  onClick = () => {},
  represents,
  formik,
  providedDrag,
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);

  const handleDelete = (index) => {
    if(represents === editPageConstants.SCREEN_GROUP) {
      const screenGroups = global.structuredClone(formik.values?.screenGroup);
      screenGroups.splice(index, 1);
      formik.setFieldValue('screenGroup', screenGroups);
    }
    if(represents === editPageConstants.SCREEN) {
      const screens = global.structuredClone(formik.values?.screenGroup?.[activePageInEditing.screenGroupIndex]?.screen);
      screens.splice(index, 1);
      formik.setFieldValue(`screenGroup.${activePageInEditing.screenGroupIndex}.screen`, screens);
    }

    setIsDeleteModalOpen(false);
    setDeleteIndex(null);
  }

  return (
    <>
    <ConfirmDeleteModal
      what={represents}
      isDeleteModalOpen={isDeleteModalOpen}
      setIsDeleteModalOpen={setIsDeleteModalOpen}
      handleDelete={() => { handleDelete(deleteIndex) }}
    />
    <label
      ref={providedDrag.innerRef}
      key={index}
      className='d-flex flex-stack mb-5 cursor-pointer col-12'
      {...providedDrag.draggableProps}
    >
      <i
        {...providedDrag.dragHandleProps}
        style={{ marginRight: 10 }}
        className='bi fs-3 bi-grip-horizontal mt-1'
      ></i>
      <span onClick={onClick} className='d-flex align-items-center justify-content-between me-2 w-100'>
        <div className='d-flex align-items-center justify-content-between me-2'>
          <span className='symbol symbol-50px me-6'>
            <span className='symbol-label bg-light-primary'>
              <KTSVG
                path='/media/icons/duotune/maps/map004.svg'
                className='svg-icon-1 svg-icon-primary'
              />
            </span>
          </span>

          <span className='d-flex flex-column'>
            <span className='fw-bolder fs-6'>{name}</span>
            <span className='fs-7 text-muted'>{description}</span>
          </span>
        </div>
        {/* If components drag and drop, show link to edit teh component */}
        {represents === editPageConstants.COMPONENT
        ?
        // + Add button to open the components page with the edit modal open of this particular component
        null
        :
        <i
            onClick={(e) => {
              e.stopPropagation();
              setDeleteIndex(index);
              setIsDeleteModalOpen(true);
            }}
            className='fas fa-trash-alt text-danger'
            style={{ fontSize: 15, cursor: 'pointer' }}
          ></i>
        }
        
        {/* } */}
        {/* If last (on screen page), display the go to component buttons */}
        {/* {last &&
          <i
          onClick={(e) => {
            e.stopPropagation();
            history.push({
              pathname: `/${appID}/components`,
              state: data
            });
          }}
          className='bi bi-arrow-right-circle text-primary'
          style={{ fontSize: 20, cursor: 'pointer' }}
        ></i>    
        } */}
      </span>
    </label>
    </>
  );
};
