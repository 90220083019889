import { mls } from "lib/multilanguagesupport";

import { ProjectForm, ScreenGroupForm, ScreenForm } from './EditProject';

import * as editPageConstants from '../constants';

const ProjectEdit = ({
  formik,
  activePageInEditing,
  setActivePageInEditing,
  setIsDeleteModalOpen,
}) => {
  return (
    <>
      <div className='col-8 h-100 px-5' style={{ overflow: 'scroll' }}>
        <div className='bg-white p-5 px-8' style={{ minHeight: '100%' }}>

          <div className='d-flex align-items-center justify-content-end my-1'>
            <button className='btn btn-sm btn-light-danger mx-3' onClick={() => setIsDeleteModalOpen(true)}>
              <i className='fa fa-trash'></i>
            </button>
            <button className='btn btn-sm btn-light-primary' onClick={() => {formik.submitForm()}}>
              {mls('Save')} 
            </button>
          </div>

          <div className='menu-item bg-white'
          // style={{ position: 'sticky', top: 45 }}
          >
            <div className='menu-content mt-5 px-0'>
              <h1 className='menu-section'>{formik.values?.isNewProject ? 'Create' : 'Edit'} {activePageInEditing.activePage}</h1>
            </div>
          </div>

          {activePageInEditing.activePage === editPageConstants.PROJECT
          &&
          <ProjectForm
            formik={formik}
            activePageInEditing={activePageInEditing}
            setActivePageInEditing={setActivePageInEditing}
          />
          }
          {activePageInEditing.activePage === editPageConstants.SCREEN_GROUP
          &&
          <ScreenGroupForm
            formik={formik}
            activePageInEditing={activePageInEditing}
            setActivePageInEditing={setActivePageInEditing}
          />
          }
          {activePageInEditing.activePage === editPageConstants.SCREEN
          &&
          <ScreenForm
            formik={formik}
            activePageInEditing={activePageInEditing}
            setActivePageInEditing={setActivePageInEditing}
          />
          }          
        </div>
      </div>
    </>
  );
};

export default ProjectEdit;
