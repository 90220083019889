import React, { useState } from 'react';
import styles from '../ComponentSettingWrapper.module.scss';
import SlideSwitch from 'lib/reusable-components/Components/SlideSwitch/SlideSwitch';
import { mls } from 'lib/multilanguagesupport';
import { tableDataToRelatedTableData } from './PDFTemplates/helperFuntion';
import PopUpDivChild from 'lib/reusable-components/PopUpDivChild/PopUpDivChild';

interface mainConnectedTableSelectionProps {
  connectedTablesData: { [key: string]: any };
  componentSchema: any;
  setRelatedTableList: Function;
  relatedTableList: { [key: string]: any }[];
}

const MainConnectedTableSelection = ({
  connectedTablesData,
  componentSchema,
  relatedTableList,
  setRelatedTableList,
}: mainConnectedTableSelectionProps) => {
  const [isSelectedTablePopup, setIsSelectedTablePopup] = useState(false);
  const handleIsSelectedTablePopup = () => {
    setIsSelectedTablePopup(!isSelectedTablePopup);
  };
  return (
    <div className={styles.tablesSelectionOuterContainer}>
      <div className={styles.tablesSelectionTitle}>{mls('Choose Connected Tables')}:</div>
      <button
        className='primaryButton secondaryButtonColor'
        onClick={() => handleIsSelectedTablePopup()}
      >
        {mls('Select Tables')}
      </button>
      {isSelectedTablePopup ? (
        <PopUpDivChild
          initialValue={isSelectedTablePopup}
          setPopUp={handleIsSelectedTablePopup}
          popupTitle={mls('Connected Tables')}
        >
          <div className={styles.tablesSelectionContainer}>
            {React.Children.toArray(
              Object.keys(connectedTablesData).map((connectedTableKey) => {
                const connectedTableFinalData =
                  connectedTablesData[connectedTableKey as keyof typeof connectedTablesData];
                const isSelected = Object.keys(relatedTableList ?? {}).some(
                  (connectedTableElem: string) => connectedTableElem === connectedTableKey
                );
                const handleSelection = () => {
                  if (componentSchema?.table === connectedTableKey) {
                    return;
                  }
                  let copyData = { ...relatedTableList };
                  if (isSelected) {
                    delete copyData[connectedTableKey as keyof typeof copyData];
                  } else {
                    copyData = {
                      ...copyData,
                      [connectedTableKey]: tableDataToRelatedTableData({
                        tableData: connectedTablesData[connectedTableKey],
                      }),
                    };
                  }
                  setRelatedTableList(copyData);
                };
                if (componentSchema?.table === connectedTableKey) {
                  return <></>;
                }
                return (
                  <div
                    className={`${styles.columnSelectionDiv} ${
                      componentSchema?.table === connectedTableKey
                        ? styles.columnSelectionDivDisable
                        : ''
                    }`}
                    onClick={() => handleSelection()}
                  >
                    <SlideSwitch
                      isChecked={isSelected}
                      setIsChecked={handleSelection}
                      selectedDisable={componentSchema?.table === connectedTableKey}
                    />
                    {connectedTableFinalData?.name ?? connectedTableFinalData?.tableID}
                  </div>
                );
              })
            )}
          </div>
        </PopUpDivChild>
      ) : null}
    </div>
  );
};

export default MainConnectedTableSelection;
