import { useDispatch } from 'react-redux';
import { create } from '../redux/stateSlice';
import reduxConstants from '../redux/reduxConstants';



export default function useUpdateProjectsSchema() {
  const dispatch = useDispatch();
  /**
   * newProjects - the updated projects object
   */
  return (newProjects) => {
    // const projects = store.getState()[reduxConstants.STORE_NAME][reduxConstants.config.APP_SCHEMA]['app']['projects'];
    dispatch(create({
      setPath: `${reduxConstants.config.APP_SCHEMA}.app.projects`,
      value: newProjects,
    }));
};
}