import { Editor } from '@monaco-editor/react';
import { mls } from 'lib/multilanguagesupport';
import PopUpDivChild from 'lib/reusable-components/PopUpDivChild/PopUpDivChild';
import React, { useEffect, useRef } from 'react';

interface sampleDataPopupProps {
  isSampleDataPopup: boolean;
  setIsSampleDataPopup: Function;
  sampleData: any;
}
const SampleDataPopup = ({
  isSampleDataPopup,
  setIsSampleDataPopup,
  sampleData,
}: sampleDataPopupProps) => {
  const monacoRef = useRef<any>(null);
  const handleEditorDidMount = (editor: any) => {
    monacoRef.current = editor;

    editor.updateOptions({ readOnly: false });
    editor.setValue(JSON.stringify(sampleData ?? ''));
    editor.getAction('editor.action.formatDocument').run();

    setTimeout(() => {
      editor.updateOptions({ readOnly: true });
    }, 3000);
  };

  useEffect(() => {
    if (monacoRef.current) {
      monacoRef.current.updateOptions({ readOnly: false });
      monacoRef.current.setValue(JSON.stringify(sampleData ?? ''));
      monacoRef?.current?.getAction('editor.action.formatDocument')?.run();
      setTimeout(() => {
        monacoRef.current.updateOptions({ readOnly: true });
      }, 3000);
    }
  }, [sampleData]);
  return (
    <div>
      <PopUpDivChild
        initialValue={isSampleDataPopup}
        setPopUp={setIsSampleDataPopup}
        popupTitle={mls('Sample Data')}
      >
        <Editor
          height={'50vh'}
          defaultLanguage='json'
          value={JSON.stringify(sampleData)}
          onChange={() => {}}
          options={{
            automaticLayout: true,
            formatOnPaste: true,
            formatOnType: true,
            // readOnly: true,
          }}
          onMount={handleEditorDidMount}
          theme='vs-dark'
        />
      </PopUpDivChild>
    </div>
  );
};

export default SampleDataPopup;
