import { mls } from 'lib/multilanguagesupport';
import { arrayMoveImmutable } from 'array-move';
import { v4 as uuid } from 'uuid';

import * as editPageConstants from '../../constants';
import DragAndDrop from '../DragAndDrop';

export const ScreenGroupForm = ({
  formik,
  activePageInEditing,
  setActivePageInEditing
}) => {
  const handleAddScreen = () => {
    const currentScreens = global.structuredClone(formik.values.screenGroup?.[activePageInEditing.screenGroupIndex]?.screen);
    currentScreens.push({
      screenName: '',
      description: '',
      screenID: uuid(),
    });
    formik.setFieldValue(`screenGroup.${activePageInEditing.screenGroupIndex}.screen`, currentScreens);
    setActivePageInEditing((old) => {
      return {
        activePage: editPageConstants.SCREEN,
        screenGroupIndex: old.screenGroupIndex,
        screenIndex: currentScreens.length - 1
      }
    });
  }
  const handleReArrangeScreens = (dragEndDetails) => {
    const reArrangedScreens = arrayMoveImmutable(formik.values.screenGroup?.[activePageInEditing.screenGroupIndex]?.screen,
      dragEndDetails.source.index,
      dragEndDetails.destination.index
    );
    formik.setFieldValue(`screenGroup.${activePageInEditing.screenGroupIndex}.screen`, reArrangedScreens);
  }
  return <>
    <label className='form-label fs-6 fw-bolder text-dark'>{mls('Name')}</label>
    <input
      {...formik.getFieldProps(`screenGroup.${activePageInEditing.screenGroupIndex}.name`)}
      placeholder='Screen group name'
      className='form-control form-control-lg form-control-solid'
    />
    <div className='d-flex justify-content-between px-2'>
      <label className='form-label fs-6 fw-bolder text-dark mt-5'>{mls('Description')}</label>
      <label className='form-label text-muted fs-6 fw-bolder text-dark mt-5'>
        {formik.values?.screenGroup?.[activePageInEditing.screenGroupIndex]?.description?.length || 0}/150
      </label>
    </div>
    <textarea
      {...formik.getFieldProps(`screenGroup.${activePageInEditing.screenGroupIndex}.description`)}
      maxLength={150}
      style={{ resize: 'none', height: 80 }}
      placeholder='Description'
      className='form-control form-control-lg form-control-solid'
    />
    <div className='d-flex justify-content-between px-2'>
      <label className='form-label fs-6 fw-bolder text-dark mt-5'>{mls('Screens')}</label>
      <button onClick={handleAddScreen} className=' btn btn-sm btn-primary btn-light-primary mt-4'>
        {mls('Add screen')}
      </button>
    </div>
    <DragAndDrop
      represents={editPageConstants.SCREEN}
      listForDragAndDrop={formik.values?.screenGroup?.[activePageInEditing.screenGroupIndex]?.screen}
      onDragEnd={handleReArrangeScreens}
      activePageInEditing={activePageInEditing}
      setActivePageInEditing={setActivePageInEditing}
      formik={formik}
    />
  </>
}

export default ScreenGroupForm;