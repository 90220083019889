// const tableUpdateObj = {
//   addColumns: [],
//   updateColumns: [],
//   deleteColumns: [],
//   renameColumns: {},
//   dropTable: false,
//   createTable: true,
// }
export const updateRelatedTableSchema = ({
  allDataTableData,
  appID,
  mainTableName,
  setTableUpdates,
  setRelatedTableList,
  relationData,
  action,
}) => {
  // Get the target table name
  const targetTableName = relationData.target;
  let newColumnSchema;
  let junctionTable;
  // Determine the inverse relation type
  let inverseType;
  // Determine the foreign key, columnSchema and junction-table for the inverse relation
  let inverseForeignKey;

  if (relationData.type === 'hasMany' || relationData.type === 'hasOne') {
    inverseType = 'belongsTo';
    inverseForeignKey = 'id';
    newColumnSchema = [
      {
        name: relationData.foreignKey,
        columnName: relationData.foreignKey,
        dataType: 'belongsTo',
        relationId: relationData.as,
      },
    ];
  } else if (relationData.type === 'belongsTo') {
    inverseType = 'hasMany';
    inverseForeignKey = `__d3id__${targetTableName}Id`;
  } else if (relationData.type === 'belongsToMany') {
    inverseType = 'belongsToMany';
    inverseForeignKey = `__d3id__${targetTableName}Id`;

    junctionTable = {
      tableID: relationData.through,
      appID,
      tableType: 'junction',
      columnSchema: [
        {
          name: relationData.foreignKey,
          columnName: relationData.foreignKey,
          dataType: 'number',
        },
        {
          name: relationData.otherKey,
          columnName: relationData.otherKey,
          dataType: 'number',
        },
      ],
      name: relationData.through,
    };
  }

  // if (inverseType === 'belongsTo') {
  //   inverseForeignKey = 'id';
  // } else if (inverseType === 'belongsToMany') {
  //   inverseForeignKey = `__d3id__${targetTableName}Id`;
  // } else {
  //   inverseForeignKey = `__d3id__${mainTableName}Id`;
  // }

  // Determine the inverse relation key and data
  const inverseRelationKey = relationData.as;
  const inverseRelationData = {
    as: inverseRelationKey,
    type: inverseType,
    foreignKey: inverseForeignKey,
    displayName: relationData.displayName,
    target: mainTableName,
  };

  // If the relation type is belongsToMany, add extra properties
  if (relationData.type === 'belongsToMany') {
    inverseRelationData.through = relationData.through;
    inverseRelationData.otherKey = `__d3id__${mainTableName}Id`;
  }

  // Update the target table schema
  setRelatedTableList((prevSchema) => {
    const targetTableSchema = {
      ...(prevSchema[targetTableName] || allDataTableData[targetTableName]),
    };
    const relations = { ...targetTableSchema.relations };

    if (action === 'add') {
      relations[inverseRelationKey] = inverseRelationData;
      // if (relationData.type === 'hasMany' || relationData.type === 'hasOne') {
      //   setRelatedTableUpdates((prevData) => {
      //     const newUpdates = { ...( prevData[targetTableName] || tableUpdateObj) }
      //     newUpdates['addColumns'] = [...(newUpdates.addColumns || []), newColumnSchema];
      //     return {
      //       ...prevData,
      //       [targetTableName]: newUpdates,
      //     };
      //   });
  
      // }
    } else if (action === 'update') {
      relations[inverseRelationKey] = inverseRelationData;
      // if (relationData.type === 'hasMany' || relationData.type === 'hasOne') {
      //   setRelatedTableUpdates((prevData) => {
      //     const newUpdates = { ...( prevData[targetTableName] || tableUpdateObj) }
      //     newUpdates['updateColumns'] = [...(newUpdates.updateColumns || []), newColumnSchema];
      //     return {
      //       ...prevData,
      //       [targetTableName]: newUpdates,
      //     };
      //   });
  
      // }
    } else if (action === 'remove') {
      delete relations[inverseRelationKey];
    //   if (relationData.type === 'hasMany' || relationData.type === 'hasOne') {

    //   setRelatedTableUpdates((prevData) => {
    //     const newUpdates = { ...( prevData[targetTableName] || tableUpdateObj) }
    //     newUpdates['deleteColumns'] = [...(newUpdates.deleteColumns || []), newColumnSchema];
    //     return {
    //       ...prevData,
    //       [targetTableName]: newUpdates,
    //     };
    //   });
    // }
    }

    targetTableSchema.relations = relations;

    if (relationData?.type === 'hasMany' || relationData?.type === 'hasOne') {
      if (
        !(targetTableSchema?.columnSchema || []).some(
          (obj) => obj.columnName === newColumnSchema[0].columnName
        )
      ) {
        targetTableSchema.columnSchema = [...(targetTableSchema.columnSchema || []), ...newColumnSchema];
      }
    }
    return {
      ...prevSchema,
      [targetTableName]: targetTableSchema,
    };
  });

  if (relationData.type === 'belongsToMany') {
    if (!Object.keys(allDataTableData).includes(junctionTable.name)) {
      setRelatedTableList((prevSchema) => ({
        ...prevSchema,
        [junctionTable.name]: junctionTable,
      }));
    }
  }
};
