import React, { useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';

import styles from '../ComponentSettingWrapper.module.scss';
import { mls } from 'lib/multilanguagesupport';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import AppComponentFilter from '../AppComponentFilter/AppComponentFilter';
import AddTeamsPopup from '../AddTeamsPopup/AddTeamsPopup';
const getColumnSchema = ({
  connectedTableList,
  appDatatable,
}: {
  connectedTableList: string[];
  appDatatable: any;
}) => {
  let allColumnSchema: any[] = [];
  (connectedTableList ?? []).forEach((connectedTablekey) => {
    const connectedTableElem = appDatatable?.[connectedTablekey] ?? {};
    const newColumnSchema = (connectedTableElem?.columnSchema ?? [])?.map(
      (columnSchemaElem: any) => ({
        ...columnSchemaElem,
        tableName: connectedTablekey,
      })
    );
    allColumnSchema = [...allColumnSchema, ...newColumnSchema];
  });
  return allColumnSchema;
};
const QueryContainerPermissionContainer = ({
  componentSchema,
  title,
  setTitle,
  activeButtonKey,
  setComponentSchema,
  errors,
  setErrors,
}: {
  componentSchema: any;
  title: string;
  setTitle: Function;
  activeButtonKey: string;
  setComponentSchema: Function;
  errors: any;
  setErrors: Function;
}) => {
  const appSchema = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.APP_SCHEMA);
  const columnSchema = useMemo(() => {
    return getColumnSchema({
      connectedTableList: componentSchema?.connectedTableList ?? [],
      appDatatable: appSchema?.appDatatable ?? {},
    });
  }, [componentSchema?.connectedTableList, appSchema?.appDatatable]);

  const validateFields = () => {
    const errors: { [key: string]: any } = {};
    if (!title.trim()) {
      errors.title = 'Label is required.';
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const handleLabelChange = (e: any) => {
    setTitle(e.target.value);
  };
  const [displayWhenFilter, setDisplayWhenFilter] = useState([]);
  const [isDisplayWhenPopup, setIsDisplayWhenPopup] = useState(false);
  const [isDisplayToPopup, setIsDisplayToPopup] = useState(false);
  const handleFilterPopup = () => {
    setIsDisplayWhenPopup(!isDisplayWhenPopup);
  };
  const handleDisplayToPopup = () => {
    setIsDisplayToPopup(!isDisplayToPopup);
  };

  const handleSaveActionButtonTitle = () => {
    if (validateFields()) {
      setComponentSchema({
        ...componentSchema,
        actionButton: {
          ...(componentSchema?.actionButton ?? {}),
          [activeButtonKey]: {
            ...(componentSchema?.actionButton?.[activeButtonKey] ?? {}),
            title,
          },
        },
      });
    }
  };
  const handleDisplayWhen = (displayWhen: any) => {
    // setDisplayWhenFilter(displayWhen);
    setComponentSchema({
      ...componentSchema,
      actionButton: {
        ...(componentSchema?.actionButton ?? {}),
        [activeButtonKey]: {
          ...(componentSchema?.actionButton?.[activeButtonKey] ?? {}),
          displayWhen,
        },
      },
    });
  };
  const handleDisplayTo = (displayTo: any) => {
    setComponentSchema({
      ...componentSchema,
      actionButton: {
        ...(componentSchema?.actionButton ?? {}),
        [activeButtonKey]: {
          ...(componentSchema?.actionButton?.[activeButtonKey] ?? {}),
          displayTo,
        },
      },
    });
  };
  return (
    <>
      <div className={styles.queryContainerPermissionOuterContainer}>
        {isDisplayToPopup ? (
          <AddTeamsPopup
            isDisplayToPopup={isDisplayToPopup}
            setIsDisplayToPopup={setIsDisplayToPopup}
            setSelectedTeams={handleDisplayTo}
            selectedTeams={componentSchema?.actionButton?.[activeButtonKey]?.displayTo ?? []}
          />
        ) : null}
        {isDisplayWhenPopup ? (
          <AppComponentFilter
            setChartFilter={handleDisplayWhen}
            allTableData={columnSchema}
            chartFilter={componentSchema?.actionButton?.[activeButtonKey]?.displayWhen ?? {}}
            appID={''}
            sheetID={''}
            isJoinedTable={false}
            isPopUp={isDisplayWhenPopup}
            setIsPopUp={setIsDisplayWhenPopup}
          />
        ) : null}
        <div className={styles.queryContainerPermissionContainer}>
          <Form.Group controlId='actionButtonLabel' className={styles.actionButtonOptions}>
            <Form.Label className={styles.actionButtonOptionsLabel}>
              {mls('Action Name')}:
            </Form.Label>
            <Form.Control
              type='text'
              value={title}
              onChange={handleLabelChange}
              onBlur={() => handleSaveActionButtonTitle()}
              isInvalid={!!errors?.title}
            />
            <Form.Control.Feedback type='invalid'>{errors?.title}</Form.Control.Feedback>
          </Form.Group>
          <div>
            <b>{mls('Display When')}:</b> &nbsp;
            <button
              className='primaryButton secondaryButtonColor'
              onClick={() => handleFilterPopup()}
            >
              {mls('Filters ')}
            </button>
          </div>

          <div>
            <b>{mls('Display to')}:</b> &nbsp;
            <button
              className='primaryButton secondaryButtonColor'
              onClick={() => handleDisplayToPopup()}
            >
              {mls('Select Teams')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default QueryContainerPermissionContainer;
