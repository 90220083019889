import { mls } from 'lib/multilanguagesupport';
export const Card = ({ title, description, index, handleShow }) => {
  return (
    <div
      onClick={handleShow}
      className={`card bgi-no-repeat`}
      style={{
        cursor: 'pointer',
        backgroundPosition: 'right top',
        backgroundSize: '30% auto',
        minHeight: 150,
        // backgroundImage: `url(${toAbsoluteUrl(
        //   `/media/svg/shapes/abstract-${(index + 1) % 5}.svg`
        // )})`,
      }}
    >
      {/* begin::Body */}
      <div className='card-body'>
        <a href='#' className='card-title fw-bolder text-dark-75 text-hover-primary fs-4'>
          {mls(title)}
        </a>

        <p
          className=' fw-bold fs-5 m-0 text-muted'
          dangerouslySetInnerHTML={{ __html: description }}
        ></p>
      </div>
      {/* end::Body */}
    </div>
  );
};
