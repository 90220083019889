import { mls } from 'lib/multilanguagesupport';
import React, { useRef, useState } from 'react';
import { filterThemeColor, getThemeColorByValue } from './getThemeColorByValue';
import styles from './calenderSettings.module.scss';
import { FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useOutsideClick } from 'lib/reusable-components/hooks/useOutsideClick';

interface pickColorSettingsProps {
  columnName: string;
  schema: any;
  handleCalendarSettingChange: Function;
}

const PickColorSettings = ({
  columnName,
  schema,
  handleCalendarSettingChange,
}: pickColorSettingsProps) => {
  const columnElem = schema?.calendarSetting?.filterList[columnName];
  const [filterValue, setFilterValue] = useState('');
  const handleFilterListValues = ({ values }: { values: any[] }) => {
    let filterList = { ...(schema?.calendarSetting?.filterList ?? {}) };
    filterList = {
      ...filterList,
      [columnName]: {
        ...columnElem,
        values: values,
      },
    };
    setFilterValue('');
    handleCalendarSettingChange({ target: { value: filterList, name: 'filterList' } });
  };
  const addValue = (value: string) => {
    if (!value) {
      return;
    }
    const isPresent = (columnElem?.values ?? []).some((valueElem: any) => {
      return valueElem?.value === value;
    });
    if (isPresent) {
      toast.warn(mls(`( ${value} )is Already Present`));
      return;
    }
    const colorData = getThemeColorByValue({ value });
    const newValues = [
      ...(columnElem?.values ?? []),
      {
        value: value,
        borderColor: colorData?.borderColor,
        backgroundColor: colorData?.backgroundColor,
        textColor: colorData?.textColor,
      },
    ];
    handleFilterListValues({ values: newValues });
    setFilterValue('');
  };
  const removeValue = (value: string) => {
    const newValues = (columnElem?.values ?? []).filter((valueElem: any) => {
      return valueElem?.value !== value;
    });
    handleFilterListValues({ values: newValues });
  };
  const handleColor = ({ value, colorData }: { value: string; colorData: any }) => {
    const newValues = (columnElem?.values ?? []).map((valueElem: any) => {
      if (valueElem?.value === value) {
        return {
          ...valueElem,
          borderColor: colorData?.borderColor,
          backgroundColor: colorData?.backgroundColor,
          textColor: colorData?.textColor,
        };
      }
      return valueElem;
    });
    handleFilterListValues({ values: newValues });
  };

  return (
    <div className={styles.pickColorSettings}>
      <h4>{columnElem?.columnName}</h4>
      <div className={styles.filterValuesInputContainer}>
        <input
          type='text'
          className='primaryInput'
          placeholder={mls('Input Filter Value')}
          value={filterValue}
          onChange={(e) => setFilterValue(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              addValue(filterValue);
            }
          }}
        />
        <button
          className={`primaryButton secondaryButtonColor ${styles.addButton}`}
          type='button'
          onClick={() => addValue(filterValue)}
        >
          {mls('Add')}
        </button>
      </div>
      <div className={styles.filterValuesOuterContainer}>
        {React.Children.toArray(
          (columnElem?.values ?? []).map((valueElem: any, index: number) => {
            return (
              <div className={styles.filterValuesContainer}>
                <div className={styles.filterValuesNumber}>{index + 1}.</div>
                <div className={styles.filterValuesTitle}>{valueElem?.value}</div>
                <FilterColorThemeSelector handleColor={handleColor} valueElem={valueElem} />
                <div
                  className={styles.filterValuesRemove}
                  onClick={() => removeValue(valueElem?.value)}
                >
                  <FaTimes />
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default PickColorSettings;

const FilterColorThemeSelector = ({
  handleColor,
  valueElem,
}: {
  handleColor: Function;
  valueElem: any;
}) => {
  const [isThemePopup, setIsThemePopup] = useState(false);
  const openThemePopup = () => {
    setIsThemePopup(true);
  };
  const wrapperRef = useRef<HTMLDivElement>(null);
  useOutsideClick({ ref: wrapperRef, onClickOutside: () => setIsThemePopup(false) });

  const scrollUp = () => {
    if (wrapperRef.current) {
      wrapperRef.current.scrollBy(0, -10); // Scroll up 10 pixels
    }
  };
  const handleOnClick = ({ colorData }: { colorData: any }) => {
    handleColor({
      value: valueElem?.value,
      colorData: colorData,
    });
    setIsThemePopup(false);

    scrollUp();
  };
  const isBorderBackground = true;
  return (
    <div className={styles.filterColorThemeSelector}>
      <div
        className={styles.filterColorThemeSelector}
        style={
          isBorderBackground
            ? {
                border: `1px solid ${valueElem?.borderColor}`,
                color: valueElem?.textColor,
                background: valueElem?.borderColor,
              }
            : {
                border: `1px solid ${valueElem?.borderColor}`,
                color: valueElem?.textColor,
                background: valueElem?.backgroundColor,
              }
        }
        onClick={() => openThemePopup()}
      >
        {/* T */}
      </div>

      {isThemePopup ? (
        <div className={styles.colorThemeContainer} ref={wrapperRef}>
          {React.Children.toArray(
            filterThemeColor.map((filterThemeColorElem: any) => {
              return (
                <div
                  className={`${styles.colorThemeElem} ${styles.selectedColorThemeElem}`}
                  style={
                    isBorderBackground
                      ? {
                          border: `1px solid ${filterThemeColorElem?.borderColor}`,
                          color: filterThemeColorElem?.textColor,
                          background: filterThemeColorElem?.borderColor,
                        }
                      : {
                          border: `1px solid ${filterThemeColorElem?.borderColor}`,
                          color: filterThemeColorElem?.textColor,
                          background: filterThemeColorElem?.backgroundColor,
                        }
                  }
                  onClick={() =>
                    handleOnClick({
                      colorData: filterThemeColorElem,
                    })
                  }
                ></div>
              );
            })
          )}
        </div>
      ) : null}
    </div>
  );
};
