import React, { useState } from 'react';
import styles from './addTeamsPopup.module.scss';
import PopUpDivChild from 'lib/reusable-components/PopUpDivChild/PopUpDivChild';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import { mls } from 'lib/multilanguagesupport';
import CheckBox from 'lib/reusable-components/Components/CheckBox/CheckBox';
import './addTeamsPopup.scss';
import SearchInputDiv from 'lib/reusable-components/Components/SearchInputDiv/SearchInputDiv';
import { defaultTeamAccessObject } from '../data/appComponentData';
const RoleAccessAddTeamsPopup = ({
  isDisplayToPopup,
  setIsDisplayToPopup,
  selectedTeams,
  setSelectedTeams,
  roleAccessSchema,
  setRoleAccessSchema,
}: {
  isDisplayToPopup: boolean;
  setIsDisplayToPopup: Function;
  setSelectedTeams: Function;
  selectedTeams: string[];
  roleAccessSchema: { [key: string]: any };
  setRoleAccessSchema: Function;
}) => {
  const appSchema = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.APP_SCHEMA);
  const appTeams = appSchema?.appErpTeams ?? {};
  const [displayToValues, setDisplayToValues] = useState<string[]>(selectedTeams ?? []);
  const [teamFilter, setTeamFilter] = useState({ search: '' });
  const [filterTeamSchema, setFilterTeamSchema] = useState(appTeams);

  const handleTeamSelection = ({ isSelected, key }: { isSelected: boolean; key: string }) => {
    if (isSelected) {
      const filterdData = (displayToValues ?? []).filter((item) => item !== key);
      setDisplayToValues(filterdData);
      return;
    }
    setDisplayToValues([...displayToValues, key]);
  };
  const handleSearch = (search: string) => {
    setTeamFilter((currentValue) => {
      let filterdData = {};
      Object.keys(appTeams).forEach((appTeamsKey: any) => {
        const appTeamsData = appTeams[appTeamsKey];
        const lowerCaseElemName = appTeamsData.name.toLowerCase();
        const lowerCaseValue = search.toLowerCase();
        if (lowerCaseElemName.includes(lowerCaseValue)) {
          filterdData = { ...filterdData, [appTeamsData.teamID]: appTeamsData };
        }
      });
      setFilterTeamSchema(filterdData);
      return { ...currentValue, search: search };
    });
  };
  const getTeamSchemaObject = ({
    teamKeyArray,
    defaultTeamType,
  }: {
    teamKeyArray: string[];
    defaultTeamType: string;
  }) => {
    let teamObject: { [key: string]: any } = {};
    (teamKeyArray ?? []).forEach((teamsKey: string) => {
      const teamElem = roleAccessSchema[teamsKey];
      if (teamElem) {
        teamObject = { ...teamObject, [teamsKey]: teamElem };
      } else {
        teamObject = {
          ...teamObject,
          [teamsKey]: {
            C: {
              type: defaultTeamType,
              ColumnWiseRoles: {},
            },
            R: {
              type: defaultTeamType,
              ColumnWiseRoles: {},
            },
            U: {
              type: defaultTeamType,
              ColumnWiseRoles: {},
            },
            D: {
              type: defaultTeamType,
              ColumnWiseRoles: {},
            },
          },
        };
      }
    });
    return teamObject;
  };
  const handleClose = () => {
    const teamObject = getTeamSchemaObject({
      teamKeyArray: displayToValues,
      defaultTeamType: defaultTeamAccessObject.type,
    });
    setRoleAccessSchema(teamObject);
    setSelectedTeams(displayToValues);
    setIsDisplayToPopup(false);
  };
  return (
    <div className={styles.addTeamsPopup}>
      {isDisplayToPopup ? (
        <PopUpDivChild
          initialValue={isDisplayToPopup}
          setPopUp={handleClose}
          popupTitle={mls('Select Teams')}
          className={'displayToMultiselect'}
        >
          <div className={styles.addTeamsContainer}>
            <div className={styles.searchBox}>
              <SearchInputDiv value={teamFilter.search} onChange={handleSearch} fullWidth border />
            </div>
            <div className={styles.addTeamsInnerContainer}>
              {React.Children.toArray(
                Object.keys(filterTeamSchema).map((appTeamsKey) => {
                  const appTeamsData = filterTeamSchema[appTeamsKey];
                  const isSelected = (displayToValues ?? []).some((elem) => elem === appTeamsKey);
                  if (appTeamsData?.teamID === 'Admin' || appTeamsData?.teamID === 'Creator') {
                    return <></>;
                  }
                  return (
                    <div
                      className={styles.addTeamsContainerElem}
                      onClick={() => handleTeamSelection({ isSelected, key: appTeamsKey })}
                    >
                      <div className={styles.addTeamsContainerElemCheckBox}>
                        <CheckBox checked={isSelected} onClick={() => {}} />
                      </div>
                      <div className={styles.addTeamsContainerElemDetails}>
                        <div className={styles.addTeamsContainerElemName}>
                          {mls(appTeamsData?.name)}
                        </div>
                        <div className={styles.addTeamsContainerElemDescription}>
                          {mls(appTeamsData?.description)}
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </PopUpDivChild>
      ) : null}
    </div>
  );
};

export default RoleAccessAddTeamsPopup;
