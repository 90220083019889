import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button, Form, ListGroup } from 'react-bootstrap';
import Editor from '@monaco-editor/react';
import { Plus, Play } from 'react-bootstrap-icons';
import styles from '../ComponentSettingWrapper.module.scss';
import { mls } from 'lib/multilanguagesupport';
import QueryContainerPermissionContainer from './QueryContainerPermissionContainer';
import { v4 as uuid } from 'uuid';
import { CircularProgress } from '@mui/material';
import { _supistaApiPost } from 'lib/server-connection/connections';
import QueryGenerator from '../QueryGenerator/QueryGenerator';
import useDivDimensions from 'lib/reusable-components/hooks/divDimensionHelper';
import {
  buttonTypeObject,
  crudTypeButtonObject,
  curdOpType,
  defaultCode,
} from '../data/appComponentData';
import SampleDataPopup from './SampleDataPopup';
const getDefaultCode = ({ componentSchema, actionButtonKey, buttonType }) => {
  if (buttonType === buttonTypeObject.CRUD.key) {
    const crudOpData = componentSchema?.crudOp ?? {};
    if (crudOpData?.[actionButtonKey]?.type === curdOpType.CUSTOM.key) {
      return crudOpData?.[actionButtonKey];
    }
    const functionID = uuid();

    const updatedCrudFunction = {
      fuctionName: 'displayFunc',
      functionID: functionID,
      functionCode: defaultCode,
      params: [],
      type: curdOpType.CUSTOM.key,
    };
    return updatedCrudFunction;
  } else if (buttonType === buttonTypeObject.actionButton.key) {
    const actionButtonData = componentSchema?.actionButton ?? {};
    if (actionButtonData?.[actionButtonKey]) {
      return actionButtonData?.[actionButtonKey];
    }
    const functionID = uuid();
    const updatedCrudFunction = {
      fuctionName: 'actionFunc',
      functionID: functionID,
      functionCode: defaultCode,
      displayWhen: {},
      displayTo: [],
      title: 'New Action',
    };

    return updatedCrudFunction;
  }
};
const codeContainerTypes = {
  code: {
    key: 'code',
    name: 'Code',
  },
  input: {
    key: 'input',
    name: 'Input',
    isLowerResolutionTab: true,
  },
  output: {
    key: 'output',
    name: 'Output',
  },
};
const outputTypeObject = {
  error: {
    key: 'error',
    class: 'errorColorClassIndicator',
  },
  success: {
    key: 'success',
    class: 'successColorClassIndicator',
  },
  normal: {
    key: 'normal',
    class: 'normalColorClassIndicator',
  },
};
const defaultOutputData = { msg: {}, outputType: outputTypeObject.normal };
const AIGeneratedCodeEditor = ({
  componentSchema,
  appID,
  connectedTableData,
  setComponentSchema,
  componentRowList,
}) => {
  const [buttonType, setButtonType] = useState(buttonTypeObject.CRUD.key);
  const [activeButtonKey, setActiveButtonKey] = useState(crudTypeButtonObject.POST.key);
  const [codeContainerType, setCodeContainerType] = useState(codeContainerTypes.code.key);
  const [inputData, setInputData] = useState('{}');
  const [outputData, setOutputData] = useState(defaultOutputData);
  const [code, setCode] = useState(() => {
    const selectedData = getDefaultCode({
      componentSchema,
      actionButtonKey: activeButtonKey,
      buttonType,
    });
    return selectedData?.functionCode ?? defaultCode;
  });
  const [title, setTitle] = useState(() => {
    const selectedData = getDefaultCode({
      componentSchema,
      actionButtonKey: activeButtonKey,
      buttonType,
    });
    return selectedData?.title ?? 'New Action';
  });
  const [errors, setErrors] = useState({});

  const handleTypeSelect = (actionButtonKey) => {
    setButtonType(buttonTypeObject.CRUD.key);
    setActiveButtonKey(actionButtonKey);
    setErrors({});
    const selectedData = getDefaultCode({
      componentSchema,
      actionButtonKey,
      buttonType: buttonTypeObject.CRUD.key,
    });
    setCode(selectedData?.functionCode || '');
    setOutputData(defaultOutputData);
    setInputData('{}');
    setCodeContainerType(codeContainerTypes.code.key);
  };

  const handleActionButtonSelect = (actionButtonKey) => {
    setButtonType(buttonTypeObject.actionButton.key);
    setActiveButtonKey(actionButtonKey);
    setErrors({});
    const selectedData = getDefaultCode({
      componentSchema,
      actionButtonKey,
      buttonType: buttonTypeObject.actionButton.key,
    });
    setCode(selectedData?.functionCode || '');
    setTitle(selectedData?.title || '');
    setOutputData(defaultOutputData);
    setInputData('{}');
    setCodeContainerType(codeContainerTypes.code.key);
  };
  const handleCodeChange = (value) => {
    setCode(value);
  };

  const handleAddActionButton = () => {
    const actionButtonID = uuid();
    const selectedData = getDefaultCode({
      componentSchema,
      actionButtonKey: actionButtonID,
      buttonType: buttonTypeObject.actionButton.key,
    });

    setButtonType(buttonTypeObject.actionButton.key);
    setActiveButtonKey(actionButtonID);
    setErrors({});
    setCode(selectedData?.functionCode || '');
    setTitle(selectedData?.title || '');
    setComponentSchema({
      ...componentSchema,
      actionButton: { ...(componentSchema?.actionButton ?? {}), [actionButtonID]: selectedData },
    });
  };

  const codeContainerRef = useRef(null);
  const { height: codeContainerHeight } = useDivDimensions(codeContainerRef);
  const [isCodeTestLoading, setIsCodeTestLoading] = useState(false);

  const handleTestCode = async () => {
    const componentID = componentSchema?.componentID;
    const baseUrl = `app/${appID}/TestCustomCode/${componentID}`;
    const selectedData = getDefaultCode({
      componentSchema,
      actionButtonKey: activeButtonKey,
      buttonType,
    });

    const functionID = selectedData?.functionID ?? uuid();
    const data = {
      functionCode: code,
      functionID: functionID,
      functionName: 'customizeERP',
    };
    setIsCodeTestLoading(true);
    const componentRes = await _supistaApiPost(baseUrl, { data });
    if (componentRes?.__d3__success) {
      if (buttonType === buttonTypeObject.CRUD.key) {
        const updatedCrudFunction = {
          fuctionName: 'displayFunc',
          functionID: functionID,
          functionCode: code,
          params: [],
          type: 'CUSTOM',
        };
        const updatedComonentSchema = {
          ...(componentSchema ?? {}),
          crudOp: { ...(componentSchema?.crudOp ?? {}), [activeButtonKey]: updatedCrudFunction },
        };

        setComponentSchema(updatedComonentSchema);
      } else if (buttonType === buttonTypeObject.actionButton.key) {
        const updatedCrudFunction = {
          fuctionName: 'actionFunc',
          functionID: functionID,
          functionCode: code,
          displayWhen: componentSchema?.actionButton?.[activeButtonKey]?.displayWhen ?? [],
          displayTo: componentSchema?.actionButton?.[activeButtonKey]?.displayTo ?? {},
          title: title,
        };
        const updatedComonentSchema = {
          ...(componentSchema ?? {}),
          actionButton: {
            ...(componentSchema?.actionButton ?? {}),
            [activeButtonKey]: updatedCrudFunction,
          },
        };
        console.log(updatedComonentSchema);
      }
      let finalOutput = { ...outputData };
      if (componentRes?.response?.codeResponse?.errorType) {
        finalOutput = {
          ...finalOutput,
          msg: { response: componentRes?.response?.codeResponse ?? {} },
          outputType: outputTypeObject.error,
        };
      } else {
        finalOutput = {
          ...finalOutput,
          msg: { response: componentRes?.response?.codeResponse ?? {} },
          outputType: outputTypeObject.success,
        };
      }
      setOutputData(finalOutput);
      setCodeContainerType(codeContainerTypes.output.key);
      setIsCodeTestLoading(false);
    } else {
      setIsCodeTestLoading(false);
    }
  };

  const [selectedId, setSelectedId] = useState(componentRowList?.[0]?.id ?? '');

  const handleComponentChange = (e) => {
    setSelectedId(e.target.value);
  };
  const [sampleData, setSampleData] = useState([]);

  useEffect(() => {
    (async () => {
      const componentID = componentSchema?.componentID;
      const baseUrl = `app/${appID}/Action_Button/${componentID}`;
      const finalUrl = `${baseUrl}`;
      const data = {
        actionType: 'getRowData',
        filterMainComponent: {
          __d3__filterdata: {
            where: { id: selectedId },
          },
        },
      };
      const componentRes = await _supistaApiPost(finalUrl, { data });
      setSampleData(componentRes?.rows ?? []);
    })();
  }, [selectedId, componentSchema?.componentID, appID]);

  const monacoRef = useRef(null);
  const handleEditorDidMount = (editor, monaco) => {
    monacoRef.current = editor;

    editor.updateOptions({ readOnly: false });
    editor.setValue(JSON.stringify(sampleData));
    editor.getAction('editor.action.formatDocument').run();

    setTimeout(() => {
      editor.updateOptions({ readOnly: true });
    }, 3000);
  };
  const [isSampleDataPopup, setIsSampleDataPopup] = useState(false);

  useEffect(() => {
    if (monacoRef.current) {
      monacoRef.current.updateOptions({ readOnly: false });
      monacoRef.current.setValue(JSON.stringify(outputData?.msg));
      monacoRef.current.getAction('editor.action.formatDocument').run();
      setTimeout(() => {
        monacoRef.current.updateOptions({ readOnly: true });
      }, 3000);
    }
  }, [outputData?.msg, codeContainerType]);

  return (
    <div className={styles.aiCustomizationOuter}>
      <Row className={styles.aiCustomizationInner}>
        <Col md={3} className={styles.apiTypes}>
          <ListGroup>
            {Object.values(crudTypeButtonObject).map((type, index) => (
              <ListGroup.Item
                key={index}
                action
                active={buttonType === buttonTypeObject.CRUD.key && activeButtonKey === type.key}
                onClick={() => handleTypeSelect(type.key)}
              >
                {mls(type.label)}
              </ListGroup.Item>
            ))}
            <ListGroup.Item className={styles.actionButtonContainer}>
              <b>{mls('Action Buttons')}</b>
              <hr />
              <ListGroup>
                {Object.keys(componentSchema?.actionButton ?? {}).map((actionButtonKey, index) => {
                  const actionButtonElem = componentSchema?.actionButton[actionButtonKey];
                  return (
                    <ListGroup.Item
                      key={index}
                      action
                      active={
                        buttonType === buttonTypeObject.actionButton.key &&
                        activeButtonKey === actionButtonKey
                      }
                      onClick={() => handleActionButtonSelect(actionButtonKey)}
                    >
                      {actionButtonElem?.title}
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
              <Button variant='link' onClick={handleAddActionButton} className={styles.addButton}>
                {mls('Add Buttons')}
                <Plus />
              </Button>
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={9} className={styles.editorContainer}>
          <div className={styles.queryContainerOuter}>
            <div
              className={styles.editorHeader}
              style={{ justifyContent: 'flex-start', marginBottom: 0, gap: '1rem' }}
            >
              <div style={{ width: 'auto', display: 'flex', textAlign: 'end' }}>
                <Form.Group as={Row} className='' style={{ marginLeft: 0, marginRight: 0 }}>
                  <Form.Label column sm={3}>
                    {mls('Id')}:
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      as='select'
                      name='selectId'
                      value={selectedId}
                      style={{ textAlign: 'end' }}
                      onChange={(e) => handleComponentChange(e)}
                      isInvalid={!!errors.componentID}
                    >
                      <option value='' disabled>
                        {mls('Select Id')}
                      </option>
                      {React.Children.toArray(
                        (componentRowList ?? []).map((componentRowData) => {
                          return (
                            <option value={componentRowData?.id ?? ''} disabled={false}>
                              {componentRowData?.id ?? ''}
                            </option>
                          );
                        })
                      )}
                    </Form.Control>
                    <Form.Control.Feedback type='invalid'>
                      {errors.componentID}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </div>
              <div
                style={{
                  width: 'auto',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '1rem',
                }}
              >
                <div>
                  <SampleDataPopup
                    isSampleDataPopup={isSampleDataPopup}
                    setIsSampleDataPopup={setIsSampleDataPopup}
                    sampleData={sampleData}
                  />
                  {/* <b>{mls('Sample Data')}:</b> */}
                  <button
                    className='primaryButton secondaryButtonColor'
                    onClick={() => setIsSampleDataPopup(true)}
                  >
                    {mls('Selected Row Data')}
                  </button>
                </div>
              </div>
            </div>
            {buttonType === buttonTypeObject.actionButton.key ? (
              <>
                <div className={styles.editorHeader} style={{ marginBottom: 0 }}>
                  <QueryContainerPermissionContainer
                    componentSchema={componentSchema}
                    title={title}
                    setTitle={setTitle}
                    activeButtonKey={activeButtonKey}
                    setComponentSchema={setComponentSchema}
                    errors={errors}
                    setErrors={setErrors}
                  />
                </div>
              </>
            ) : null}
            <div
              className={`${styles.queryContainer} ${
                buttonType === buttonTypeObject.actionButton.key
                  ? styles.queryContainerWithPermission
                  : ''
              }`}
            >
              <div className={styles.queryEditorTabOuterContainer}>
                <div className={styles.queryEditorTabHeaderContainer}>
                  <div className={styles.queryEditorTabContainer}>
                    {React.Children.toArray(
                      Object.keys(codeContainerTypes).map((codeContainerTypeKey) => {
                        const codeContainerTypeElem = codeContainerTypes[codeContainerTypeKey];
                        return (
                          <div
                            className={`${styles.queryEditorTabOption} ${
                              codeContainerType === codeContainerTypeKey
                                ? styles.activeContainerTab
                                : ''
                            } ${
                              codeContainerTypeElem?.isLowerResolutionTab
                                ? styles.lowerResolutionTab
                                : ''
                            }`}
                            onClick={() => setCodeContainerType(codeContainerTypeElem.key)}
                          >
                            {mls(codeContainerTypeElem.name)}
                            {codeContainerTypeElem.key === codeContainerTypes.output.key ? (
                              <div
                                className={`${styles.outputColorIndicator} ${
                                  styles?.[
                                    outputData?.outputType?.class ?? outputTypeObject?.normal?.class
                                  ]
                                }`}
                              ></div>
                            ) : null}
                          </div>
                        );
                      })
                    )}
                  </div>
                  <div
                    // className={`primaryButton secondaryButtonColor ${styles.runCodeButton}`}
                    className={` ${styles.runCodeButton}`}
                    onClick={() => handleTestCode()}
                  >
                    {isCodeTestLoading ? (
                      <>
                        <CircularProgress color='inherit' size={10} /> &nbsp;
                      </>
                    ) : null}
                    {mls('Run Code')}
                    &nbsp;
                    <Play />
                  </div>
                </div>
                <div className={styles.queryEditorInnerContainer}>
                  <div className={styles.queryEditorContainer}>
                    <div className={styles.querytitleArea}>
                      {codeContainerType === codeContainerTypes.code.key ? mls('Commands') : null}
                      {codeContainerType === codeContainerTypes.output.key ? mls('Output') : null}
                      {codeContainerType === codeContainerTypes.input.key ? mls('Input') : null}
                    </div>

                    <div className={styles.queryEditorArea} ref={codeContainerRef}>
                      <div
                        style={
                          codeContainerType === codeContainerTypes.code.key
                            ? { height: '100%' }
                            : { display: 'none', height: '100%' }
                        }
                      >
                        <Editor
                          height={codeContainerHeight}
                          defaultLanguage='javascript'
                          value={code}
                          onChange={handleCodeChange}
                          theme='vs-dark'
                        />
                      </div>
                      <div
                        style={
                          codeContainerType === codeContainerTypes.output.key
                            ? { height: '100%' }
                            : { display: 'none', height: '100%' }
                        }
                      >
                        <Editor
                          height={codeContainerHeight}
                          defaultLanguage='json'
                          value={JSON.stringify(outputData?.msg ?? '')}
                          onChange={() => {}}
                          options={{
                            automaticLayout: true,
                            formatOnPaste: true,
                            formatOnType: true,
                            // readOnly: true,
                          }}
                          onMount={handleEditorDidMount}
                          theme='vs-dark'
                        />
                      </div>
                      <div
                        style={
                          codeContainerType === codeContainerTypes.input.key
                            ? { height: '100%' }
                            : { display: 'none', height: '100%' }
                        }
                      >
                        <Editor
                          height={codeContainerHeight}
                          defaultLanguage='javascript'
                          value={inputData}
                          onChange={(e) => {
                            setInputData(e);
                          }}
                          theme='vs-dark'
                          options={{ lineNumbers: false }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.queryOutputContainer}>
                <div className={styles.querytitleArea}>{mls('Input')}</div>
                <div className={styles.queryEditorArea}>
                  <Editor
                    height='100%'
                    // height='10rem'
                    defaultLanguage='javascript'
                    value={inputData}
                    onChange={(e) => {
                      setInputData(e);
                    }}
                    theme='vs-dark'
                    options={{ lineNumbers: false }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <QueryGenerator
        connectedTableData={connectedTableData}
        appID={appID}
        handleCodeChange={handleCodeChange}
        code={code}
        buttonType={buttonType}
        activeButtonKey={activeButtonKey}
      />
    </div>
  );
};

export default AIGeneratedCodeEditor;
