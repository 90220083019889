import { Modal } from '@mui/material';

import { mls } from 'lib/multilanguagesupport';

const ConfirmDeleteModal = ({
  isDeleteOpen,
  setIsDeleteOpen,
  handleDeleteTable,
  joinTableData,
}) => {
  return (
    <Modal open={isDeleteOpen} onClose={() => setIsDeleteOpen(false)}>
      <div className='modal-dialog modal-dialog-centered mw-600px'>
        <div
          className='modal-content d-flex justify-content-center align-items-center p-5 mx-auto'
          style={{ maxWidth: '90vw' }}
        >
          <p className='p-5 mb-7 fs-4 fw-bold'>Are you sure you want to delete this table?</p>
          <div className='d-flex'>
            <button
              data-bs-dismiss='modal'
              onClick={() => {
                handleDeleteTable(joinTableData);
              }}
              style={{ marginTop: -15 }}
              className='btn btn-sm mb-2 btn-danger btn-light-primary mx-4'
            >
              {mls('Delete table')}
            </button>
            <button
              onClick={() => setIsDeleteOpen(false)}
              style={{ marginTop: -15 }}
              className='btn btn-sm mb-2 btn-primary btn-light-primary mx-4'
            >
              {mls('Cancel')}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmDeleteModal;
