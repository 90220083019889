import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import { create } from 'lib/reusable-components/reusableUtils/redux/stateSlice';
import store from 'lib/reusable-components/reusableUtils/redux/store';
import { useDispatch } from 'react-redux';

export default function useUpdateTableSchema() {
  const dispatch = useDispatch();
  /**
   * isDelete - true for delete operation, false for table creation
   * path - The key of table to be deleted
   * newObj - the object of new table in case of table creation
   * key - the key (table ID)
   */
  return (isDelete, path, newObj, key, update = false, newKey) => {
    const schema =
      store.getState()[reduxConstants.STORE_NAME][reduxConstants.config.APP_SCHEMA][
        reduxConstants.config.DATA_TABLE
      ];
    let newDataTable;
    if (isDelete) {
      newDataTable = { ...schema };
      delete newDataTable[path];
    } else {
      newDataTable = { ...schema, [key]: newObj };
    }
    dispatch(
      create({
        setPath: `${reduxConstants.config.APP_SCHEMA}.${reduxConstants.config.DATA_TABLE}`,
        value: newDataTable,
      })
    );
  };
}
