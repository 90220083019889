import { mls } from 'lib/multilanguagesupport';
import React, { useState } from 'react';
import clsx from 'clsx';
import styles from './EditTableSettingsPopup.module.scss';
import { toast } from 'react-toastify';
import { convertToTitleCase } from '../util/utilFunctions';

interface addNewColumnProps {
  columnSchema: any[];
  handleAddColumn: Function;
}
const AddNewColumn = ({ columnSchema, handleAddColumn }: addNewColumnProps) => {
  const defaultNewColumnData = {
    status: false,
    columnName: '',
    type: 'text',
  };
  const [addNewColumnData, setAddNewColumnData] = useState(defaultNewColumnData);
  const handleAddNewColumnButton = () => {
    setAddNewColumnData({ ...defaultNewColumnData, status: true });
  };
  const addColumn = () => {
    if (!addNewColumnData?.columnName) {
      toast.error(mls('Please Enter Column Name!'));
      return;
    }
    const isColumnNamePresent = (columnSchema ?? []).some(
      (columnElem) => columnElem?.columnName === addNewColumnData?.columnName
    );
    if (isColumnNamePresent) {
      toast.error(mls('Column Name Already Present!'));
      return;
    }
    handleAddColumn({
      columnName: addNewColumnData.columnName,
      name: convertToTitleCase(addNewColumnData.columnName),
      type: addNewColumnData.type,
    });
    setAddNewColumnData(defaultNewColumnData);
  };
  const handleColumnName = (e: any) => {
    const str = e.target.value;
    // const result = str.replace(/\s+/g, '');
    setAddNewColumnData({ ...addNewColumnData, columnName: str });
  };
  return (
    <div>
      {addNewColumnData?.status ? (
        <div className={styles.addNewColumnData}>
          <label className='form-label fs-6 fw-bolder text-dark'>{mls('Column name')}</label>
          <input
            placeholder='Column name'
            value={addNewColumnData.columnName}
            onChange={handleColumnName}
            onBlur={() => addColumn()}
            className={clsx('form-control form-control-lg form-control-solid')}
            type='text'
            name='columnName'
            autoComplete='off'
          />
          <button
            onClick={() => addColumn()}
            className='btn btn-sm btn-primary mt-4'
            style={{ width: 120 }}
          >
            {mls('Add column')}
          </button>
          &nbsp; &nbsp;
          <button
            onClick={() => setAddNewColumnData(defaultNewColumnData)}
            className='btn btn-sm btn-secondary mt-4'
            style={{ width: 120 }}
          >
            {mls('Cancel')}
          </button>
        </div>
      ) : (
        <button
          onClick={handleAddNewColumnButton}
          className='btn btn-sm btn-primary mt-4'
          style={{ width: 120 }}
        >
          {mls('Add column')}
        </button>
      )}
    </div>
  );
};

export default AddNewColumn;
