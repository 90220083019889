import _ from 'lodash';

const sortArrayOfObjects = (arrayOfObjects, sortBy='alphabetically', isSortAscending=true) => {
  const sorted = _.sortBy(arrayOfObjects, (obj) => {
    if(sortBy === 'alphabetically') {
      return obj.name?.toLowerCase();
    }
    if(sortBy === 'createdAt') {
      return -obj.createdAt;      // Using the negative value since larger value (later timestamp) should come first
    }
    if(sortBy === 'updatedAt') {
      return -obj.updatedAt;      // Using the negative value since larger value (later timestamp) should come first
    }
  });
  return isSortAscending ? sorted : sorted.reverse();
}

export default sortArrayOfObjects;