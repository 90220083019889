/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { FC, useEffect } from 'react';
import { Redirect, Switch, Route, useHistory, withRouter } from 'react-router-dom';
import { MasterLayout } from '../../_metronic/layout/MasterLayout';
import { PrivateRoutes } from './PrivateRoutes';
import { ErrorsPage } from '../modules/errors/ErrorsPage';
import getAppID from '../../utils/getAppID';
import getDefaultAppID from '../../utils/getDefaultAppID';
// import { supistaApiGet } from 'app/connections/connections';
// import { useDispatch } from 'react-redux';
// import { create } from 'redux/stateSlice';
import { fetchData } from '../../utils/fetchAppSchemaFromServer';
import { AuthPage } from 'lib/auth-ui/AuthPage';
import useAuthenticate from 'lib/reusable-components/reusableUtils/commons/authenticate';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';

const Routes: FC = () => {
  const history = useHistory();
  const authenticate = useAuthenticate();
  // const dispatch = useDispatch();

  const userData = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.LOGIN);
  const appSchema = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.APP_SCHEMA);

  let appID = getAppID(history.location.pathname);

  // const fetchData = () => {
  //   supistaApiGet(`appSchema/${appID}`)
  //     .then((res) => {
  //       if (res.__d3__error !== true) {
  //         dispatch(
  //           create({
  //             setPath: reduxConstants.config.APP_SCHEMA,
  //             value: res,
  //           })
  //         );
  //       }
  //     })
  //     .catch((err) => {});
  // };
  useEffect(() => {
    if (appID !== undefined) {
      // console.log(appID, 'appID at run');
      fetchData(appID);
    }
  }, [appID]);
  const defaultAppID = getDefaultAppID(userData);

  if (authenticate !== true) {
    return <Route component={AuthPage} />;
  }

  if (!appID || appID !== defaultAppID) {
    appID = getDefaultAppID(userData);
    return <Redirect to={`/${appID}`} />;
  }

  if (!appSchema.app) {
    fetchData(appID);
    return <></>;
  }
  return (
    <Switch>
      <Route path='/error' component={ErrorsPage} />
      <MasterLayout appID={appID}>
        <PrivateRoutes />
      </MasterLayout>
    </Switch>
  );
};

export default withRouter(Routes);
