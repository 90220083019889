import React, { useEffect, useMemo, useState } from 'react';
import FullCalendarComp from './FullCalendarComp';
import styles from './myCalendar.module.scss';
import { apiEventsSampleData, eventsSampleData2 } from './eventsSampleData';
import CheckBox from 'lib/reusable-components/Components/CheckBox/CheckBox';
import { mls } from 'lib/multilanguagesupport';
import { Accordion } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { getThemeColorByValue } from 'lib/appComponent/componentSettings/TabContent/ComponentDetails/getThemeColorByValue';
import useOperations from 'lib/reusable-components/hooks/fetchERPdata/useOperations';
import FilterSection from './FilterSection';
import './calendarView.scss';
interface myCalendarProps {
  component: { [key: string]: any };
  tableSchema: { [key: string]: any };
  buttonSchema: { [key: string]: any };
  accessSchema: { [key: string]: any };
  parentScreen: { [key: string]: any };
  parentRowID: any;
  relationSchema: any;
}

const defaultFilterSchema = ({ filterList }: { filterList: any }) => {
  const firstFilterListData: any = Object.values(filterList ?? {})?.[0];
  if (firstFilterListData) {
    let filterValues: string[] = [];
    (firstFilterListData?.values ?? []).forEach((valueElem: any, i: number) => {
      if (i < 5) {
        filterValues = [...filterValues, valueElem?.value ?? ''];
      }
    });
    return { [firstFilterListData?.columnName]: { in: filterValues } };
  }
  return {};
};
const idValue = null;
const isCreate = false;
const MyCalendar = ({
  component,
  tableSchema,
  buttonSchema,
  accessSchema,
  parentScreen,
  parentRowID = null,
  relationSchema = {},
}: myCalendarProps) => {
  const calendarSetting = component?.calendarSetting ?? {};

  const filterList = calendarSetting?.filterList ?? {};
  // const [apiEventsData, setApiEventsData] = useState(apiEventsSampleData);
  const [filterSchema, setFilterSchema] = useState<any>(defaultFilterSchema({ filterList }));
  const filterColorList = useMemo(
    () => getColorByFilterValue({ filterList: calendarSetting?.filterList }),
    [calendarSetting?.filterList]
  );
  const [selectedFilterColumn, setSelectedFilterColumn] = useState(
    Object.keys(filterList ?? {})?.[0] ?? ''
  );

  const {
    isLoading,
    error,
    data,
    filterData,
    refetch,
    setFilterData,
    // createData,
    // updateData,
    // deleteData,
    // downloadCSV,
    // uploadCSV,
  } = useOperations(component, idValue, isCreate, relationSchema, parentRowID);

  useEffect(() => {
    setFilterData({ where: filterSchema });
  }, [filterSchema, setFilterData]);

  const calendarData = useMemo(() => {
    const calendarSetting = component?.calendarSetting ?? {};
    const startDate = calendarSetting?.startDate ?? '';
    const titleField = calendarSetting?.titleField ?? '';
    const endDate = calendarSetting?.endDate ?? '';

    const finalData = getCalendarData({
      apiEventsData: data?.rows ?? [],
      startDate,
      endDate,
      titleField,
      filterColorList,
      selectedFilterColumn,
    });
    return finalData;
  }, [data?.rows, component?.calendarSetting, filterColorList, selectedFilterColumn]);

  const addFilter = ({ columnName, value }: { columnName: string; value: string }) => {
    const newColumnValue = [...(filterSchema?.[columnName]?.in ?? []), value];
    setFilterSchema({ ...filterSchema, [columnName]: { in: newColumnValue } });
  };
  const removeFilter = ({ columnName, value }: { columnName: string; value: string }) => {
    const newColumnValue = (filterSchema?.[columnName]?.in ?? [])?.filter(
      (elem: string) => elem !== value
    );
    if (newColumnValue?.length === 0) {
      setFilterSchema({ [columnName]: {} });
    }

    setFilterSchema({ ...filterSchema, [columnName]: { in: newColumnValue } });
  };
  return (
    <div className={`${styles.MyCalendarComponent} CalendarView`}>
      <div className={styles.calendarComponentOuter}>
        <FullCalendarComp calendarData={calendarData} refetch={refetch} isLoading={isLoading} />
      </div>
      <FilterSection
        addFilter={addFilter}
        removeFilter={removeFilter}
        filterList={filterList}
        filterSchema={filterSchema}
        setFilterSchema={setFilterSchema}
        selectedFilterColumn={selectedFilterColumn}
        setSelectedFilterColumn={setSelectedFilterColumn}
      />
    </div>
  );
};

export default MyCalendar;

const getColorByFilterValue = ({ filterList }: { filterList: any }) => {
  let filterColorList = {};
  let newFilterColorList = {};

  Object.keys(filterList ?? {}).forEach((columnName) => {
    const filterListElem = filterList?.[columnName] ?? {};
    let valueList = {};
    (filterListElem?.values ?? []).forEach((valueElem: any) => {
      valueList = { ...valueList, [valueElem?.value]: valueElem };
      newFilterColorList = { ...newFilterColorList, [valueElem?.value]: valueElem };
    });
    filterColorList = { ...filterColorList, [columnName]: valueList };
  });
  return newFilterColorList;
};
const getCalendarData = ({
  apiEventsData,
  startDate,
  endDate,
  titleField,
  filterColorList,
  selectedFilterColumn,
}: {
  apiEventsData: any[];
  filterColorList: any;
  startDate: string;
  endDate: string;
  titleField: string;
  selectedFilterColumn: string;
}) => {
  return (apiEventsData ?? []).map((apiEventElem: any) => {
    const startDateElem = apiEventElem?.[startDate] ?? '';
    const endDateElem = apiEventElem?.[endDate] ?? '';
    const start = typeof startDateElem === 'string' ? new Date(startDateElem) : startDateElem;
    const end = typeof endDateElem === 'string' ? new Date(endDateElem) : endDateElem;
    const title = apiEventElem?.[titleField];
    const filterColumn = apiEventElem?.[selectedFilterColumn] ?? '';
    const colorData =
      filterColorList?.[filterColumn] ?? getThemeColorByValue({ value: filterColumn });
    const backgroundColor = colorData?.backgroundColor;
    const borderColor = colorData?.borderColor;
    const textColor = colorData?.textColor;

    return {
      ...apiEventElem,
      start,
      end,
      title,
      filterColumn,
      backgroundColor,
      borderColor,
      textColor,
    };
  });
};
