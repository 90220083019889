export const apiErrors={
  400: {
    error: 'BAD REQUEST',
    message: 'You are making a bad request! Please reload this webpage or app. If this problem persists, please email us at support@supista.com with screenshots and steps to replicate.'
  },
  401: {
    error: 'UNAUTHORIZED',
    message: 'You are Unauthorized to access this resource! Kindly contact your IT department to give you permission if you think its an error.'
  },
  403: {
    error: 'FORBIDDEN',
    message: 'You do not have access to this request.'
  },
  404: {
    error: 'NOT FOUND',
    message: 'The resource you are looking for is not found.'
  },
  500: {
    error: 'INTERNAL SERVER ERROR',
    message: 'Some nasty error occurred to the server. We are working to mitigate this issue as soon as possible. Please try again after sometime. If this error persists, kindly contact us at support@supista.com with screenshots and steps to replicate.'
  },
}