import clsx from 'clsx';
import { mls } from 'lib/multilanguagesupport';
import React, { useRef, useState, useEffect } from 'react';

export const ValidationSection = ({ columnSchema, setColumnSchema, index, setDataTableSchema }) => {
  const sectionRef = useRef(null);
  const [localColumnSchema, setLocalColumnSchema] = useState(() => {
    return {
      ...columnSchema,
      validation: columnSchema.validation ? [...columnSchema.validation] : [],
    };
  });
  const [validationError, setValidationError] = useState('');
  // Handles change in validation field
  // console.log(index, 'index');

  const handleChangeValidation = (e, valIndex, field) => {
    e.preventDefault();
    const value = e.target.value;

    setLocalColumnSchema((prev) => {
      const updatedSchema = { ...prev };
      const updatedValidation = [...(updatedSchema.validation || [])];

      if (!updatedValidation[valIndex]) {
        updatedValidation[valIndex] = {};
      }

      updatedValidation[valIndex] = {
        ...updatedValidation[valIndex],
        [field]: value,
      };

      // Check for errors
      const currentValidation = updatedValidation[valIndex];
      let error = '';

      if (field === 'type' && !value) {
        error = 'Validation type is required';
      } else if (field === 'message' && !value.trim()) {
        error = 'Error message is required';
      }

      if (error) {
        currentValidation.error = error;
      } else {
        delete currentValidation.error;
      }

      // console.log(updatedSchema, 'updated by schema');

      return { ...updatedSchema, validation: updatedValidation };
    });
  };

  const addValidation = (e) => {
    e.preventDefault();
    // console.log('Add validation button clicked');

    setLocalColumnSchema((prev) => {
      const updatedSchema = { ...prev };
      const updatedValidation = [...(updatedSchema.validation || [])];

      const hasIsRequired = updatedValidation.some((v) => v.type === 'isRequired');

      let newType = '';
      if (!hasIsRequired) {
        newType = 'isRequired';
      } else {
        newType =
          ['isEmail', 'isNumeric', 'isLength', 'isBool', 'isDate', 'isAlphabet', 'isMobile'].find(
            (type) => !updatedValidation.some((v) => v.type === type)
          ) || '';
      }

      const newValidation = { type: newType, message: '' };

      if (newType) {
        updatedValidation.push(newValidation);
        // console.log('Updated validation array:', updatedValidation);
      } else {
        // console.log('No unique validation types available to add');
      }

      return { ...updatedSchema, validation: updatedValidation };
    });
  };

  const deleteValidation = (valIndex) => {
    setValidationError('');
    setLocalColumnSchema((prev) => {
      const updatedSchema = { ...prev };
      const updatedValidation = updatedSchema.validation
        ? updatedSchema.validation.filter((_, index) => index !== valIndex)
        : [];
      return { ...updatedSchema, validation: updatedValidation };
    });
  };

  const handleSectionBlur = () => {
    const { hasErrors, errorMessage } = checkForErrors(localColumnSchema);

    if (hasErrors) {
      setValidationError(errorMessage);
    } else {
      setValidationError('');
      setColumnSchema({ ...localColumnSchema });
      setDataTableSchema((prev) => {
        const updatedColumnSchema = [...prev.columnSchema];
        updatedColumnSchema[index] = { ...localColumnSchema };
        return {
          ...prev,
          columnSchema: updatedColumnSchema,
        };
      });
    }
    // console.log(localColumnSchema, 'localschema');
    // console.log(columnSchema, 'columnSchemafinal');
  };

  const checkForErrors = (schema) => {
    const validations = schema.validation || [];
    let hasErrors = false;
    let errorMessage = '';

    validations.forEach((validation) => {
      if (validation.type === 'isLength') {
        if (!validation.minValue || !validation.maxValue) {
          hasErrors = true;
          errorMessage = 'Length range must be specified for isLength validation';
        } else if (parseInt(validation.minValue) > parseInt(validation.maxValue)) {
          hasErrors = true;
          errorMessage = 'Min length cannot be greater than max length';
        }
      }

      if (validation.type === 'isRequired' && !validation.message.trim()) {
        hasErrors = true;
        errorMessage = 'Required fields must have an error message';
      }
    });

    return { hasErrors, errorMessage };
  };

  useEffect(() => {
    setLocalColumnSchema(columnSchema);
  }, [columnSchema]);

  //   const schemaToValidate = { ...updatedSchema };
  //   const columnToUpdate = { ...schemaToValidate[index] };
  //   const validations = columnToUpdate.validation || [];

  //   validations.forEach((validation) => {
  //     if (validation.type === 'isLength') {
  //       if (!validation.minValue || !validation.maxValue) {
  //         validation.error = 'Length range must be specified';
  //       } else if (validation.minValue > validation.maxValue) {
  //         validation.error = 'Min length cannot be greater than max length';
  //       } else {
  //         delete validation.error;
  //       }
  //     }

  //     if (validation.type === 'isRequired' && !validation.message.trim()) {
  //       validation.error = 'Required fields must have an error message';
  //     }
  //   });

  //   columnToUpdate.validation = validations;
  //   schemaToValidate[index] = columnToUpdate;

  //   // Update the schema after validation
  //   setDataTableSchema(schemaToValidate);
  // };

  return (
    <div
      className='px-4 py-4'
      style={{ background: '#F5F8FA' }}
      ref={sectionRef}
      onBlur={handleSectionBlur}
    >
      <p className='mb-5 fs-6 fw-bolder text-dark'>Validation</p>
      <div style={{ maxHeight: 300, overflowY: 'auto', overflowX: 'hidden' }}>
        {!localColumnSchema?.validation?.length && (
          <p className='text-muted px-4'>
            {mls('No validations added.')}{' '}
            {/* {console.log(columnSchema.validation, 'validation')} */}
          </p>
        )}
        {localColumnSchema?.validation?.length !== 0 && (
          <div className='row'>
            <div className='col-6 '>
              <label className='form-label mx-9 fs-6 fw-bolder text-dark'>{mls('Type')}</label>
            </div>
            <label className='col-6 form-label fs-6 fw-bolder text-dark'>
              {mls('Error Message')}
            </label>
          </div>
        )}
        {localColumnSchema?.validation?.map((val, key) => (
          <div className='row mt-1' key={key}>
            <div className='col-6'>
              <div className='d-flex align-items-center'>
                <i
                  onClick={() => deleteValidation(key)}
                  className='fas fa-trash-alt text-danger'
                  style={{ marginRight: 15, fontSize: 15, cursor: 'pointer' }}
                ></i>
                <select
                  name='type'
                  onChange={(e) => handleChangeValidation(e, key, 'type')}
                  className='form-select form-control form-control-lg'
                >
                  <option value='' selected={val.type === '' ? true : false} hidden>
                    Select...
                  </option>
                  <option value='isRequired' selected={val.type === 'isRequired' ? true : false}>
                    Is Required
                  </option>
                  <option value='isLength' selected={val.type === 'isLength' ? true : false}>
                    Is Length
                  </option>
                  <option value='isEmail' selected={val.type === 'isEmail' ? true : false}>
                    Is Email
                  </option>
                  <option value='isNumeric' selected={val.type === 'isNumeric' ? true : false}>
                    Is Numeric
                  </option>
                  <option value='isAlphabet' selected={val.type === 'isAlphabet' ? true : false}>
                    Is Alphabet
                  </option>
                  <option value='isBool' selected={val.type === 'isBool' ? true : false}>
                    Is Bool
                  </option>
                  <option
                    value='isCreditCard'
                    selected={val.type === 'isCreditCard' ? true : false}
                  >
                    Is Credit Card
                  </option>
                  <option value='isCurrency' selected={val.type === 'isCurrency' ? true : false}>
                    Is Currency
                  </option>
                  <option value='isDate' selected={val.type === 'isDate' ? true : false}>
                    Is Date
                  </option>
                  <option value='isUppercase' selected={val.type === 'isUppercase' ? true : false}>
                    Is Uppercase
                  </option>
                  <option value='isLowercase' selected={val.type === 'isLowercase' ? true : false}>
                    Is Lowercase
                  </option>
                  <option value='isMobile' selected={val.type === 'isMobile' ? true : false}>
                    Is Mobile
                  </option>
                  <option value='isUri' selected={val.type === 'isUri' ? true : false}>
                    {mls('Is Uri')}
                  </option>
                </select>
              </div>
            </div>
            <div className='col-6'>
              <input
                placeholder='Error Message'
                className={clsx('form-control form-control-lg', { 'is-invalid': val.error })}
                type='text'
                name='error'
                autoComplete='off'
                onChange={(e) => handleChangeValidation(e, key, 'message')}
                value={val.message}
              />
            </div>
            {val.error && <div className='col-12 text-danger mt-1'>{val.error}</div>}
            {val.type === 'isLength' && (
              <div className='row mx-3'>
                <div className='col-6'>
                  <label className='form-label fs-6 fw-bolder text-dark mt-3'>
                    {mls('Min Value')}
                  </label>
                  <input
                    placeholder='Min Value'
                    className={clsx('form-control form-control-lg ')}
                    type='number'
                    name='minValue'
                    autoComplete='off'
                  />
                </div>
                <div className='col-6'>
                  <label className='form-label fs-6 fw-bolder text-dark mt-3'>
                    {mls('Max Value')}
                  </label>
                  <input
                    placeholder='Max Value'
                    className={clsx('form-control form-control-lg ')}
                    type='number'
                    name='maxValue'
                    autoComplete='off'
                  />
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      <button
        className='btn btn-sm btn-primary btn-light-primary mx-4 mt-3'
        onClick={(e) => addValidation(e)}
      >
        {mls('Add validation')}
      </button>
      {validationError && <div className='text-danger mt-2'>{validationError}</div>}
    </div>
  );
};
