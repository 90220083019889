import { mls } from 'lib/multilanguagesupport';
import { toAbsoluteUrl } from '_metronic/helpers';

export const Card = ({ title, description, numOfColumns, index, handleShow }) => {
  return (
    <div
      onClick={handleShow}
      className={`card bgi-no-repeat`}
      style={{
        cursor: 'pointer',
        backgroundPosition: 'right top',
        backgroundSize: '30% auto',
        minHeight: 188,
        backgroundImage: `url(${toAbsoluteUrl(
          `/media/svg/shapes/abstract-${(index + 1) % 5}.svg`
        )})`,
      }}
    >
      {/* begin::Body */}
      <div className='card-body'>
        <span className='card-title fw-bolder text-dark-75 text-primary fs-4'>
          {mls(title)}
        </span>

        <p
          className=' fw-bold fs-5 m-0 text-muted'
          dangerouslySetInnerHTML={{ __html: description }}
        ></p>
        <p
          className='fw-bold fs-5 m-0 text-muted p-1 px-4'
          style={{
            textAlign: 'right',
            bottom: 15,
            position: 'absolute',
            right: 25,
            border: '1px dashed #E4E6EF',
            borderRadius: 6,
          }}
        >
          {mls(`No of Columns: `)}
          {numOfColumns}
        </p>
      </div>
      {/* end::Body */}
    </div>
  );
};
