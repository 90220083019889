import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { DragAndDropCard } from './DragAndDropCard';
import { mls } from 'lib/multilanguagesupport';
import * as editPageConstants from '../constants'
import { useSelector } from 'react-redux';

const DragAndDrop = ({
  represents,
  listForDragAndDrop,
  onDragEnd,
  activePageInEditing,
  setActivePageInEditing,
  formik
}) => {
  const appComponent = useSelector((store) => store.config_store.appSchema.appComponent);

  const handleClickOnDragAndDropCard = (index) => {
    if(represents === editPageConstants.SCREEN_GROUP) {
      setActivePageInEditing((old) => {
        const nState =  {
          activePage: editPageConstants.SCREEN_GROUP,
          screenGroupIndex: index,
          screenIndex: null
        };
        // console.log(nState, 'nState');
        return nState;
      });
      return;
    }
    if(represents === editPageConstants.SCREEN) {
      setActivePageInEditing((old) => {
        const nState =  {
          activePage: editPageConstants.SCREEN,
          screenGroupIndex: old.screenGroupIndex,
          screenIndex: index
        };
        console.log(nState, 'nState');
        return nState;
      });
      return;
    }
  }
  return <>
    <DragDropContext
      onDragEnd={(dragEndDetails) => {
        if(!dragEndDetails.destination) return;
        onDragEnd(dragEndDetails)}
      }
    >
      <Droppable droppableId='droppable-1'>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {listForDragAndDrop?.map((dragAndDropItem, index) => {
              return <Draggable draggableId={`draggable-${index}`} index={index}>
                {(provided, snapshot) => (
                  <DragAndDropCard
                    represents={represents}
                    index={index}
                    activePageInEditing={activePageInEditing}
                    name={dragAndDropItem?.name || dragAndDropItem?.screenName || appComponent?.[dragAndDropItem?.componentID]?.name || mls('<Untitled>')}
                    description={dragAndDropItem?.description}
                    providedDrag={provided}
                    onClick={handleClickOnDragAndDropCard.bind(null, index)}
                    formik={formik}
                  />
                )}
              </Draggable>
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>

    </DragDropContext>
  </>

}

export default DragAndDrop;