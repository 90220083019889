import React, { useState } from 'react';
import styles from './ComponentSettingWrapper.module.scss';
import { toast } from 'react-toastify';
import { mls } from 'lib/multilanguagesupport';

const Tabs = ({ children, validateForm, isNew, isComponetDetailsChange }) => {
  const [activeTab, setActiveTab] = useState(children[0].props.label);

  const handleClick = (tab) => {
    const isNoError = validateForm();
    if (isNoError) {
      if (isNew || isComponetDetailsChange) {
        toast.warning(mls('Save before switching tabs'));
        return;
      }
      setActiveTab(tab);
    }
  };

  return (
    <div>
      <ul className={`nav nav-tabs ${styles.customTabs}`}>
        {children.map((child) => {
          const label = child.props.label;
          return (
            <li className='nav-item' key={label}>
              <a
                className={`nav-link ${activeTab === label ? 'active' : ''}`}
                onClick={() => handleClick(label)}
              >
                {label}
              </a>
            </li>
          );
        })}
      </ul>
      <div className={styles.tabContent}>
        {children.map((child) => {
          if (child.props.label !== activeTab) return undefined;
          return <div className='tab-pane active'>{child.props.children}</div>;
        })}
      </div>
    </div>
  );
};

export default Tabs;
