import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { XCircleFill } from 'react-bootstrap-icons'; // Importing the icon from react-bootstrap-icons
import { updateRelatedTableSchema } from '../modal/add-database/helper/updateRelatedTableSchema';
import Select from 'react-select';

const RelationsComponent = ({
  allDataTableData,
  dataTableSchema,
  setDataTableSchema,
  setRelatedTableList,
  setTableUpdates,
}) => {
  const appID = dataTableSchema.appID;
  const [relations, setRelations] = useState(dataTableSchema?.relations || {});
  const [newRelations, setNewRelations] = useState({
    type: '',
    target: '',
    displayName: '',
  });
  const [errors, setErrors] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let displayName = newRelations.displayName;
    if (name === 'target') {
      displayName = `${value}`;
    }
    // else if (name === 'displayName') {
    //   displayName = value;
    // }
    setNewRelations({
      ...newRelations,
      [name]: value,
      displayName: displayName,
    });

    // Clear the error on input change
    setErrors('');
  };

  const validateForm = () => {
    let formIsValid = true;
    let errorMessage = '';

    if (newRelations.type === '' && newRelations.target === '') {
      formIsValid = false;
      errorMessage = 'Relation Type and Relation Target are required.';
    } else if (newRelations.type === '') {
      formIsValid = false;
      formIsValid = false;
      errorMessage = 'Relation Type is required.';
    } else if (newRelations.target === '') {
      formIsValid = false;
      errorMessage = 'Target Table is required.';
    }

    // Check if the same combination of relation type and target table already exists
    const isDuplicate = Object.values(relations || []).some(
      (relation) => relation.type === newRelations.type && relation.target === newRelations.target
    );

    if (isDuplicate) {
      formIsValid = false;
      errorMessage = 'This combination of Relation Type and Target Table already exists.';
    }

    setErrors(errorMessage);
    return formIsValid;
  };

  const handleAddRelation = () => {
    if (!validateForm()) {
      return;
    }

    const uuid = uuidv4();
    const foreignKey =
      newRelations.type === 'belongsTo' ? 'id' : `__d3id__${dataTableSchema.name}Id`;
    let relationData;

    relationData = {
      ...newRelations,
      as: uuid,
      foreignKey,
    };

    if (newRelations.type === 'belongsToMany') {
      relationData.through = `${dataTableSchema.name}-${newRelations.target}-throughTable`;
      relationData.otherKey = `__d3id__${newRelations.target}Id`;
    }

    setRelations({
      ...relations,
      [uuid]: relationData,
    });

    // Update target table schema with the new relation
    updateRelatedTableSchema({
      allDataTableData,
      appID,
      mainTableName: dataTableSchema.name,
      setTableUpdates,
      setRelatedTableList,
      relationData,
      action: 'add',
    });
    const newDataTableSchema = {
      ...dataTableSchema,
      relations: { ...relations, [uuid]: relationData },
    };
    if (newRelations.type === 'belongsTo') {
      const isColumnPresent = (newDataTableSchema['columnSchema'] || []).some(
        (col) => col['columnName'] === `__d3id__${newRelations.target}Id`
      );
      if (!isColumnPresent) {
        const prevColSchema = [
          ...newDataTableSchema['columnSchema'],
          ...[
            {
              name: `__d3id__${newRelations.target}Id`,
              columnName: `__d3id__${newRelations.target}Id`,
              dataType: 'belongsTo',
              relationId: relationData.as,
            },
          ],
        ];
        newDataTableSchema['columnSchema'] = prevColSchema;
      }
    }
    setDataTableSchema(newDataTableSchema);

    setNewRelations({
      type: '',
      target: '',
      displayName: '',
    });
  };

  const handleUpdateRelation = (e, key) => {
    const { name, value } = e.target;
    const updatedRelation = { ...relations[key], [name]: value };
    if (updatedRelation.type === 'belongsToMany') {
      updatedRelation.through = `${dataTableSchema.name}-${updatedRelation.target}-throughTable`;
      updatedRelation.otherKey = `__d3id__${updatedRelation.target}Id`;
    }

    setRelations({
      ...relations,
      [key]: updatedRelation,
    });

    // Update target table schema with the updated relation
    updateRelatedTableSchema({
      allDataTableData,
      appID,
      mainTableName: dataTableSchema.name,
      setTableUpdates,
      setRelatedTableList,
      relationData: updatedRelation,
      action: 'update',
    });
  };

  const handleRemoveRelation = (key) => {
    const relationToRemove = relations[key];

    const updatedRelations = { ...relations };
    delete updatedRelations[key];
    setRelations(updatedRelations);

    // Update target table schema by removing the relation
    updateRelatedTableSchema({
      allDataTableData,
      appID,
      mainTableName: dataTableSchema.name,
      setTableUpdates,
      setRelatedTableList,
      relationData: relationToRemove,
      action: 'remove',
    });
    setDataTableSchema({ ...dataTableSchema, relations: updatedRelations });
  };

  const options = Object.keys(allDataTableData)
    .filter((key) => key !== dataTableSchema.name && allDataTableData[key].tableType !== 'junction')
    .map((key) => ({
      value: allDataTableData[key].name,
      label: allDataTableData[key].name,
    }));

  // Options for the relation type
  const typeOptions = [
    { value: 'hasOne', label: 'hasOne' },
    { value: 'hasMany', label: 'hasMany' },
    { value: 'belongsTo', label: 'belongsTo' },
    { value: 'belongsToMany', label: 'belongsToMany' },
  ];

  return (
    <>
      <label className='form-label fs-6 fw-bolder text-dark mt-3'>Relations</label>
      <div
        style={{
          border: '1px solid #e4e6ef',
          borderRadius: '.5rem',
          padding: '.5rem',
        }}
      >
        <Form className='mb-5 ms-3'>
          <Row className='align-items-end'>
            <Col md={5}>
              <Form.Group controlId='relationTarget'>
                <Form.Label>Target Table</Form.Label>
                <Select
                  name='target'
                  value={options.find((option) => option.value === newRelations.target) || ''}
                  onChange={(selectedOption) =>
                    handleInputChange({ target: { name: 'target', value: selectedOption.value } })
                  }
                  options={options}
                  isInvalid={!!errors.target}
                  menuPlacement='auto'
                  placeholder='Select Target Table'
                  isSearchable
                />
                {/* <Form.Control
                  as='select'
                  name='target'
                  value={newRelations.target}
                  onChange={handleInputChange}
                  isInvalid={!!errors.target}
                >
                  <option value=''>Select Target Table</option>
                  {Object.keys(allDataTableData).map((key) => (
                    <option key={key} value={allDataTableData[key].name}>
                      {allDataTableData[key].name}
                    </option>
                  ))}
                </Form.Control> */}
              </Form.Group>
            </Col>

            <Col md={5}>
              <Form.Group controlId='relationType'>
                <Form.Label>Type</Form.Label>
                <Select
                  name='type'
                  value={typeOptions.find((option) => option.value === newRelations.type) || ''}
                  onChange={(selectedOption) =>
                    handleInputChange({ target: { name: 'type', value: selectedOption.value } })
                  }
                  options={typeOptions}
                  placeholder='Select Type'
                  menuPlacement='auto'
                  isInvalid={!!errors.type}
                />
              </Form.Group>
            </Col>
            {/* <Col md={3}>
              <Form.Group controlId='relationDisplayName'>
                <Form.Label>Display Name</Form.Label>
                <Form.Control
                  type='text'
                  name='displayName'
                  value={newRelations.displayName}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col> */}
            <Col md={2}>
              <Button
                variant='primary'
                onClick={handleAddRelation}
                className='btn btn-sm btn-primary'
                style={{ marginBottom: '4px' }}
              >
                Add Relation
              </Button>
            </Col>
          </Row>
        </Form>
        {/* Error Section */}
        {errors.length > 0 && (
          <div className='alert alert-danger' role='alert'>
            {errors}
          </div>
        )}
        {Object.keys(relations).length > 0 && (
          <>
            <hr />
            <label className='form-label fs-6 fw-bolder text-dark'>Current Relations</label>
            <div className='m-3'>
              <Row className='fw-bold mb-2'>
                <Col md={5}>Target Table</Col>
                <Col md={5}>Type</Col>
                {/* <Col md={4}>Display Name</Col> */}
                <Col md={2}></Col> {/* Empty column for spacing */}
              </Row>
              {Object.keys(relations).map((key) => (
                <Form key={key} className='mb-3'>
                  <Row className='align-items-center'>
                    <Col md={5}>
                      <Form.Group controlId={`relationTarget-${key}`}>
                        {/* <Form.Control
                          as='select'
                          name='target'
                          value={relations[key].target}
                          onChange={(e) => handleUpdateRelation(e, key)}
                        >
                          {Object.keys(allDataTableData).map((key) => (
                            <option key={key} value={allDataTableData[key].name}>
                              {allDataTableData[key].name}
                            </option>
                          ))}
                        </Form.Control> */}
                        <Select
                          name='target'
                          value={options.find((option) => option.value === relations[key].target)}
                          onChange={(selectedOption) =>
                            handleUpdateRelation(
                              { target: { name: 'target', value: selectedOption.value } },
                              key
                            )
                          }
                          options={options}
                          placeholder='Select Target Table'
                          menuPlacement='auto'
                          isSearchable
                        />
                      </Form.Group>
                    </Col>

                    <Col md={5}>
                      <Form.Group controlId={`relationType-${key}`}>
                        <Select
                          name='type'
                          value={typeOptions.find((option) => option.value === relations[key].type)}
                          onChange={(selectedOption) =>
                            handleUpdateRelation(
                              { target: { name: 'type', value: selectedOption.value } },
                              key
                            )
                          }
                          options={typeOptions}
                          menuPlacement='auto'
                          placeholder='Select Type'
                          isInvalid={!!errors.type}
                        />
                      </Form.Group>
                    </Col>
                    {/* <Col md={4}>
                      <Form.Group controlId={`relationDisplayName-${key}`}>
                        <Form.Control
                          type='text'
                          name='displayName'
                          value={relations[key].displayName || ''}
                          onChange={(e) => handleUpdateRelation(e, key)}
                        />
                      </Form.Group>
                    </Col> */}
                    <Col md={2} className='d-flex justify-content-center'>
                      <XCircleFill
                        color='grey'
                        size={20}
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleRemoveRelation(key)}
                      />
                    </Col>
                  </Row>
                </Form>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default RelationsComponent;
