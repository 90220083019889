import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './PDFTemplate.module.scss';
import PDFTemplate from './PDFTemplate';
import { mls } from 'lib/multilanguagesupport';
import { getConnectedTables } from './helperFuntion';
import ConnectedTableSelection from './ConnectedTableSelection';
import { CircularProgress } from '@mui/material';
import { Row, Col, Form } from 'react-bootstrap';
import CreateTemplateHeader from './CreateTemplateHeader';
import { defaultHtmlContent } from './defaultHtmlContentData';
import { _supistaApiPost, _supistaApiGet } from 'lib/server-connection/connections';

const fetchComponentData = async ({
  componentID,
  appID,
}: {
  componentID: string;
  appID: string;
}) => {
  const baseUrl = `app/${appID}/CRUD/${componentID}`;
  const filter = `data=%7B%22__d3__filterdata%22%3A%7B%22where%22%3A%7B%7D%7D%2C%22__d3__screenFilter%22%3A%7B%22where%22%3A%7B%7D%7D%7D&lang=English`;
  const finalUrl = `${baseUrl}?${filter}`;
  const componentRes = await _supistaApiGet(finalUrl);
  return componentRes;
};
interface createTemplateProps {
  setIsNewTemplate: Function;
  isNewTemplate: boolean;
  isTemplateSetting: boolean;
  setIsTemplateSetting: Function;
  appDatatable: any;
  appComponent: any;
  componentSchema: any;
  appID: string;
  selectedTemplateID: string;
  setSelectedTemplateID: Function;
  setComponentSchema: Function;
}
const CreateTemplate = ({
  setIsNewTemplate,
  isNewTemplate,
  isTemplateSetting,
  setIsTemplateSetting,
  appDatatable,
  setComponentSchema,
  appComponent,
  componentSchema,
  appID,
  selectedTemplateID,
  setSelectedTemplateID,
}: createTemplateProps) => {
  const [isTemplateForm, setIsTemplateForm] = useState(true);
  const [componentRowList, setComponentRowList] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [isDataFetching, setIsDataFetching] = useState(false);

  const [selectedId, setSelectedId] = useState('');
  const downloadPdfButtonRef = useRef(null);
  const connectedTablesData = useMemo(
    () =>
      getConnectedTables({
        tableId: componentSchema?.table ?? '',
        appDatatable,
      }),
    [componentSchema?.table, appDatatable]
  );
  const defaultTemplateData = {
    htmlString: defaultHtmlContent,
    title: '',
    description: '',
    variables: {
      name: '',
    },
    componentID: componentSchema?.componentID,
    connectedTableList: Object.keys(connectedTablesData ?? {}) ?? [],
    filterMainComponent: { __d3__filterdata: {} },
  };

  const [templateData, setTemplateData] = useState(
    !isNewTemplate
      ? componentSchema?.PDFTemplate?.[selectedTemplateID] ?? defaultTemplateData
      : defaultTemplateData
  );

  // const [templateData, setTemplateData] = useState(defaultTemplateData);
  const updateRowListData = async ({
    componentID,
    appID,
  }: {
    componentID: string;
    appID: string;
  }) => {
    const componentRes = await fetchComponentData({ componentID, appID });
    setComponentRowList(componentRes?.rows ?? []);
    if ((componentRes?.rows ?? [])[0]) {
      setSelectedId((componentRes?.rows ?? [])[0]?.id ?? '');
    }
  };
  useEffect(() => {
    updateRowListData({ componentID: componentSchema?.componentID, appID });
  }, [componentSchema?.componentID, appID]);
  const [errors, setErrors] = useState<{ [key: string]: any }>({});

  const handleComponentChange = (e: any) => {
    setSelectedId(e.target.value);
  };

  const handleTemplateName = (e: any) => {
    setTemplateData({
      ...templateData,
      title: e.target.value,
      variables: { ...(templateData?.variables ?? {}), name: e.target.value },
    });
  };
  const handleTemplateDescription = (e: any) => {
    setTemplateData({
      ...templateData,
      description: e.target.value,
      variables: { ...(templateData?.variables ?? {}), description: e.target.value },
    });
  };

  const validateFields = () => {
    const errors: { [key: string]: any } = {};
    if (!(templateData?.title ?? '')?.trim()) {
      errors.title = 'Label is required.';
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const handleBlur = (e: any) => {
    validateFields();
  };
  const handleNext = async () => {
    if (validateFields()) {
      const componentID = componentSchema?.componentID;
      const baseUrl = `app/${appID}/Action_Button/${componentID}`;
      const finalUrl = `${baseUrl}`;
      const data = {
        ...templateData,
        actionType: 'getRowData',
        filterMainComponent: {
          ...(templateData?.filterMainComponent ?? {}),
          __d3__filterdata: {
            ...(templateData?.filterMainComponent?.__d3__filterdata ?? {}),
            where: { id: selectedId },
          },
        },
      };
      setIsDataFetching(true);
      const componentRes = await _supistaApiPost(finalUrl, { data });
      if (componentRes?.rows) {
        setRowData(componentRes?.rows ?? {});
        setIsDataFetching(false);
        setIsTemplateForm(false);
      } else {
        setIsDataFetching(false);
        setIsTemplateForm(false);
      }
    }
  };

  return (
    <div className={styles.createTemplate}>
      <CreateTemplateHeader
        setIsNewTemplate={setIsNewTemplate}
        setIsTemplateSetting={setIsTemplateSetting}
        isNewTemplate={isNewTemplate}
        isTemplateSetting={isTemplateSetting}
        setIsTemplateForm={setIsTemplateForm}
        isTemplateForm={isTemplateForm}
        downloadPdfButtonRef={downloadPdfButtonRef}
        templateData={templateData}
        selectedTemplateID={selectedTemplateID}
        setSelectedTemplateID={setSelectedTemplateID}
        componentSchema={componentSchema}
        setComponentSchema={setComponentSchema}
      />
      <div className={styles.createTemplateContent}>
        {isTemplateForm ? (
          <>
            <div className={styles.createTemplateFormOuter}>
              <div className={styles.createTemplateForm}>
                <Form.Group
                  as={Row}
                  className='mb-3'
                  controlId='formName'
                  style={{ marginLeft: 0, marginRight: 0 }}
                >
                  <Form.Label column sm={3}>
                    {mls('Name')}:
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type='text'
                      name='name'
                      value={templateData?.title || ''}
                      onChange={handleTemplateName}
                      onBlur={handleBlur}
                      isInvalid={!!errors.title}
                    />
                    <Form.Control.Feedback type='invalid'>{errors.title}</Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className='mb-3'
                  controlId='formName'
                  style={{ marginLeft: 0, marginRight: 0 }}
                >
                  <Form.Label column sm={3}>
                    {mls('Description')}:
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type='text'
                      name='name'
                      value={templateData?.description || ''}
                      onChange={handleTemplateDescription}
                      // onBlur={handleBlur}
                      isInvalid={!!errors.description}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors.description}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className='mb-3' style={{ marginLeft: 0, marginRight: 0 }}>
                  <Form.Label column sm={3}>
                    {mls('Id')}:
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      as='select'
                      name='selectId'
                      value={selectedId}
                      onChange={(e) => handleComponentChange(e)}
                      onBlur={(e) => handleBlur(e)}
                      isInvalid={!!errors.componentID}
                    >
                      <option value='' disabled>
                        {mls('Select Id')}
                      </option>
                      {React.Children.toArray(
                        (componentRowList ?? []).map((componentRowData: any) => {
                          return (
                            <option value={componentRowData?.id ?? ''} disabled={false}>
                              {componentRowData?.id ?? ''}
                            </option>
                          );
                        })
                      )}
                    </Form.Control>
                    <Form.Control.Feedback type='invalid'>
                      {errors.componentID}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <ConnectedTableSelection
                  connectedTablesData={connectedTablesData}
                  templateData={templateData}
                  componentSchema={componentSchema}
                  setTemplateData={setTemplateData}
                />
                <div className={styles.nextButtonDiv}>
                  <button
                    type='button'
                    className='primaryButton secondaryButtonColor'
                    onClick={() => handleNext()}
                  >
                    {isDataFetching ? (
                      <>
                        <CircularProgress color='inherit' size={10} /> &nbsp;
                      </>
                    ) : null}
                    {mls('Next')}
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <PDFTemplate
            downloadPdfButtonRef={downloadPdfButtonRef}
            rowData={rowData}
            connectedTablesData={connectedTablesData}
            templateData={templateData}
            setTemplateData={setTemplateData}
            appID={appID}
          />
        )}
      </div>
    </div>
  );
};

export default CreateTemplate;
