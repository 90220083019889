const myCopy = (o) => {
  let v;
  let key;
  const output = Array.isArray(o) ? [] : {};
  /* eslint guard-for-in: 0 */
  /* eslint no-restricted-syntax: 0 */
  for (key in o) {
    v = o[key];
    output[key] = (typeof v === 'object' && v !== null) ? myCopy(v) : v;
  }
  return output;
};

exports.areEqual = (prev, next) => {
  let isEqual = true;
  if (prev != null && next == null) {
    return false;
  }
  if (Object.keys(prev).length !== Object.keys(next).length) {
    return false;
  }
  Object.keys(next).forEach((obj) => {
    if (typeof next[obj] !== 'function' && next[obj] !== prev[obj]) {
      isEqual = false;
      // console.log(obj, next[obj], 'caused memo to be false');
    }
  });
  return isEqual;
};
exports.areEqualNotChildren = (prev, next) => {
  let isEqual = true;
  if (Object.keys(prev).length !== Object.keys(next).length) {
    return false;
  }
  Object.keys(next).forEach((obj) => {
    if (obj !== 'children' && typeof next[obj] !== 'function' && next[obj] !== prev[obj]) {
      isEqual = false;
    }
  });
  return isEqual;
};

exports.copy = myCopy;

exports.removeNonAlnum = (value) => {
  if (value == null) {
    return value;
  }
  return (value.replace(/\W/g, ''));
};

exports.isURL = (str) => {
  const pattern = new RegExp('^https?://'); // fragment locator
  return pattern.test(str);
};

/**
 * Stable Sort to sort array with the soryBy key.
 * @param {[{ sortBy: string }]]} inputArr The array which you wish to sort.
 * @param {String} sortBy The key in the inputArr with which the array is to be sorted.
 * @returns sorted array with stable sort.
 */
exports.stableBubbleSort = (inputArr, sortBy) => {
  const len = inputArr.length;
  for (let i = 0; i < len; i++) {
    for (let j = 0; j < len - 1; j++) {
      if (inputArr[j][sortBy] < inputArr[j + 1][sortBy]) {
        const tmp = inputArr[j];
        inputArr[j] = inputArr[j + 1];
        inputArr[j + 1] = tmp;
      }
    }
  }
  return inputArr;
};
