import React, { useState, useEffect } from 'react';
import { mls } from '../../../../lib/multilanguagesupport';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';

const Card = ({ project, index, handleShow, totalData, appComponent, appSchema, projects, idx }) => {
  const [totalScreens, setTotalScreens] = useState(0);

  useEffect(() => {
    let screens = 0;
    for(let i = 0; i < project.screenGroup.length; i++) {
      screens += (project.screenGroup[i]?.screen?.length || 0);
    }
    setTotalScreens(screens);
  }, [project]);

  return (
    <div
      // onClick={handleShow}
      className={`card bgi-no-repeat`}
      style={{
        cursor: 'pointer',
        backgroundPosition: 'right top',
        backgroundSize: '30% auto',
        minHeight: 188,
        backgroundImage: `url(${toAbsoluteUrl(
          `/media/svg/shapes/abstract-${(index + 1) % 5}.svg`
        )})`,
      }}
    >
      {/* begin::Body */}
      <div className='card-body'>
        <span className='card-title fw-bolder text-dark-75 text-primary fs-4'>
          {mls(project?.name)}
        </span>

        <p
          className=' fw-bold fs-5 m-0 text-muted'
        >
          {project.description ?
            project.description.substring(0, 70) + (project.description.length > 70 ? ' . . .' : '') :
            <><br /> <br /></>}
        </p>
        <p
          className='fw-bold fs-5 m-0 text-muted p-1 px-4'
          style={{
            textAlign: 'right',
            bottom: 15,
            position: 'absolute',
            right: 25,
            border: '1px dashed #E4E6EF',
            borderRadius: 6,
          }}
        >
          {mls(`Screens: ${totalScreens}`)}
        </p>
        <p
          className='fw-bold fs-5 m-0 text-muted p-1 px-4'
          style={{
            // textAlign: 'left',
            bottom: 15,
            position: 'absolute',
            left: 25,
            border: '1px dashed #E4E6EF',
            borderRadius: 6,
          }}
        >
          {mls(`Screen Groups: ${project && project.screenGroup.length}`)}
        </p>
      </div>
      {/* end::Body */}
    </div>
  );
};

export default Card;
