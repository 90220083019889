import React, { useState } from 'react';
import styles from '../ComponentSettingWrapper.module.scss';
import { mls } from 'lib/multilanguagesupport';
import { Button, Form } from 'react-bootstrap';
import { CircularProgress } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { _supistaApiPost } from 'lib/server-connection/connections';
import AIToQuery from './AIToQuery';
import { FaHistory } from 'react-icons/fa';

interface queryGeneratorProps {
  connectedTableData: any;
  appID: string;
  code: string;
  handleCodeChange: Function;
  activeButtonKey: string;
  buttonType: string;
}
const QueryGenerator = ({
  connectedTableData,
  appID,
  handleCodeChange,
  code,
  activeButtonKey,
  buttonType,
}: queryGeneratorProps) => {
  const [query, setQuery] = useState('');
  const [isAiQueryLoading, setIsAiQueryLoading] = useState(false);
  const [isAIQuaryPopup, setIsAIQuaryPopup] = useState(false);
  const [aiQuaryData, setAiQuaryData] = useState('');
  const handleQueryChange = (e: any) => {
    setQuery(e.target.value);
  };
  const handleAiRequest = async (e: any) => {
    e.preventDefault();
    const baseUrl = `aiQuery/${appID}`;
    const data = {
      queryArray: [
        {
          promptType: 'CRUD',
          query: query,
          apiType: buttonType === 'actionButton' ? 'PUT' : activeButtonKey,
        },
      ],
      data: {
        tableName: connectedTableData?.tableName,
        columnSchema: connectedTableData?.columnSchema,
      },
    };

    setIsAiQueryLoading(true);
    const componentRes = await _supistaApiPost(baseUrl, { data });
    if (componentRes?.[0]) {
      setIsAIQuaryPopup(true);
      setAiQuaryData(componentRes[0]?.response ?? '');
      setIsAiQueryLoading(false);
    } else {
      setIsAiQueryLoading(false);
    }
  };
  return (
    <div>
      {isAIQuaryPopup ? (
        <AIToQuery
          isAIQuaryPopup={isAIQuaryPopup}
          aiQuaryData={aiQuaryData}
          setIsAIQuaryPopup={setIsAIQuaryPopup}
          handleCodeChange={handleCodeChange}
          code={code}
        />
      ) : null}
      <Form className={styles.queryGenerator} onSubmit={handleAiRequest}>
        <div className={styles.queryHistoryButton} onClick={() => setIsAIQuaryPopup(true)}>
          <FaHistory />
        </div>
        <Form.Group controlId='queryInput' className={styles.queryInput}>
          <Form.Control
            type='text'
            placeholder={mls('Generate Code using AI ...')}
            value={query}
            onChange={handleQueryChange}
          />
        </Form.Group>
        <Button variant='primary' onClick={handleAiRequest} className={styles.generateButton}>
          {isAiQueryLoading ? (
            <>
              <CircularProgress color='inherit' size={12} />
              &nbsp;
            </>
          ) : (
            <SendIcon fontSize={'small'} />
          )}
          &nbsp;
          {mls('Send')}
        </Button>
      </Form>
    </div>
  );
};

export default QueryGenerator;
